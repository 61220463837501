import React, { useState, useEffect, FunctionComponent } from 'react';
import { Dropdown, DropdownButton, ButtonGroup, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import classNames from 'classnames';
import { Content } from '@bloomreach/spa-sdk';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import { formatForHistory } from 'components/shared/ResolverLink';
import './HeroBanner.scss';
import { Spinner } from 'components/shared/Spinner';
import SiteGlobalSwitches, { checkForNessus, getBuildingCenterFromHeader } from 'components/utils';
import { BannerBar } from '../BannerBar/BannerBar';
import AutocompleteComp, { GoToPageUrl } from '../Autocomplete/Autocomplete';
import { ReactComponent as SearchIcon } from '../../assets/images/search-bg-black.svg';
import { useStateWithLocalStorage } from '../hooks/useStateWithLocalStorage';

enum HERO_BANNER_VARIANTS {
  DROPDOWN_MENU_SEARCH = 'dropdown',
  CUSTOM_TITLE_SEARCH = 'customTitle',
  SLIDER_SEARCH = 'slider',
  SIMPLE_SEARCH = 'simple',
}

enum HERO_BANNER_BACKGROUND_THEMES {
  LIGHT = 'light',
  DARK = 'dark',
}

enum HERO_BANNER_TEXT_EMPHASIS {
  LINE_1 = 'line1',
  LINE_2 = 'line2',
}

enum HERO_HEADING_LEVEL {
  HEADING_1 = 'h1',
  HEADING_2 = 'h2',
}

let isSearching = false;

function HeroBannerDropDown({
  isParkModel,
  defaultText,
  labels,
}: {
  isParkModel: boolean;
  defaultText: string;
  labels: { [key: string]: string };
}) {
  const [dropDownTitle, setDropDownTitle] = useState(defaultText);
  const { retailerDropdownText = '', floorplanDropdownText = '' } = labels;
  const parkModelVal = isParkModel ? 'Park Model ' : '';

  const [items, setItems] = useState([
    {
      text: floorplanDropdownText,
      active: floorplanDropdownText === defaultText,
      class: floorplanDropdownText === defaultText ? 'default' : ' ',
    },
    {
      text: retailerDropdownText,
      active: floorplanDropdownText === defaultText,
      class: floorplanDropdownText === defaultText ? 'default' : ' ',
    },
  ]);

  const selectedItem = (val: string) => {
    setDropDownTitle(val);
    const options = [...items];
    options.find((i: any) => i.text === val)!.active = true;
    options.find((i: any) => i.text === val)!.class = 'default';
    setItems(options);
  };

  return (
    <>
      <DropdownButton
        alignRight
        id="hero-banner-dropdown-menu"
        className="cvc-hero-banner-content__dropdown-menu"
        title={`${parkModelVal}${dropDownTitle}`}
      >
        {items.map((item) => (
          <Dropdown.Item
            key={item.text}
            as="button"
            active={item.active}
            className={item.class}
            eventKey={item.text}
            onClick={() => selectedItem(item.text)}
          >
            {parkModelVal}
            {item.text}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </>
  );
}
function HeroBannerSlider({
  isParkModel,
  dropdownPrefixLabelVal,
  defaultText,
  labels,
  componentId,
}: {
  isParkModel: boolean;
  dropdownPrefixLabelVal: string;
  defaultText: string;
  labels: { [key: string]: string };
  componentId: string;
}) {
  const [sliderDefault, setSliderDefault] = useState(defaultText);
  const { retailerDropdownText = '', floorplanDropdownText = '' } = labels;
  const parkModelVal = isParkModel ? 'Park Model ' : '';

  const items = [
    { text: floorplanDropdownText, active: false, class: '' },
    { text: retailerDropdownText, active: false, class: '' },
  ];

  items.find((i: any) => i.text === sliderDefault)!.active = true;
  items.find((i: any) => i.text === sliderDefault)!.class = 'default';

  const selectedItem = (val: string) => {
    setSliderDefault(val);
  };

  return (
    <>
      <ButtonGroup
        id={`hero-banner-slider-${componentId}`}
        className="cvc-hero-banner-content__slider"
      >
        {items.map((item) => (
          <Button
            variant="secondary"
            key={item.text}
            active={item.active}
            className={item.class}
            onClick={() => selectedItem(item.text)}
          >
            <span>{dropdownPrefixLabelVal} </span>
            <span className="cvc-hero-banner-content__slider-search-type">
              {parkModelVal}
              {item.text}
            </span>
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
}

function HeroBannerSimple({
  isParkModel,
  dropdownPrefixLabelVal,
  defaultText,
}: {
  isParkModel: boolean;
  dropdownPrefixLabelVal: string;
  defaultText: string;
}) {
  const parkModelVal = isParkModel ? 'Park Model ' : '';

  return (
    <span className="cvc-hero-banner-content__simple-search-label">
      {dropdownPrefixLabelVal} {parkModelVal}
      {defaultText}
    </span>
  );
}

function displayCustomTitles(
  headingLevelChoice: string,
  searchTitle1Val: string,
  searchTitle2Val: string
) {
  if (!searchTitle1Val || !searchTitle2Val) return '';

  return (
    <div className="cvc-hero-banner-content__custom-titles-container text-center text-md-left">
      {React.createElement(
        headingLevelChoice,
        { className: 'cvc-hero-banner-content__custom-title-line-1' },
        searchTitle1Val,
        <span className="cvc-hero-banner-content__custom-title-line-2">{searchTitle2Val}</span>
      )}
    </div>
  );
}

function displayComboSearch(
  isParkModel: boolean,
  dropdownPrefixLabelVal: string,
  customSearchTypeVal: string,
  heroBannerLabels: any,
  componentId: any
) {
  const { floorplanDropdownText = '', retailerDropdownText = '' } = heroBannerLabels;
  const defaultText =
    customSearchTypeVal === 'floorplan' ? floorplanDropdownText : retailerDropdownText;

  return (
    <div className="cvc-hero-banner-content__combo-search">
      <div className="cvc-hero-banner-content__combo-search-dropdown">
        <span className="cvc-hero-banner-content__dropdown-menu-prefix-label">
          {dropdownPrefixLabelVal}
        </span>
        <HeroBannerDropDown
          isParkModel={isParkModel}
          defaultText={defaultText}
          labels={heroBannerLabels}
        />
      </div>
      <div className="cvc-hero-banner-content__combo-search-slider">
        <HeroBannerSlider
          isParkModel={isParkModel}
          dropdownPrefixLabelVal={dropdownPrefixLabelVal}
          defaultText={defaultText}
          labels={heroBannerLabels}
          componentId={componentId}
        />
      </div>
      <div className="cvc-hero-banner-content__combo-search-simple">
        <HeroBannerSimple
          isParkModel={isParkModel}
          dropdownPrefixLabelVal={dropdownPrefixLabelVal}
          defaultText={defaultText}
        />
      </div>
    </div>
  );
}

function getBackgroundThemeVal(backgroundTheme: string) {
  if (!backgroundTheme) return '';

  return backgroundTheme === HERO_BANNER_BACKGROUND_THEMES.LIGHT ? 'is-light' : 'is-dark';
}

function getTextEmphasisVal(textEmphasis: string) {
  if (!textEmphasis) return 'is-emphasis-line-1';

  return textEmphasis === HERO_BANNER_TEXT_EMPHASIS.LINE_1
    ? 'is-emphasis-line-1'
    : 'is-emphasis-line-2';
}

function getHeadingLevelVal(headingLevel: string) {
  if (!headingLevel) return HERO_HEADING_LEVEL.HEADING_1;
  return headingLevel === 'heading1' ? HERO_HEADING_LEVEL.HEADING_1 : HERO_HEADING_LEVEL.HEADING_2;
}

function getUrlToRedirect(
  showDropdown: any,
  showSlider: any,
  heroBannerConfiguration: any,
  searchModelTypeVal: any,
  customSearchTypeVal: any,
  componentId: any
) {
  let urlToRedirectVal: string;
  if (showDropdown) {
    let dropDownItemSelected = document.getElementById('hero-banner-dropdown-menu')!.textContent!;

    const divDropDownMenuContainer = document.querySelectorAll(
      '.cvc-hero-banner-content__dropdown-menu.dropdown'
    );
    if (divDropDownMenuContainer !== null) {
      if (divDropDownMenuContainer.length === 2) {
        dropDownItemSelected = (divDropDownMenuContainer[1] as HTMLDivElement).querySelector(
          '#hero-banner-dropdown-menu'
        )!.textContent!;
      }
    }

    const dropDownItemVal = dropDownItemSelected.toLowerCase().trim().replace(/\s/g, '');
    // console.log("ddiv", dropDownItemVal);
    urlToRedirectVal = heroBannerConfiguration[`${searchModelTypeVal}_${dropDownItemVal}_url`];
  } else if (showSlider) {
    const sliderItemSelected = document.querySelector(
      `#hero-banner-slider-${componentId} .btn.active .cvc-hero-banner-content__slider-search-type`
    )!.textContent!;
    const sliderItemVal = sliderItemSelected.toLowerCase().trim().replace(/\s/g, '');
    urlToRedirectVal = heroBannerConfiguration[`${searchModelTypeVal}_${sliderItemVal}_url`];
  } else {
    urlToRedirectVal = heroBannerConfiguration[`${searchModelTypeVal}_${customSearchTypeVal}_url`];
  }

  return urlToRedirectVal;
}

const setIsSearching = (value: any) => {
  isSearching = value;
};

export function HeroBanner(
  props: BrProps,
  displayBackground = true,
  displayBannerBar = true,
  defaultSearch = undefined,
  onSearch = () => {}
) {
  const history = useHistory();
  // eslint-disable-next-line
  const [currentFloorPlanSearch, setCurrentFloorPlanSearch] =
    useStateWithLocalStorage('currentFloorPlanSearch');
  // eslint-disable-next-line
  const [currentRetailerSearch, setCurrentRetailerSearch] =
    useStateWithLocalStorage('currentRetailerSearch');
  const [searchText, setSearchText] = useState({
    description: '',
    lat: null,
    lng: null,
    terms: null,
  });

  const {
    document: documentRef,
    documents = [],
    componentParameterMap,
    heroBannerConfiguration,
    heroBannerLabels,
    googleMapConfig,
    spinnerConfig,
  } = props.component.getModels();

  const componentId = props.component.getId();

  const buildingCenterDoc = getBuildingCenterFromHeader(props.page);
  const buildingCenterPath = buildingCenterDoc?.buildingCenterPath;

  // The search results hero banner has 3 documents: bannerDocument and two simple content documents
  let bannerDocument!: Content | undefined;
  let contentDocument1!: Content | undefined;
  let contentDocument2!: Content | undefined;

  if (documentRef !== undefined) {
    bannerDocument = documentRef && props.page.getContent(documentRef);
  } else {
    bannerDocument = documents[0] && props.page.getContent(documents[0]);
    contentDocument1 = documents[1] && props.page.getContent(documents[1]);
    contentDocument2 = documents[2] && props.page.getContent(documents[2]);
  }

  const bannerBarComp =
    props.page.getComponent('hero', 'bannerBar') ||
    props.page.getComponent('main', 'bannerBar') ||
    props.page.getComponent('main', 'detailcontainer', 'bannerBar');

  const bannerDocumentData: any = bannerDocument && bannerDocument.getData<HeroBannerData>();
  const id = bannerDocumentData && bannerDocumentData.id;
  const imageRef = bannerDocumentData && bannerDocumentData.backgroundImage;
  const searchTitle1 = bannerDocumentData && bannerDocumentData.searchTitle1;
  const searchTitle2 = bannerDocumentData && bannerDocumentData.searchTitle2;
  const customSearchType = bannerDocumentData && bannerDocumentData.customSearchType;
  const searchModelType = bannerDocumentData && bannerDocumentData.searchModelType;
  const searchBoxHelp = bannerDocumentData && bannerDocumentData.searchBoxHelp;

  const header = contentDocument1?.getData<DocumentContent>();
  const map = contentDocument2?.getData<DocumentContent>();
  const defaultSearchBoxHelpText = 'Enter City/State or Province';
  const defaultDropdownPrefixLabel = 'Find a';

  const { headingLevel, background, variant, textEmphasis } = componentParameterMap;

  let backgroundThemeVal = getBackgroundThemeVal(background);
  const showComboSearch =
    variant === HERO_BANNER_VARIANTS.DROPDOWN_MENU_SEARCH ||
    variant === HERO_BANNER_VARIANTS.SLIDER_SEARCH ||
    variant === HERO_BANNER_VARIANTS.SIMPLE_SEARCH;
  const showDropdown = variant === HERO_BANNER_VARIANTS.DROPDOWN_MENU_SEARCH;
  const showSlider = variant === HERO_BANNER_VARIANTS.SLIDER_SEARCH;
  const showSimpleSearch = variant === HERO_BANNER_VARIANTS.SIMPLE_SEARCH;
  const textEmphasisVal = getTextEmphasisVal(textEmphasis);
  const searchBoxHelpVal = searchBoxHelp ?? defaultSearchBoxHelpText;
  const dropdownPrefixLabelVal =
    heroBannerLabels && heroBannerLabels.dropdown_prefix
      ? heroBannerLabels.dropdown_prefix
      : defaultDropdownPrefixLabel;

  const headingLevelChoice = getHeadingLevelVal(headingLevel);

  const customSearchTypeVal = defaultSearch || customSearchType;
  const searchModelTypeVal = searchModelType;

  const image = imageRef && props.page.getContent(imageRef.imagelink);
  const searchTitle1Val = searchTitle1;
  const searchTitle2Val = searchTitle2;

  const isParkModel = searchModelType === 'park';

  const heroBannerClasses = classNames({
    'cvc-hero-banner': true,
    'mb-4': true,
    'is-park-model': isParkModel,
    'show-dropdown': showDropdown,
    'show-slider': showSlider,
    'show-combo-search': showComboSearch,
    'show-simple-search': showSimpleSearch,
    'show-custom-titles': !showComboSearch,
    'no-image': !image,
    'with-custom-params': displayBackground === false,
  });

  if (!backgroundThemeVal && !image) {
    backgroundThemeVal = 'is-dark';
  }

  const spinnerColorInLightBackground =
    spinnerConfig && spinnerConfig.spinner_color_light_background;

  const goToPage = (data: any) => {
    const isFloorPlanSearch = data.url.includes('our-homes') || data.url.includes('park-models');
    data.appendParamOnly =
      buildingCenterPath !== undefined &&
      isFloorPlanSearch &&
      !SiteGlobalSwitches.useElasticSearchFloorplans;

    let urlVal = GoToPageUrl(data);

    if (isFloorPlanSearch) {
      setCurrentFloorPlanSearch({
        latitude: data.lat,
        longitude: data.lng,
        dataTerms: data.terms,
      });
    } else if (data.url.includes('our-retailers') || data.url.includes('park-retailers')) {
      setCurrentRetailerSearch({
        latitude: data.lat,
        longitude: data.lng,
        dataTerms: data.terms,
      });
    }

    if (buildingCenterPath) {
      urlVal = isFloorPlanSearch
        ? `/building-center${buildingCenterPath}${urlVal.replace(
            /our-homes|park-models/g,
            'floorplans/search'
          )}`
        : `/building-center${buildingCenterPath}${urlVal}`;
    }
    history.push(formatForHistory(props.page.getUrl(urlVal)));
    setIsSearching(false);
  };

  const heroBannerSearchInputFocus = () => {
    const heroBannerSearchInput = document.getElementById(
      'hero-banner-react-google-places-autocomplete-input'
    );
    if (heroBannerSearchInput) {
      (heroBannerSearchInput as HTMLInputElement).focus();
    }
  };

  const sendToPlace = () => {
    setIsSearching(true);

    // console.log("sendToPlace", data.terms);
    if (searchText.description !== '') {
      geocodeByAddress(searchText.description)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) =>
          goToPage({
            url: getUrlToRedirect(
              showDropdown,
              showSlider,
              heroBannerConfiguration,
              searchModelTypeVal,
              customSearchTypeVal,
              componentId
            ),
            terms: searchText.terms,
            lat,
            lng,
          })
        )
        .then(() => onSearch());
    } else {
      setIsSearching(false);
      heroBannerSearchInputFocus();
    }
  };

  const handleSuggestionSelected = (suggestionSelected: any) => {
    setSearchText(suggestionSelected);
    heroBannerSearchInputFocus();
  };

  const apiKey = googleMapConfig && googleMapConfig.apiKey ? googleMapConfig.apiKey : '';
  const internalLoc =
    googleMapConfig && googleMapConfig.mapEndpoint ? googleMapConfig.mapEndpoint : '';

  const suggestionClassNames = {
    container: 'pac-item',
    suggestion: 'pac-container',
    suggestionActive: 'pac-container--active',
  };

  useEffect(() => {
    const searchInputBox: any = document.querySelector('.cvc-hero-banner-content__search-text')!;
    if (searchInputBox) {
      const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) {
          sendToPlace();
        }
      };

      searchInputBox.addEventListener('keydown', handleKeyDown);
      return () => searchInputBox.removeEventListener('keydown', handleKeyDown);
    }
  });
  if (!bannerDocument) return null;

  return (
    <div
      id={id}
      className={`${heroBannerClasses} ${backgroundThemeVal} ${
        !showComboSearch ? textEmphasisVal : ''
      }${props.page.isPreview() ? ' has-edit-button' : ''}${
        displayBannerBar && bannerBarComp ? ' banner-bar' : ''
      }`}
    >
      <BrManageContentButton content={bannerDocument} />
      {displayBackground && (
        <div
          className="cvc-hero-banner-image"
          style={image ? { backgroundImage: image && `url(${image.getUrl()})` } : {}}
          role="img"
          aria-label={imageRef ? imageRef.alt : 'hero-banner-background'}
        />
      )}
      <div className="cvc-hero-banner-content-wrapper">
        <div className="cvc-hero-banner-content">
          {contentDocument1 && header?.title && (
            <h3 className="cvc-hero-banner-content-intro">{header?.title}</h3>
          )}
          {contentDocument1 ? (
            <div
              className="cvc-hero-banner-content-header"
              dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(header?.content.value) }}
            />
          ) : (
            ''
          )}
          <div className="cvc-hero-banner-content__search">
            <div className="cvc-hero-banner-content__search-form">
              {showComboSearch
                ? displayComboSearch(
                    isParkModel,
                    dropdownPrefixLabelVal,
                    customSearchTypeVal,
                    heroBannerLabels,
                    componentId
                  )
                : displayCustomTitles(headingLevelChoice, searchTitle1Val, searchTitle2Val)}
              <div className="cvc-hero-banner-content__search-container">
                <AutocompleteComp
                  apiKey={checkForNessus(apiKey)}
                  placeholder={searchBoxHelpVal}
                  onClick={(suggestionSelected: any) =>
                    handleSuggestionSelected(suggestionSelected)
                  }
                  geocodeEndpoint={internalLoc}
                  suggestionsClassNames={suggestionClassNames}
                  inputClassName="cvc-hero-banner-content__search-text"
                  elementId={!displayBackground ? 'hero-banner-custom' : 'hero-banner'}
                />
                {!isSearching ? (
                  <SearchIcon
                    className="cvc-hero-banner-content__search-icon"
                    onClick={sendToPlace}
                  />
                ) : (
                  <Spinner
                    color={spinnerColorInLightBackground ? `${spinnerColorInLightBackground}` : ''}
                  />
                )}
              </div>
            </div>
            {contentDocument2 ? (
              <div
                className="cvc-hero-banner-content-map"
                dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(map?.content.value) }}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        {displayBannerBar && bannerBarComp && (
          <BannerBar page={props.page} component={bannerBarComp} />
        )}
      </div>
    </div>
  );
}

export const HeroBannerWithParams: FunctionComponent<any> = (heroProps) => {
  const { customParams, onSearch, ...props } = heroProps;
  const { displayBackground } = heroProps.component.getModels();
  return HeroBanner(
    props,
    displayBackground || customParams.displayBackground,
    customParams.displayBannerBar,
    customParams.defaultSearch,
    onSearch
  );
};
