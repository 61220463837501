import { BrProps } from '@bloomreach/react-sdk';
import React, { useEffect, useState } from 'react';
import './CavcoAlbum.scss';
import { ALBUM_MEDIA_TYPES, SCREEN_SIZES } from '../enums';
import { display3DTour, displayYoutubeVideo } from '../utils';
import { ReactComponent as VideoIcon } from '../../assets/images/video-icon-mini.svg';
import { GalleryViewer } from '../FloorPlanBanner/GalleryViewer';
import { CavcoLink } from '../FormComponents/CavcoLink';

export function CavcoAlbum(props: BrProps) {
  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const [patterMobile, setPatterMobile] = React.useState(false);
  const [showMediaViewer, setShowMediaViewer] = React.useState(false);
  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);
  const [indexItemSelected, setIndexItemSelected] = useState(0);

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  function getImage(imageGalleryName: string, item: any, imgContent: any) {
    let cavcoThumbnailImgPath = '';
    let mediumImgPath = '';
    let largeImgPath = '';
    let extraLargeImgPath = '';

    if (imgContent) {
      cavcoThumbnailImgPath =
        imgContent.model.cavcoThumbnail && imgContent.model.cavcoThumbnail._links.site.href
          ? imgContent.model.cavcoThumbnail._links.site.href
          : null;
      mediumImgPath =
        imgContent.model.medium && imgContent.model.medium._links.site.href
          ? imgContent.model.medium._links.site.href
          : null;
      largeImgPath =
        imgContent.model.large && imgContent.model.large._links.site.href
          ? imgContent.model.large._links.site.href
          : null;
      extraLargeImgPath =
        imgContent.model.extraLarge && imgContent.model.extraLarge._links.site.href
          ? imgContent.model.extraLarge._links.site.href
          : null;
    } else if (item.ximage) {
      cavcoThumbnailImgPath =
        item.ximage.cavcoThumbnail && item.ximage.cavcoThumbnail.url
          ? item.ximage.cavcoThumbnail.url
          : null;
      mediumImgPath = item.ximage.medium && item.ximage.medium.url ? item.ximage.medium.url : null;
      largeImgPath = item.ximage.large && item.ximage.large.url ? item.ximage.large.url : null;
      extraLargeImgPath =
        item.ximage.extraLarge && item.ximage.extraLarge.url ? item.ximage.extraLarge.url : null;
    }

    return (
      <picture key={`${imageGalleryName}-${item.id}`}>
        <source media={`(min-width:${SCREEN_SIZES.DESKTOP_BIG}px)`} srcSet={extraLargeImgPath} />
        <source
          media={`(min-width:${SCREEN_SIZES.DESKTOP}px) and (max-width:${SCREEN_SIZES.DESKTOP_MAX_WIDTH}px)`}
          srcSet={largeImgPath}
        />
        <source
          media={`(min-width:${SCREEN_SIZES.TABLET}px) and (max-width:${SCREEN_SIZES.TABLET_MAX_WIDTH}px)`}
          srcSet={mediumImgPath}
        />
        <img alt={item.imageAltText} src={cavcoThumbnailImgPath} />
      </picture>
    );
  }

  const {
    documents,
    document: documentRef,
    imageGalleryLabels,
    spinnerConfig,
  } = props.component.getModels();

  let document = documentRef ? props.page.getContent(documentRef) : undefined;
  if (documents && !document) {
    document = props.page.getContent(documents[0]);
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize);
    if (windowDimension.winWidth < 800) {
      setPatterMobile(true);
    } else {
      setPatterMobile(false);
    }
    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimension]);

  const imageGalleryName = props.component.getName();
  const isBackedPage = imageGalleryName === 'content';

  const calculateItemToShow = (n: number) => {
    if (n <= 5) {
      return n;
    }
    return 5;
  };

  const getPattern = (n: number, mobile: boolean) => {
    if (mobile) {
      if (n === 1) {
        return `"item1 item1 item1"`;
      }
      if (n === 2) {
        return `"item1 item1 item1" "item2 item2 item2"`;
      }
      if (n === 3) {
        return `"item1 item1 item1" "item2 item2 item3"`;
      }
      if (n === 4) {
        return `"item1 item1 item1" "item2 item3 item3" "item4 item4 item4"`;
      }
      return `"item1 item1 item1" "item2 item3 item3" "item4 item4 item5"`;
    }

    if (n === 1) {
      return `"item1 item1 item1 item1" "item1 item1 item1 item1"`;
    }
    if (n === 2) {
      return `"item1 item1 item2 item2" "item1 item1 item2 item2"`;
    }
    if (n === 3) {
      return `"item1 item1 item2 item2"
         "item1 item1 item3 item3"`;
    }
    if (n === 4) {
      return `"item1 item1 item2 item3"
        "item1 item1 item4 item4"`;
    }
    return `"item1 item1 item2 item3"
        "item1 item1 item4 item5"`;
  };

  if (!document) {
    return null;
  }

  const { title, description, mediaAsset, mediaType, button } = document?.getData<AlbumData>();

  const itemToPattern = calculateItemToShow(mediaAsset.length);

  function displayAlbumMediaItem(
    imageGalleryNameProp: string,
    mediaTypeProp: string,
    item: any,
    imgContent: any,
    index: any,
    gridArea: string,
    handleShowMediaViewerProp = () => {},
    setIndexItemSelectedProp: any
  ) {
    const handleMediaItemClick = () => {
      setIndexItemSelectedProp(Number(index));
      handleShowMediaViewerProp();
    };
    const handlekeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 32) {
        setIndexItemSelectedProp(Number(index));
        handleShowMediaViewerProp();
      }
    };

    switch (mediaTypeProp) {
      case ALBUM_MEDIA_TYPES.IMAGE:
        return (
          <div
            role="presentation"
            key={`${imageGalleryNameProp}-image-${item.id}-${index}`}
            style={{ gridArea }}
            className="cvc-album-item cvc-album-media-item"
            onClick={() => handleMediaItemClick()}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlekeyDown(e)}
          >
            {imgContent || item.ximage ? getImage(imageGalleryNameProp, item, imgContent) : ''}
          </div>
        );
      case ALBUM_MEDIA_TYPES.VIDEO:
        return item.videoURL !== '' ? (
          <div
            role="presentation"
            key={`${imageGalleryNameProp}-video-container-${item.id}`}
            style={{ gridArea }}
            className="cvc-album-item cvc-album-media-item-video-container"
            onClick={() => handleMediaItemClick()}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlekeyDown(e)}
          >
            <div
              key={`${imageGalleryNameProp}-album-media-item-video-${item.id}`}
              className="cvc-album-media-item"
            >
              {item.videoURL ? displayYoutubeVideo(item) : ''}
            </div>
            <div
              key={`${imageGalleryNameProp}-album-media-item-video-link-${item.id}`}
              className="cvc-album-media-item-video-link"
              tabIndex={-1}
            >
              <VideoIcon
                key={`${imageGalleryNameProp}-album-media-item-video-icon-${item.id}`}
                className="cvc-album-media-item-video-icon"
              />
            </div>
          </div>
        ) : (
          ''
        );
      case ALBUM_MEDIA_TYPES.VIRTUAL_TOUR:
      case ALBUM_MEDIA_TYPES.PANATOUR:
        return item.matterportURL !== '' ? (
          <div
            role="presentation"
            style={{ gridArea }}
            key={`${imageGalleryNameProp}-virtual-${item.id}`}
            className="cvc-album-item cvc-album-media-item-virtual-container"
            onClick={() => handleMediaItemClick()}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handlekeyDown(e)}
          >
            <div className="cvc-album-media-item">
              {item.matterportURL ? display3DTour(item) : ''}
            </div>
            <div
              key={`${imageGalleryNameProp}-album-media-item-virtual-link-${item.id}`}
              className="cvc-album-media-item-virtual-link"
              tabIndex={-1}
            >
              <div className="cvc-album-media-item-virtual-text">3D</div>
            </div>
          </div>
        ) : (
          ''
        );
      default:
        break;
    }
  }

  function displayMediaAssets(
    displayProps: BrProps,
    isBackedPageParam: boolean,
    imageGalleryNameParam: string,
    mediaTypeParam: string,
    mediaAssetItems: [MediaAssetCompound],
    maxItems: number,
    imageGalleryLabelsParam: any,
    handleShowMediaViewerParam = () => {},
    setIndexItemSelectedParam: any
  ) {
    const items = [...new Array(maxItems)].map(
      (item, index) =>
        mediaAssetItems[index] || {
          id: `${imageGalleryNameParam}-empty-${index}`,
          key: `${imageGalleryNameParam}-empty-${index}`,
        }
    );

    return (
      <>
        {items.map((item: any, index) => {
          const imgContent: any = item && displayProps.page.getContent(item.image);
          const gridItem = `item${index + 1}`;
          const imageKey = `image-gallery-${index}`;
          return (
            <React.Fragment key={imageKey}>
              {displayAlbumMediaItem(
                imageGalleryNameParam,
                mediaTypeParam,
                item,
                imgContent,
                index,
                gridItem,
                handleShowMediaViewerParam,
                setIndexItemSelectedParam
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  }

  return (
    <div>
      <GalleryViewer
        show={showMediaViewer}
        onHide={handleCloseMediaViewer}
        mediaType={ALBUM_MEDIA_TYPES.IMAGE}
        mediaAssetItems={mediaAsset}
        albumName={title}
        albumLink=""
        hasParentPageFloorPlan={false}
        indexItemSelected={indexItemSelected}
        spinnerColorInDarkBackground={spinnerConfig}
        headerComponentType="album"
      />
      <div className="cavco-album-container">
        <div className="cavco-album-title-container">
          <h1>{title}</h1>
          <div
            className="album-description"
            dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(description.value) }}
          />
        </div>
        <div
          className="cavco-album-image-gallery"
          style={{ gridTemplateAreas: getPattern(itemToPattern, patterMobile), gap: '8px' }}
        >
          {displayMediaAssets(
            props,
            isBackedPage,
            imageGalleryName,
            mediaType,
            mediaAsset,
            itemToPattern,
            imageGalleryLabels,
            handleShowMediaViewer,
            setIndexItemSelected
          )}
        </div>
        <div>
          {button && button.destinationUrl && (
            <div className="image-button-container">
              <CavcoLink
                label={button.displayText}
                link={button.destinationUrl}
                variant="yellow"
                newWindow={button.newWindow}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
