/*
 *
 * This component creates the metadata for the Display Home Detail pages
 * This is a Document-backed page
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrProps } from '@bloomreach/react-sdk';
import { getInventoryData, getRetailerData, findBuildingCenterFromPage } from 'components/utils';
import { GetFirstImgUrl } from '../FloorPlanBanner/FloorPlanBanner';
import { BuildCannonicalURL } from './CannonicalURL';

/*
function buildMetaKeywords(data: any) {
  let metaKeywords = `${
    (data.buildingMethodValue ? `${data.buildingMethodValue}, ` : '') +
    (data.dimensionalArea ? `${data.dimensionalArea}, ` : '') +
    data.modelName
  }, ${data.modelNumber}, ${data.bedRoomsValue} bedroom, ${data.bedRoomsValue} bathroom, `;
  metaKeywords += data.squareFeet ? `${data.squareFeet.toString()} square foot, ` : '';
  metaKeywords = `${metaKeywords + data.name}, ${data.sectionsValue ? data.sectionsValue : ''}`;
  return metaKeywords;
}
*/

function buildCavcoMetaImage(props: BrProps, data: InventoryData) {
  let ogImage = '';
  const photosDocument = data.photoAlbum && props.page.getContent(data.photoAlbum);
  if (photosDocument) {
    const imgUrl = GetFirstImgUrl(props, data.photoAlbum);
    if (imgUrl) ogImage = imgUrl;
  } else {
    // get photos from floorplan
    const floorPlanDoc = data.floorPlan && props.page.getContent(data.floorPlan);
    if (floorPlanDoc) {
      const floorPlanData = floorPlanDoc.getData<FloorPlanData>();
      const floorplanPhotos = floorPlanData.photos && props.page.getContent(floorPlanData.photos);
      if (floorplanPhotos) {
        const imgUrl = GetFirstImgUrl(props, floorPlanData.photos);
        if (imgUrl) ogImage = imgUrl;
      }
    }
  }
  return ogImage;
}

function buildMetaImage(props: BrProps, data: FloorPlanData) {
  let ogImage = '';
  const photosDocument = data.photos && props.page.getContent(data.photos);
  if (photosDocument) {
    const imgUrl = GetFirstImgUrl(props, data.photos);
    if (imgUrl) ogImage = imgUrl;
  }
  return ogImage;
}

function buildCmsOgImage(props: BrProps, imageRef: any) {
  const image = imageRef && props.page.getContent(imageRef.imagelink);
  if (!image) return undefined;
  return image.getUrl();
}

function getDisplayHomeDataFromInventoryData(props: BrProps) {
  const buildingCenterData = findBuildingCenterFromPage(props?.page);
  const pageComponents = props.page.getComponent('main', 'detailcontainer');
  const inventoryData = pageComponents?.getComponent('inventory-data');
  if (!inventoryData) return buildingCenterData?.dba;
  return inventoryData?.getModels()?.['displayHomeData'];
}

const pluralizer = (val: string | number, desc: string) =>
  val === '1' || val === 1 ? desc : `${desc}s`;

function pagePropertiesForRtlData(props: BrProps, data: any, links: any) {
  const { componentParameterMap } = props.component.getModels();
  const { metadata: cmsMetadata } = data;
  const parkFind: boolean = data.buildingMethodValue
    ? data.buildingMethodValue.toString().toLowerCase().includes('park')
    : false;
  const { pageType } = componentParameterMap;
  //const pageTitle = `Manufactured Homes in ${data.city}, ${data.state} | Mobile Homes for Sale | ${componentParameterMap.companyFull}`;

  const displayHomeData = getDisplayHomeDataFromInventoryData(props);
  const brand = displayHomeData?.builder;
  const retailerData = getRetailerData(props?.page);

  const buildingMethod = parkFind ? data.buildingMethodValue : `${data.buildingMethodValue} Home`;

  let cavcoPageTitle = `${data.modelName} ${data.modelNumber} ${buildingMethod} from ${brand}, ${retailerData?.city}, ${retailerData?.state} | ${retailerData?.dba}, a Cavco Company`;
  const canonicalUrl = cmsMetadata?.canonicalUrl || BuildCannonicalURL(links);
  let metaDescription =
    cmsMetadata?.metaDescription ||
    `${data.modelName} ${data.modelNumber} ${buildingMethod.toLowerCase()} from ${brand} features ${
      data.bedRoomsValue
    } ${pluralizer(data.bedRoomsValue, 'bedroom')}, ${data.bathRoomsValue} ${pluralizer(
      data.bathRoomsValue,
      'bath'
    )} and ${
      data.squareFeet
    } square feet of living space. Learn more about this floor plan at cavcohomes.com or come see it at our showroom in ${
      retailerData?.city
    }, ${retailerData?.state}!`;

  if (pageType && pageType === 'MIR') {
    cavcoPageTitle = `${brand} ${data.squareFeet} Sq Ft Mobile Home for Sale in ${retailerData?.city}, ${retailerData?.state} | ${retailerData?.dba}, a Cavco Company`;
    metaDescription = `Move-in-ready ${data.modelName} ${data.modelNumber} mobile home for sale in ${retailerData?.city}, ${retailerData?.state}. See the details at cavcohomes.com and chat with our sales manager to get started.`;
  }
  const pageTitle = cavcoPageTitle;
  const metaKeywords =
    cmsMetadata?.metaKeywords ||
    `manufactured homes for sale, modular homes for sale, modular homes, manufactured homes, singlewide homes, doublewide homes, manufactured home floor plans, modular home floor plans, display homes for sale.`;
  const ogTitle = cmsMetadata?.ogTitle || pageTitle;
  const ogImage = buildCmsOgImage(props, cmsMetadata?.socialImage) || buildMetaImage(props, data);
  const elasticImage = ogImage || buildCavcoMetaImage(props, data);
  const ogDescription = cmsMetadata?.ogDescription || metaDescription;
  const indexRobot = componentParameterMap.indexRobot || 'index';
  const followRobot = componentParameterMap.followRobot || 'follow';
  const robots = [indexRobot, followRobot];
  const robotsTag = robots && <meta name="robots" content={robots.join(',')} />;
  return (
    <div>
      <Helmet>
        {!pageType && pageTitle && <title>{pageTitle}</title>}
        {pageType && <title>{cavcoPageTitle}</title>}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogTitle && <meta name="twitter:title" content={ogTitle} />}
        {pageType && <meta className="elastic" name="type" content={`${pageType}`} />}
        {pageType && (
          <meta className="sort_group" name="type" content={pageType === 'MIR' ? '4' : '5'} />
        )}
        {elasticImage && (
          <meta className="elastic" name="search_image" content={`${elasticImage}`} />
        )}
        {ogImage && <meta property="og:image" content={`${ogImage}`} />}
        {ogImage && <meta name="twitter:image" content={`${ogImage}`} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogDescription && <meta name="twitter:description" content={ogDescription} />}
        {robotsTag}
      </Helmet>
    </div>
  );
}
export function PagePropertiesRtlDisplayHome(props: BrProps) {
  //  const details = props.page.getComponent('main', 'detailcontainer');
  //  const form = details && details.getComponent('dh-data');
  //  const doc = form && form.getModels().document;
  //  const document = props.page.getContent(doc);
  //  const dm = document && document.getData();
  const { componentParameterMap } = props.component.getModels();
  const { pageType } = componentParameterMap;
  const { page } = props;

  if (pageType) {
    const inventory = getInventoryData(page);
    if (inventory) {
      return pagePropertiesForRtlData(props, inventory, inventory?._links);
    }
  }
  const dm = getRetailerData(page);
  if (!dm) {
    return null;
  }
  return pagePropertiesForRtlData(props, dm, dm._links);
}
