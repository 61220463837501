import { BrProps } from '@bloomreach/react-sdk';
import { Content } from '@bloomreach/spa-sdk';
import {
  FormConfigurationProps,
  RequestInfoFormResourceBundleApiConfigProps,
  RequestInfoFormResourceBundleLabelProps,
} from './requestInfoForm.interface';

const processTime = (time: string) => {
  if (!time || time === '') {
    return time;
  }
  const hrmin = time.split(':');
  const numHour = Number(hrmin[0]);
  const hr = numHour % 12;
  const meridian = numHour >= 12 ? 'PM' : 'AM';
  return `${hr || 12}:${hrmin[1]} ${meridian}`;
};

const cleanDisplayInsideModal = (displayInsideModal: any) =>
  typeof displayInsideModal === 'object'
    ? !!Object.keys(displayInsideModal).length
    : displayInsideModal;

const objectToArray = (object: { [key: string]: string }): { key: string; value: string }[] => {
  return Object.entries(object)
    .map(([key, value]) => ({ key, value }))
    .sort();
};

const addVariableDataToString = (text: string, variable: string, toreplace: string) =>
  text.replace(toreplace, variable);

const checkForParkUrl = (buildingMethodValue: string, isRetailer: any) => {
  if (!buildingMethodValue) {
    return false;
  }
  const stringToCheck = isRetailer
    ? window.location.href
    : buildingMethodValue.toString().toLowerCase();
  return stringToCheck.includes('park');
};

export const handleErrorMessage = (error: any, modalData: any) => {
  const textLength = modalData.modalTextLength ? parseInt(modalData.modalTextLength, 10) : 50;
  const payloadErrorString = error.payload;
  const jsonText = payloadErrorString.slice(
    payloadErrorString.indexOf('{'),
    payloadErrorString.length
  );
  try {
    const parsedJson = JSON.parse(jsonText);
    const errorText = parsedJson.message;
    return errorText.length > textLength ? `${errorText.substr(0, textLength)}...` : errorText;
  } catch (err) {
    return 'An error occurred.';
  }
};

export function getDisplayHomeDataModel(page: any): any | null {
  const detailContainer = page && page.getComponent('main', 'detailcontainer');

  const dhDataComponent =
    detailContainer &&
    detailContainer.getChildren().find((comp: any) => comp.getName() === 'inventory-data');
  if (!dhDataComponent) {
    return null;
  }
  if (dhDataComponent.getModels() && dhDataComponent.getModels().displayHomeData) {
    return dhDataComponent.getModels().displayHomeData;
  }
  return '';
}

export function getRetailer(page: any): any | null {
  const detailContainer = page && page.getComponent('main', 'detailcontainer');

  const dhDataComponent =
    detailContainer &&
    detailContainer.getChildren().find((comp: any) => comp.getName() === 'retailer-data');

  if (!dhDataComponent) {
    return null;
  }

  const { documents = [] } = dhDataComponent.getModels();
  const ref = documents[0];
  const retailerContent: Content | undefined = ref && page.getContent(ref);
  return retailerContent ? retailerContent.getName() : '';
}

export const formConfigurationMapper = (
  props: BrProps,
  displayInsideModal: boolean
): FormConfigurationProps => {
  const {
    documents,
    requestInfoFormLabels,
    componentParameterMap,
    requestInfoFormConfig,
    formModalLabel,
    usStates,
    caProvinces,
    spinnerConfig,
    isModal,
    inventoryType,
  }: {
    documents: any;
    requestInfoFormLabels: RequestInfoFormResourceBundleLabelProps;
    componentParameterMap: any;
    requestInfoFormConfig: RequestInfoFormResourceBundleApiConfigProps;
    formModalLabel: any;
    usStates: any;
    caProvinces: any;
    spinnerConfig?: any;
    isModal?: boolean;
    inventoryType?: string;
  } = props.component.getModels();

  const ref = documents && documents[0];
  const inventoryData = getDisplayHomeDataModel(props.page);
  const componentData = ref && props.page.getContent<any>(ref);
  const fpAssetId = componentData?.model.assetId;
  const { template, version } = componentParameterMap;
  const isRetailer = template === 'cavcohome-retailer';
  const fpPlaceHolder = isRetailer
    ? getRetailer(props.page)
    : `${componentData?.modelName ?? componentData?.model.modelName} ${
        componentData?.modelNumber ?? componentData?.model.modelNumber
      }`;
  const inventoryPlaceHolder = `${inventoryData?.modelName ?? inventoryData?.model.modelName} ${
    inventoryData?.modelNumber ?? inventoryData?.model.modelNumber
  }`;

  const variableStringData = inventoryType ? inventoryPlaceHolder : fpPlaceHolder;

  const apiWithParkUrl = checkForParkUrl(componentData?.buildingMethodValue, isRetailer)
    ? requestInfoFormConfig.requestInfoParkUrl
    : requestInfoFormConfig.requestInfoUrl;

  const {
    commentPH,
    commentPH_displayHome,
    commentPH_generic,
    commentPH_retailer,
    commentPH_mir,
    ...rest
  } = requestInfoFormLabels;

  let commentPHValue;
  let assetId;
  if (inventoryType === 'mir') {
    commentPHValue = commentPH_mir;
    assetId = inventoryData?.assetID;
  } else if (inventoryType === 'displayHome') {
    commentPHValue = commentPH_displayHome;
    assetId = inventoryData?.assetID;
  } else if (isRetailer) {
    commentPHValue = commentPH_retailer;
    assetId = '';
  } else {
    commentPHValue = commentPH;
    assetId = fpAssetId;
  }
  return {
    documents,
    isRetailer,
    displayAsModal: isModal || cleanDisplayInsideModal(displayInsideModal),
    apiUrl: `${requestInfoFormConfig.apiRoot}${apiWithParkUrl}`,
    formLabels: {
      ...rest,
      commentPlaceholderText: variableStringData
        ? addVariableDataToString(commentPHValue, variableStringData, '%')
        : commentPH_generic,
    },
    formModalLabel,
    statesProvinceList: objectToArray({ ...usStates, ...caProvinces }).sort(),
    version,
    spinnerConfig,
    webType: requestInfoFormConfig?.webType,
    assetId,
  };
};
export const formSubmit = (values: any, requestUrl: string) => {
  const finalValues = {
    ...values,
    appointmentTime: processTime(values.appointmentTime),
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(finalValues),
  };

  return fetch(requestUrl, requestOptions);
};
