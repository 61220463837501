export function BuildCannonicalURL(links: any, useWindowsHref = false) {
  if (useWindowsHref) {
    return window.location.href;
  }

  let canon = links.site.href;
  if (!links?.site?.href?.startsWith('http')) {
    const locationArr = window.location.href.split('/');
    canon = `${locationArr[0]}//${locationArr[2]}${links.site.href}`;
  }

  const splits = canon?.split('/');
  if (splits[splits.length - 1] === splits[splits.length - 2]) {
    canon = canon.slice(0, canon.lastIndexOf('/'));
  }

  return canon;
}
