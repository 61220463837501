import React, { FunctionComponent } from 'react';
import { Button } from 'react-bootstrap';
import { BrManageContentButton, BrProps } from '@bloomreach/react-sdk';
import './BannerBar.scss';
import { Link } from 'react-router-dom';

const isExternalUrl = (url: string) => {
  const externalUrlRegex = new RegExp(/^https?:\/\//);
  return externalUrlRegex.test(url);
};

export function BannerBar(props: BrProps) {
  const { document: documentRef } = props.component.getModels();
  const document = documentRef ? props.page.getContent(documentRef) : undefined;

  if (!document) {
    return null;
  }

  const { link1, link2, description, image: imageRef } = document.getData<BannerBarData>();

  const imgContent = imageRef && props.page.getContent(imageRef.imagelink);

  // TODO: obtain the small image path
  // const smallImgPath = imgContent && imgContent.model.small._links.site.href;
  const smallImgPath = imgContent && imgContent.getUrl();

  const ContentLink: FunctionComponent<any> = (linkProps) => {
    const {
      link: { destinationUrl, displayText, linkTitle, newWindow },
      variant: linkVariant,
    } = linkProps;

    if (!destinationUrl) {
      return null;
    }

    const isExternal = isExternalUrl(destinationUrl) ? Link : undefined;

    const newUrl = props.page.getUrl(destinationUrl) || destinationUrl;

    return (
      <Button
        className="cvc-banner-bar-content__link"
        variant={linkVariant}
        as={!isExternal ? Link : undefined}
        to={!isExternal ? newUrl : undefined}
        href={isExternal ? newUrl : undefined}
        title={linkTitle}
        target={newWindow ? '_blank' : undefined}
      >
        {displayText}
      </Button>
    );
  };

  return (
    <div className={`cvc-banner-bar${props.page.isPreview() ? ' has-edit-button' : ''}`}>
      <BrManageContentButton content={document} />
      <div className="cvc-banner-bar-content">
        {description && <span className="cvc-banner-bar-content__label">{description}</span>}
        <div className="cvc-banner-bar-content__links">
          {link1 && <ContentLink link={link1} variant="primary" />}
          {link2 && <ContentLink link={link2} variant="link" />}
        </div>
        {imgContent && (
          <img
            alt={imageRef.alt}
            className="cvc-banner-bar-content__image"
            src={smallImgPath}
            width="251"
            height="120"
          />
        )}
      </div>
    </div>
  );
}
