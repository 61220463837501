import React, { useEffect, useState } from 'react';
import { BrPageContext, BrProps } from '@bloomreach/react-sdk';
import './InspirationGallery.scss';
import { Tabs, Tab, ImageList, ImageListItem, LinearProgress } from '@material-ui/core';
import { Content } from '@bloomreach/spa-sdk';
import classNames from 'classnames';
import Scroll from 'react-scroll';
import { ALBUM_MEDIA_TYPES } from '../enums';
import { ArrowDrowDown } from './arrowDrowDown';
import { GalleryViewer } from '../FloorPlanBanner/GalleryViewer';
import { display3DTour, displayYoutubeVideo } from '../utils';

interface InspirationGalleryTabProps {
  showAll?: boolean;
  documents: ImageDoc[];
  title: string;
}

interface ImageDoc {
  id: string;
  title: string;
  albumUrl: string;
  mediaType: ALBUM_MEDIA_TYPES;
  description: {
    value: string;
  };
  mediaAsset: [MediaAssetCompound];
  name: string;
  imageList: JSX.Element;
}

interface ImageListProps {
  images: [MediaAssetCompound];
  albumTitle: string;
  albumName: string;
}

interface AssetImageProps {
  item: any;
  imgContent: any;
  mediaType: string;
  defaultImageSize: string;
  index: number;
}

function AssetImage(imgProps: AssetImageProps) {
  const { item, imgContent, mediaType, defaultImageSize, index } = imgProps;
  let largeImgPath = '';
  let extraLargeImgPath = '';

  let defaultImageSizeUrl = '';
  if (defaultImageSize !== '') {
    if (imgContent) {
      defaultImageSizeUrl =
        imgContent.model[`${defaultImageSize}`] &&
        imgContent.model[`${defaultImageSize}`]._links.site.href
          ? imgContent.model[`${defaultImageSize}`]._links.site.href
          : '';
    } else if (item.ximage) {
      defaultImageSizeUrl =
        item.ximage[`${defaultImageSize}`] && item.ximage[`${defaultImageSize}`].url
          ? item.ximage[`${defaultImageSize}`].url
          : '';
    }
  } else if (imgContent) {
    largeImgPath =
      imgContent.model.large && imgContent.model.large._links.site.href
        ? imgContent.model.large._links.site.href
        : null;
    extraLargeImgPath =
      imgContent.model.extraLarge && imgContent.model.extraLarge._links.site.href
        ? imgContent.model.extraLarge._links.site.href
        : null;
  } else if (item.ximage) {
    largeImgPath = item.ximage.large && item.ximage.large.url ? item.ximage.large.url : null;
    extraLargeImgPath =
      item.ximage.extraLarge && item.ximage.extraLarge.url ? item.ximage.extraLarge.url : null;
  }

  return defaultImageSize === '' ? (
    <img
      alt={item.imageAltText}
      style={{ OObjectFit: 'fill' }}
      src={largeImgPath || extraLargeImgPath}
      key={`key-image-${item.id}-${index}`}
    />
  ) : (
    <picture key={`${item.id}`} className="cvc-media-viewer__item-image">
      <img
        alt={item.imageAltText}
        src={defaultImageSizeUrl}
        key={`image-${item.id}-${index}`}
        className={classNames(
          `${mediaType === ALBUM_MEDIA_TYPES.LINE_DRAWING ? 'is-linedrawing' : ''}`
        )}
      />
    </picture>
  );
}

function calculateColsMobile(index: number, total: number) {
  let colSize;
  switch (index % 8) {
    case 0:
    case 5: {
      colSize = 6;
      break;
    }
    case 1:
    case 2: {
      colSize = 3;
      break;
    }
    case 3:
    case 7: {
      colSize = 2;
      break;
    }
    case 4:
    case 6: {
      colSize = 4;
      break;
    }
    default: {
      colSize = 6;
      break;
    }
  }

  if (index === total - 1) {
    if (colSize === 3 && index % 8 === 1) {
      return 6;
    }
    if (colSize === 2 && index % 8 === 3) {
      return 6;
    }
    if (colSize === 4 && index % 8 === 6) {
      return 6;
    }
  }
  return colSize;
}

function calculateCols(index: number, total: number) {
  if (total % 3 === 1) {
    if (index === total - 1) {
      return 4;
    }
  }

  if (total % 3 === 2) {
    if (index === total - 1) {
      return 2;
    }
    if (index === total - 2) {
      return 2;
    }
  }

  switch (index % 9) {
    case 0:
      return 1;
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 1;
    case 4:
      return 2;
    case 5:
      return 1;
    case 6:
      return 1;
    case 7:
      return 1;
    case 8:
      return 2;
    default:
      return 1;
  }
}

function CustomImageItem(
  item: any,
  index: number,
  galleryName: string,
  handleShowMediaViewer = () => {},
  setIndexItemSelected: any,
  size: number,
  page: any,
  cols: number
) {
  const imgContent: any = item && page.getContent(item.image);

  const handleMediaItemClick = () => {
    setIndexItemSelected(Number(index));
    handleShowMediaViewer();
  };

  const imageListItemKey = `imgkey-${index}`;
  switch (item.mediaType) {
    case ALBUM_MEDIA_TYPES.IMAGE:
      return (
        <ImageListItem className="imageList" key={imageListItemKey} cols={cols} rows={2}>
          <button
            type="button"
            className="cvc-album-item cvc-album-media-item"
            onClick={() => handleMediaItemClick()}
          >
            {imgContent || item.ximage ? (
              <AssetImage
                item={item}
                imgContent={imgContent}
                mediaType="Image"
                defaultImageSize=""
                index={index}
              />
            ) : (
              <></>
            )}
          </button>
        </ImageListItem>
      );
    case ALBUM_MEDIA_TYPES.VIDEO:
      return (
        <ImageListItem key={imageListItemKey} cols={cols} rows={2}>
          <button
            type="button"
            className="cvc-album-item cvc-album-media-item"
            onClick={() => handleMediaItemClick()}
          >
            {item.videoURL ? displayYoutubeVideo(item) : ''}
          </button>
        </ImageListItem>
      );
    case ALBUM_MEDIA_TYPES.VIRTUAL_TOUR:
    case ALBUM_MEDIA_TYPES.PANATOUR:
      return (
        <ImageListItem key={imageListItemKey} cols={cols} rows={2}>
          <button
            type="button"
            className="cvc-album-item cvc-album-media-item"
            onClick={() => handleMediaItemClick()}
          >
            {item.matterportURL ? display3DTour(item) : ''}
          </button>
        </ImageListItem>
      );
    default: {
      return null;
    }
  }
}

function ImageGrid(imageProps: ImageListProps) {
  const [showMediaViewer, setShowMediaViewer] = React.useState(false);
  const [pageNum, setPageNum] = React.useState(1);
  const mediaAssetItems = imageProps.images;
  const totalImg = mediaAssetItems.length;
  const [indexItemSelected, setIndexItemSelected] = useState(0);
  const [gridCol, setGridCol] = React.useState(24);
  const handleCloseMediaViewer = () => setShowMediaViewer(false);
  const handleShowMediaViewer = () => setShowMediaViewer(true);
  const spinnerConfig = 'black';
  const page = React.useContext(BrPageContext)!;
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const [showMore, setShowMore] = React.useState(true);

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);
    if (windowDimenion.winWidth < 800) {
      setGridCol(6);
    } else {
      setGridCol(4);
    }
    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimenion]);

  const pageSize = 24;

  const total = () => {
    if (pageNum * pageSize >= totalImg) {
      return 100;
    }
    return (pageNum * 24 * 100) / totalImg;
  };

  const getCurrentItems = () => {
    if (pageNum * pageSize <= totalImg) {
      return pageNum * pageSize;
    }
    return totalImg;
  };

  const getWidth = () => {
    if (windowDimenion.winWidth > 800) return 'auto';
    return windowDimenion.winWidth;
  };

  const updatePaginationButton = () => {
    const totalPages = Math.ceil(totalImg / pageSize);
    if (!showMore) {
      setPageNum((state) => state - 1);
      const scroll = Scroll.animateScroll;
      scroll.scrollToTop({ isDynamic: true });
    } else {
      setPageNum((state) => state + 1);
    }
    setShowMore(totalPages - 1 !== pageNum);
  };

  return (
    <div>
      <GalleryViewer
        show={showMediaViewer}
        onHide={handleCloseMediaViewer}
        mediaType={ALBUM_MEDIA_TYPES.IMAGE}
        mediaAssetItems={mediaAssetItems}
        albumName={imageProps.albumTitle}
        albumLink=""
        hasParentPageFloorPlan={false}
        indexItemSelected={indexItemSelected}
        spinnerColorInDarkBackground={spinnerConfig}
        headerComponentType="album"
        showThumbs={false}
      />
      <ImageList gap={16} cols={gridCol} style={{ width: getWidth() }} className="imageListDesktop">
        {mediaAssetItems
          .slice(0, pageNum * pageSize)
          .map((item: any, index: number) =>
            CustomImageItem(
              item,
              index,
              imageProps.albumName,
              handleShowMediaViewer,
              setIndexItemSelected,
              mediaAssetItems.length,
              page,
              calculateCols(index, mediaAssetItems.length)
            )
          )}
      </ImageList>
      <ImageList gap={8} cols={gridCol} style={{ width: getWidth() }} className="imageListMobile">
        {mediaAssetItems
          .slice(0, pageNum * pageSize)
          .map((item: any, index: number) =>
            CustomImageItem(
              item,
              index,
              imageProps.albumName,
              handleShowMediaViewer,
              setIndexItemSelected,
              mediaAssetItems.length,
              page,
              calculateColsMobile(index, mediaAssetItems.length)
            )
          )}
      </ImageList>
      <div className="load-more-container">
        <span>
          Showing {getCurrentItems()} of {totalImg} results.
        </span>
        <LinearProgress variant="determinate" value={total()} />
        {totalImg > pageSize && (
          <>
            <button type="button" className="load-more" onClick={updatePaginationButton}>
              {showMore ? 'Show More' : 'Show Less'}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export function castImageDoc(document: Content): ImageDoc {
  const { id, title, thisAlbumUrl, description, mediaType, mediaAsset, name } =
    document.getData<AlbumData>();
  mediaAsset.map((item: any) => {
    // eslint-disable-next-line
    return (item.mediaType = mediaType);
  });
  return {
    id,
    title,
    albumUrl: thisAlbumUrl,
    description,
    mediaAsset,
    mediaType,
    name,
    imageList: <ImageGrid images={mediaAsset} albumTitle={title} albumName={name} />,
  };
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export function mergeAllImages(documents: ImageDoc[]): ImageDoc {
  const allImages: [MediaAssetCompound] = [...documents[0]?.mediaAsset];
  documents.forEach((x, index) => {
    if (index > 0) {
      allImages.push(...x.mediaAsset);
    }
  });
  return {
    id: 'all',
    title: 'All',
    albumUrl: '',
    description: documents[0]?.description,
    mediaAsset: allImages,
    mediaType: documents[0]?.mediaType,
    name: 'all',
    imageList: <ImageGrid images={allImages} albumTitle="All" albumName="name" />,
  };
}

export function InspirationGallery(props: BrProps) {
  const {
    albumDocument1: documentRef1,
    albumDocument2: documentRef2,
    albumDocument3: documentRef3,
    albumDocument4: documentRef4,
    albumDocument5: documentRef5,
    albumDocument6: documentRef6,
    galleryTitle,
    includeCombinedAlbum,
  } = props.component.getModels();

  let listDocuments: ImageDoc[] = [];

  const document1 = documentRef1 && props.page.getContent(documentRef1);
  if (document1) {
    listDocuments.push(castImageDoc(document1));
  }
  const document2 = documentRef2 && props.page.getContent(documentRef2);
  if (document2) {
    listDocuments.push(castImageDoc(document2));
  }
  const document3 = documentRef2 && props.page.getContent(documentRef3);
  if (document3) {
    listDocuments.push(castImageDoc(document3));
  }
  const document4 = documentRef2 && props.page.getContent(documentRef4);
  if (document4) {
    listDocuments.push(castImageDoc(document4));
  }
  const document5 = documentRef2 && props.page.getContent(documentRef5);
  if (document5) {
    listDocuments.push(castImageDoc(document5));
  }
  const document6 = documentRef2 && props.page.getContent(documentRef6);
  if (document6) {
    listDocuments.push(castImageDoc(document6));
  }
  if (includeCombinedAlbum === 'on' && listDocuments?.length) {
    const demo = mergeAllImages(listDocuments);
    listDocuments = [demo, ...listDocuments];
  }

  return <CavcoAlbumGallery title={galleryTitle} albums={listDocuments} />;
}

interface CavcoAlbumGalleryProps {
  title: string;
  description?: string;
  albums: ImageDoc[];
}

export function CavcoAlbumGallery(cavcoAlbumProps: CavcoAlbumGalleryProps) {
  function InspirationGalleryTabs(propsTabs: InspirationGalleryTabProps) {
    const [value, setValue] = React.useState(propsTabs.documents[0]);
    const [albumTitle, setAlbumTitle] = React.useState(propsTabs.documents[0].title);

    const handleChange = (event: any, newValue: ImageDoc) => {
      setValue(newValue);
    };

    const handleSelectChange = (event: any) => {
      const albumSelected = propsTabs.documents[event.target.value];
      setAlbumTitle(propsTabs.documents[event.target.value].title);
      setValue(albumSelected);
    };

    return (
      <div className="image-list-container">
        <div className="filter-container">
          <span>
            Showing <strong>{albumTitle}</strong>
          </span>
          <div className="select-container">
            <select
              className="Select"
              style={{ backgroundColor: '#f4f7f8' }}
              onChange={handleSelectChange}
              value={-1}
            >
              <option key={-1} value={-1}>
                Filter
              </option>
              {propsTabs.documents?.map((item: ImageDoc, index: number) => (
                <option key={index} value={index}>
                  {item.title}
                </option>
              ))}
            </select>
            <ArrowDrowDown />
          </div>
        </div>
        <h1 className="gallery-title">Photo Gallery - {propsTabs.title}</h1>
        {cavcoAlbumProps.description && (
          <div className="gallery-description">
            <span>{cavcoAlbumProps.description}</span>
          </div>
        )}
        <div className="tab-container">
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {propsTabs.documents?.map((item: ImageDoc, index: number) => (
              <Tab
                key={index}
                value={item}
                label={item.title.toLowerCase()}
                {...a11yProps(index)}
                style={{ textTransform: 'capitalize' }}
              />
            ))}
          </Tabs>
        </div>
        <div className="imageContainer">{value.imageList}</div>
      </div>
    );
  }

  return (
    <div className="inspirationgallery-container">
      {cavcoAlbumProps.albums.length > 0 && (
        <InspirationGalleryTabs documents={cavcoAlbumProps.albums} title={cavcoAlbumProps.title} />
      )}
    </div>
  );
}
