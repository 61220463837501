import React, { useState } from 'react';
import { TextField as FormikTextField } from 'formik-material-ui';
import { Col, Row } from 'react-bootstrap';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import './CHAccountInfoForm.scss';
import { IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import useWindowDimensions from 'components/hooks/useWindowDimensions';
import type { AccountInfoPostValues } from '../../FavoriteComponent';

interface CHAccountInfoFormProps {
  accountInfo: AccountInfoPostValues;
  onSubmit: (values: any) => void;
  stateOptions?: any;
}

const PHONE_REGEX = /[0-9]{10}/g;
const ZIPCODE_REGEX = /[0-9]{5}(?:-[0-9]{4})?|[a-zA-Z][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]/;

const AccountInfoFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is a required field'),
  lastName: Yup.string().required('Last Name is a required field'),
  emailAddress: Yup.string()
    .email('Please provide a valid email address')
    .required('A valid email address is required'),
  cellPhone: Yup.string().notRequired().matches(PHONE_REGEX, 'Must be a valid phone number'),
  zip: Yup.string().notRequired().matches(ZIPCODE_REGEX, 'must be a valid zipcode'),
});

export const CHAccountInfoForm: React.FC<CHAccountInfoFormProps> = ({
  accountInfo,
  onSubmit,
  stateOptions = [],
}) => {
  return (
    <div className="ch-account-info-form">
      <Formik
        initialValues={accountInfo}
        onSubmit={(values, actions) => {
          onSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm({ values: { ...values } });
        }}
        validationSchema={AccountInfoFormSchema}
      >
        {({ values, isValid, dirty }) => (
          <Form>
            <legend>
              <p className="ch-account-info-form__legend-text">*Highlighted fields are required</p>
            </legend>
            <h3>Account Information</h3>
            <Row>
              <Col lg={6} xs={12}>
                <Field
                  id="firstName"
                  className="cvc-customer-support__input"
                  label="First Name*"
                  fullWidth
                  name="firstName"
                  value={values.firstName || ''}
                  component={FormikTextField}
                />
              </Col>
              <Col lg={6} xs={12}>
                <Field
                  className="cvc-customer-support__input"
                  id="lastName"
                  name="lastName"
                  label="Last Name*"
                  fullWidth
                  value={values?.lastName || ''}
                  component={FormikTextField}
                />
              </Col>
              <Col xs={12}>
                <Field
                  className="cvc-customer-support__input"
                  id="address1"
                  name="address1"
                  label="Street Address"
                  fullWidth
                  value={values?.address1 || ''}
                  component={FormikTextField}
                />
              </Col>
              <Col xs={12} lg={6}>
                <Field
                  className="cvc-customer-support__input"
                  id="city"
                  name="city"
                  label="City"
                  fullWidth
                  value={values?.city || ''}
                  component={FormikTextField}
                />
              </Col>
              <Col lg={2} xs={6}>
                <FormControl fullWidth className="cvc-customer-support__select">
                  <Field
                    id="state"
                    labelId="state-label"
                    name="state"
                    value={values?.state || ''}
                    component="select"
                  >
                    <option label="--" disabled />
                    {stateOptions.map((option: any) => {
                      return (
                        <option className="option-state" key={option[0]} value={option[0]}>
                          {option[0]}
                        </option>
                      );
                    })}
                  </Field>
                  <InputLabel id="state-label" htmlFor="state">
                    State
                  </InputLabel>
                </FormControl>
              </Col>
              <Col xs={6} lg={4}>
                <Field
                  className="cvc-customer-support__input"
                  id="zip"
                  name="zip"
                  label="Zip"
                  fullWidth
                  value={values?.zip || ''}
                  component={FormikTextField}
                />
              </Col>
              <Col xs={12} lg={6}>
                <Field
                  className="cvc-customer-support__input"
                  id="cellPhone"
                  name="cellPhone"
                  label="Phone"
                  fullWidth
                  value={values?.cellPhone || ''}
                  component={FormikTextField}
                />
              </Col>
              <Col xs={12} lg={6}>
                <Field
                  className="cvc-customer-support__input"
                  id="emailAddress"
                  name="emailAddress"
                  label="Email*"
                  fullWidth
                  value={values?.emailAddress || ''}
                  component={FormikTextField}
                />
              </Col>
            </Row>

            <button className="btn btn-cta" type="submit" disabled={!isValid || !dirty}>
              Save
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

interface CHPasswordResetFormProps {
  uid: string;
  onSubmit: (values: any) => void;
  handleForgotPassword: () => void;
}

const CHPasswordResetFormSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Please provide your current password'),
  newPassword: Yup.string()
    .required('A new password is required')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).{8,25}$/,
      'New Password must match all criteria'
    ),
  confirmNewPassword: Yup.string()
    .required('Please confirm your new password')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

export const CHPasswordResetForm: React.FC<CHPasswordResetFormProps> = ({
  uid,
  onSubmit,
  handleForgotPassword,
}) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const initialValues = {
    uid,
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };
  const windowDimensions = useWindowDimensions();
  const { isMobile, isTablet } = windowDimensions;

  return (
    <div className="ch-change-pw-form">
      <Formik
        initialValues={initialValues}
        validationSchema={CHPasswordResetFormSchema}
        onSubmit={(values, actions) => {
          onSubmit(values);
          actions.resetForm({ values: { ...values } });
          actions.setSubmitting(false);
        }}
      >
        {({ values, isValid, dirty }) => (
          <Form>
            <h3>Change Password</h3>
            <Row>
              <Col md={6} xs={12}>
                <Field
                  id="currentPassword"
                  className="cvc-customer-support__input"
                  label="Current Password*"
                  fullWidth
                  name="currentPassword"
                  value={values.currentPassword || ''}
                  component={FormikTextField}
                  type="password"
                />
              </Col>

              <Col md={6} xs={12} className="ch-account-info-form__forgot-pw">
                <button type="button" className="cvh-link" onClick={handleForgotPassword}>
                  Forgot your password?
                </button>
              </Col>

              <Col md={6} xs={12}>
                <Field
                  id="newPassword"
                  className="cvc-customer-support__input"
                  label="New Password*"
                  fullWidth
                  name="newPassword"
                  value={values.newPassword || ''}
                  component={FormikTextField}
                  type={!showNewPassword ? 'password' : 'text'}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        aria-label="toggle password visibility"
                      >
                        {!showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
              </Col>
              {(isMobile || isTablet) && (
                <Col xs={12}>
                  <legend>
                    <p className="ch-account-info-form__legend-bold">
                      Your password must include:{' '}
                    </p>
                    <ul>
                      <li>8-25 characters</li>
                      <li>Upper and lowercase letters</li>
                      <li>At least one number</li>
                      <li>At least one special character</li>
                    </ul>
                  </legend>
                </Col>
              )}
              <Col md={6} xs={12}>
                <Field
                  id="confirmNewPassword"
                  className="cvc-customer-support__input"
                  label="Confirm New Password*"
                  fullWidth
                  name="confirmNewPassword"
                  value={values.confirmNewPassword || ''}
                  component={FormikTextField}
                  type={!showConfirmPassword ? 'password' : 'text'}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        aria-label="toggle password visibility"
                      >
                        {!showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
              </Col>
            </Row>
            {!isMobile && !isTablet && (
              <legend>
                <p className="ch-account-info-form__legend-bold">Your password must include: </p>
                <ul>
                  <li>8-25 characters</li>
                  <li>Upper and lowercase letters</li>
                  <li>At least one number</li>
                  <li>At least one special character</li>
                </ul>
              </legend>
            )}
            <button className="btn btn-cta" type="submit" disabled={!isValid || !dirty}>
              Change
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
