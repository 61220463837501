interface RetailerInfo {
  address?: string;
  city?: string;
  distance?: number;
  inStock?: boolean | null;
  latitude?: number;
  longitude?: number;
  name?: string;
  phone?: string;
  state?: string;
  stateAbbr?: string;
  url?: string;
  zipCode?: string;
}
const createRetailerData = (rData: any) => {
  return {
    address: rData?.address1,
    city: rData?.city,
    distance: rData?.distance || null,
    inStock: null,
    latitude: rData?.latitude,
    longitude: rData?.longitude,
    name: rData?.name,
    phone: rData?.phone,
    state: rData?.state,
    stateAbbr: rData?.stateAbbr,
    url: rData?._links?.site?.href,
    zipCode: rData?.zip,
  };
};

export const mapMIRtoFpRetailers = (data: any) => {
  const details = data?.page.getComponent('main', 'detailcontainer');
  const docRef = details && details.getComponent('inventory-data')?.getModels()?.document;
  const inventoryDocument = data?.page.getContent(docRef);
  if (!inventoryDocument) return;

  const { retailerLocation, secondaryRetailers } = inventoryDocument?.getData();
  const prData = data?.page.getContent(retailerLocation)?.getData();
  const primeRetailer: RetailerInfo = createRetailerData(prData);

  const otherRetailers =
    secondaryRetailers &&
    secondaryRetailers.map((retailer: string) =>
      createRetailerData(data?.page.getContent(retailer)?.getData())
    );
  return [primeRetailer, ...otherRetailers];
};
