/*
 *
 * This component creates the metadata for the Retailer Interactive Builder page
 * This is a Document-backed page
 * ex url: https://qa.cavco.bloomreach.cloud/site/our-retailers/us/wi/fond-du-lac/flood-homes/builder
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrProps } from '@bloomreach/react-sdk';
import { GetFirstImgUrl } from '../FloorPlanBanner/FloorPlanBanner';
import { BuildCannonicalURL } from './CannonicalURL';

// {DBA} gallery includes [album names]
function buildMetaDescription(props: BrProps, data: RetailerData) {
  const { componentParameterMap } = props.component.getModels();
  const { companyFull } = componentParameterMap;
  return `${companyFull} retailer ${data.dba} offers a variety of colors and styles.  Choose your siding, shutters, roof, cabinets, countertops, trim, flooring and more!`;
}

function buildMetaImage(props: BrProps, photos: any) {
  let ogImage = '';
  const photosDocument = photos && photos[0] && props.page.getContent(photos[0]);
  if (photosDocument) {
    const imgUrl = GetFirstImgUrl(props, photos[0]);
    if (imgUrl) ogImage = imgUrl;
  }
  return ogImage;
}
function buildCmsOgImage(props: BrProps, imageRef: any) {
  const image = imageRef && props.page.getContent(imageRef.imagelink);
  if (!image) return undefined;
  return image.getUrl();
}

function pagePropertiesForRtlData(props: BrProps, data: RetailerData, links: any) {
  const { metadata: cmsMetadata } = data;
  const { componentParameterMap } = props.component.getModels();
  const { companyFull } = componentParameterMap;

  const pageTitle = cmsMetadata?.pageTitle || `${companyFull} Digital Home Design Center`;

  const canonicalUrl = cmsMetadata?.canonicalUrl || BuildCannonicalURL(links, true);
  const metaDescription = cmsMetadata?.metaDescription || buildMetaDescription(props, data);
  const metaKeywords = cmsMetadata?.metaKeywords || '';
  const ogTitle = cmsMetadata?.ogTitle || pageTitle;
  const ogImage =
    buildCmsOgImage(props, cmsMetadata?.socialImage) || buildMetaImage(props, data.photos);
  const imageAlt = cmsMetadata?.socialImage?.alt || '';
  const ogDescription = cmsMetadata?.ogDescription || metaDescription;
  const indexRobot = cmsMetadata?.index ? 'index' : 'noindex';
  const followRobot = cmsMetadata?.follow ? 'follow' : 'nofollow';
  const robots = [indexRobot, followRobot];
  const robotsTag = robots && <meta name="robots" content={robots.join(',')} />;

  return (
    <div>
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogTitle && <meta name="twitter:title" content={ogTitle} />}
        {ogImage && <meta property="og:image" content={`${ogImage}`} />}
        {ogImage && <meta name="twitter:image" content={`${ogImage}`} />}
        {imageAlt && <meta name="twitter:image:alt" content={imageAlt} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogDescription && <meta name="twitter:description" content={ogDescription} />}
        {robotsTag}
      </Helmet>
    </div>
  );
}
export function PagePropertiesRtlBuilder(props: BrProps) {
  const details = props.page.getComponent('main', 'detailcontainer');
  const form = details && details.getComponent('retailer-banner');
  const doc = form && form.getModels().documents[0];
  const document = props.page.getContent(doc);
  const dm = document && document.getData<RetailerData>();

  if (!dm) {
    return null;
  }
  return pagePropertiesForRtlData(props, dm, dm._links);
}
