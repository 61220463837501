import { BrPageContext } from '@bloomreach/react-sdk';
import React from 'react';
import { Link } from 'react-router-dom';

const isExternalUrl = (url: string) => {
  const externalUrlRegex = new RegExp(/^https?:\/\//);
  const result = externalUrlRegex.test(url);
  return result;
};

// const removeSiteOnLocal = (url = '') => {
//   if (
//     window.location.origin.includes('localhost:3000') &&
//     url &&
//     url.length &&
//     url.startsWith('/site')
//   ) {
//     return url.slice('/site'.length);
//   }
//   return url;
// };

const ExternalLink = ({ children, ...props }: any) => <a {...props}>{children}</a>;

const InternalLink = ({ children, href = '', ...props }: any) => {
  // const url = removeSiteOnLocal(href);
  const url = href;

  return (
    <Link {...props} to={url}>
      {children}
    </Link>
  );
};

const forceToString = (href = '') => {
  if (typeof href === typeof true) {
    return '';
  }

  return href.replace(/\s/g, '+');
};

export const containsAssetPath = (url: string) => {
  return url.indexOf('/assets/') > -1;
};

export const ResolverLink = ({ children, ...props }: any) => {
  const { href = '', target = '' } = props;
  const page = React.useContext(BrPageContext);

  let url = forceToString(href).replace(/\.\//, `${window.location.pathname.replace(/\/$/, '')}/`);
  // console.log("ResolverLink url: ", url);

  if (url !== '' && url.startsWith('http')) {
    const urlLocation = new URL(url);
    // console.log("urlLocation: ", urlLocation);
    const urlCmsBase = new URL(window.cmsBaseUrl);
    // console.log("urlCmsBase: ", urlCmsBase);
    if (
      (urlCmsBase.origin === urlLocation.origin || window.location.origin === urlLocation.origin) &&
      !containsAssetPath(url)
    ) {
      url = urlLocation.pathname;
      // console.log("if statement url: ", url);
    }
  }

  const isUrlExternal = isExternalUrl(url);
  const newURL = isUrlExternal ? url : page!.getUrl(url) || url;
  // console.log("newURL: ", newURL);

  // console.log('>>>>>>>>>>>>>>>>');
  // console.log('ResolverLink function');
  // console.log({ props, url, newURL });
  // console.log('>>>>>>>>>>>>>>>>');

  const Variant = isUrlExternal ? ExternalLink : InternalLink;

  // console.log("url: ", url);

  return (
    <Variant {...props} href={newURL} target={target}>
      {children}
    </Variant>
  );
};

export const formatForHistory = (url: string) => {
  // return removeSiteOnLocal(url);
  return url;
};
