import React from 'react';
import { Content, Reference, Page } from '@bloomreach/spa-sdk';
import { createBrowserHistory } from 'history';
import GoogleTagManager from 'react-gtm-module';
import { BrProps } from '@bloomreach/react-sdk';
import {
  OPERATIVE_SYSTEM,
  ENVIRONMENT,
  BLOOMREACH_CHANNEL_NAMES,
  SITE_NAMES,
  BUILDING_METHOD_KEY,
  BUILDING_METHOD_DISPLAY_VALUE,
  FOOTER_COMPONENTPARAMETERMAP_VARIANT,
  PAGE_VARIANT,
} from './enums';
import { getCookie } from './hooks/cookie';
// import { useStateWithLocalStorage } from './hooks/useStateWithLocalStorage';

const SiteGlobalSwitches: any = {};
export default SiteGlobalSwitches;

const currentUserAgent = window.navigator.userAgent;
const nessusUserAgent = 'cavconessus';

export function checkForNessus(apiKeyData?: any): any {
  return currentUserAgent !== nessusUserAgent ? apiKeyData : null;
}

export const TagManager: any =
  currentUserAgent === nessusUserAgent ? { dataLayer() {}, initialize() {} } : GoogleTagManager;

export function isStringOnOrTrue(tocheck: string): boolean {
  return !!tocheck && (tocheck.toLowerCase() === 'true' || tocheck.toLowerCase() === 'on');
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const BUNNY_NET_PULL_ZONE = 'https://vz-633a982d-715.b-cdn.net/';

export function getInventoryData(page: any): InventoryData | null {
  const inventoryPageContent =
    page &&
    page
      .getComponent()
      .getChildren()
      .find((comp: any) => comp.getName() === 'main');

  if (Array.isArray(inventoryPageContent.getChildren())) {
    const fistChildren = inventoryPageContent.getChildren()[0];
    const inventoryContent =
      fistChildren &&
      fistChildren.getChildren().find((comp: any) => comp.getName() === 'inventory-data');
    if (!inventoryContent) {
      return null;
    }
    const { document } = inventoryContent.getModels();
    const retailerContent: Content | undefined = document && page.getContent(document);
    const retailerData = retailerContent?.getData<InventoryData>();
    return retailerData || null;
  }

  return null;
}

// import { useHistory } from 'react-router-dom';
export function getRetailerData(page: any): RetailerData | null {
  const retailerHeaderContent =
    page &&
    page
      .getComponent()
      .getChildren()
      .find((comp: any) => comp.getName() === 'retailer-header-content');

  const retailerComponent =
    retailerHeaderContent &&
    retailerHeaderContent.getChildren().find((comp: any) => comp.getName() === 'retailer-data');

  if (!retailerComponent) {
    return null;
  }

  const {
    documents = [],
    retailerPath,
    featuredImage,
    showFeatureModels,
  } = retailerComponent.getModels();
  const ref = documents[0];
  const retailerContent: Content | undefined = ref && page.getContent(ref);

  if (!retailerContent) {
    return null;
  }
  const retailerData = retailerContent.getData<RetailerData>();
  if (!retailerData) {
    return null;
  }
  const retailerBrands = retailerData?.series?.map((series: any) => {
    const { brand, brandUid } = page?.getContent(series)?.getData();
    if (!brand && !brandUid) return;
    return brand ?? brandUid;
  });
  retailerData.retailerPath = retailerPath;
  retailerData.featuredPhotos = featuredImage;
  retailerData.brands = retailerBrands;
  retailerData.showFeatureModels = showFeatureModels;
  return retailerData;
}

export function getYoutubeId(item: any) {
  let videoId;
  if (item.videoURL.includes('v=')) {
    const youtubeArrayValues = item.videoURL.split('v=');
    // eslint-disable-next-line prefer-destructuring
    videoId = youtubeArrayValues[1].split('?')[0];
  } else {
    const youtubeArrayValues = item.videoURL.split('/');
    videoId = youtubeArrayValues[youtubeArrayValues.length - 1];
  }
  return videoId;
}
export function getYoutubeThumbImage(item: any, size = 'hq') {
  // valid size:  'hq', 'mq', 'sd', 'maxres'
  const videoId = getYoutubeId(item);
  const imageQal = !size || size.length === 0 ? 'hqdefault.jpg' : `${size}default.jpg`;
  return `https://img.youtube.com/vi/${videoId}/${imageQal}`;
}

export function displayYoutubeVideo(item: any, size = 'hq') {
  // youtube example
  // https://www.youtube.com/watch?v=jJYpcaSMXEs
  return <img alt={item.imageAltText} src={getYoutubeThumbImage(item, size)} />;
}

export function getMatterportThumbImage(item: any, size = 0) {
  const matterportArrayValues = item.matterportURL.split('/');
  const matterportID = matterportArrayValues[matterportArrayValues.length - 1]
    .replace('?m=', '')
    .replace(/&\S*/, '')
    .trim();
  return size > 0
    ? `https://my.matterport.com/api/v1/player/models/${matterportID}/thumb?width=${size}`
    : `https://my.matterport.com/api/v1/player/models/${matterportID}/thumb`;
}

export function display3DTour(item: any) {
  const imageSrc = item.matterportURL.includes('matterport')
    ? getMatterportThumbImage(item)
    : 'https://cdn1.cavco.com/public/cavcoweb/images/bloomreach/panatour.jpg';
  return <img alt={item.imageAltText} src={imageSrc} />;
}

export function getBunnyNetVideoSource(videoId: string) {
  return `${BUNNY_NET_PULL_ZONE}${videoId}/original`;
}

export function getGoogleMapScriptByKeywordAndElement(keywords: [string], element: string) {
  // console.log(
  //   `>>> START getGoogleMapScriptByKeywordAndElement - into element: ${element} with keywords: ${keywords.toString()}`
  // );
  // let keywords = ['maps.googleapis'];

  let allGoogleMapsScripts: any;
  if (element === 'body') {
    allGoogleMapsScripts = document.body.getElementsByTagName('script');
  } else {
    allGoogleMapsScripts = document.head.getElementsByTagName('script');
  }
  // console.log('MapWithMarkers - existsAnyGoogleMapScript - allGoogleMapsScripts: ', allGoogleMapsScripts);

  for (let i = allGoogleMapsScripts.length - 1; i >= 0; i -= 1) {
    const scriptSource = allGoogleMapsScripts[i].getAttribute('src');

    if (scriptSource) {
      if (keywords.filter((item) => scriptSource!.includes(item)).length) {
        // console.log(
        //   '>>> Into getGoogleMapScriptByKeywordAndElement - found - scriptSource: ',
        //   scriptSource
        // );
        return scriptSource;
        // return true;
      }
    }
  }

  return null;
}

export function removeGoogleMapScriptFromHeadByKeywords(
  methodOrigin: string,
  keywords: [string] = ['maps.googleapis']
) {
  // console.log(`*** ${methodOrigin} - Into removeGoogleMapScriptFromHeadByKeywords function`);
  // let keywords = ['maps.googleapis'];

  // Remove google from BOM (window object)
  // window.google = undefined;

  // Remove google map scripts from DOM
  const scripts = document.head.getElementsByTagName('script');

  // console.log(`${methodOrigin} - removeGoogleMapScriptFromHeadByKeywords - scripts: ${scripts}`);

  let numberScriptRemoved = 0;
  for (let i = scripts.length - 1; i >= 0; i -= 1) {
    const scriptSource = scripts[i].getAttribute('src');
    if (scriptSource) {
      if (keywords.filter((item) => scriptSource!.includes(item)).length) {
        scripts[i].remove();
        numberScriptRemoved += 1;
        // scripts[i].parentNode.removeChild(scripts[i]);
      }
    }
  }

  // console.log(
  //   `${methodOrigin} - removeGoogleMapScriptFromHeadByKeywords - numberScriptRemoved: ${numberScriptRemoved}`
  // );
  return numberScriptRemoved > 0;
}

export function removeGoogleMapScriptFromBodyByKeywords(
  methodOrigin: string,
  keywords: [string] = ['maps.googleapis']
) {
  // console.log(`*** ${methodOrigin} - Into removeGoogleMapScriptFromBody function`);
  // let keywords = ['maps.googleapis'];

  // Remove google from BOM (window object)
  // window.google = undefined;

  // Remove google map scripts from DOM
  const scripts = document.body.getElementsByTagName('script');

  // console.log(`${methodOrigin} - removeGoogleMapScriptFromBody - scripts: ${scripts}`);

  let numberScriptRemoved = 0;
  for (let i = scripts.length - 1; i >= 0; i -= 1) {
    const scriptSource = scripts[i].getAttribute('src');
    if (scriptSource) {
      if (keywords.filter((item) => scriptSource!.includes(item)).length) {
        scripts[i].remove();
        numberScriptRemoved += 1;
        // scripts[i].parentNode.removeChild(scripts[i]);
      }
    }
  }

  // console.log(
  //   `${methodOrigin} - removeGoogleMapScriptFromBody - numberScriptRemoved: ${numberScriptRemoved}`
  // );
  return numberScriptRemoved > 0;
}

export function addGoogleMapScriptIntoHead(key: string) {
  // console.log(`*** ${methodOrigin} - Into addGoogleMapScriptIntoHead function`);

  // Script URL added into Head for Map
  // https://maps.googleapis.com/maps/api/js?callback=_$_google_map_initialize_$_&key=AIzaSyDgc5xvbOImZpF2aG7YjNwpXN0kPvFgitw

  // Script URL added into Body for Autocomplete
  // https://maps.googleapis.com/maps/api/js?key=AIzaSyDgc5xvbOImZpF2aG7YjNwpXN0kPvFgitw&libraries=places

  const dynamicScripts = [`https://maps.googleapis.com/maps/api/js?key=${checkForNessus(key)}`];
  for (let i = 0; i < dynamicScripts.length; i += 1) {
    const node = document.createElement('script');
    node.type = 'text/javascript';
    node.src = dynamicScripts[i];
    // node.onload = uponFinishLoadingScript; //probably to initialise your map or something
    document.head.appendChild(node);
  }
}

export function addGoogleMapScriptIntoBody(key: string) {
  // console.log(`*** ${methodOrigin} - Into addGoogleMapScriptIntoBody function`);

  // Script URL added into Head for Map
  // https://maps.googleapis.com/maps/api/js?callback=_$_google_map_initialize_$_&key=AIzaSyDgc5xvbOImZpF2aG7YjNwpXN0kPvFgitw

  // Script URL added into Body for Autocomplete
  // https://maps.googleapis.com/maps/api/js?key=AIzaSyDgc5xvbOImZpF2aG7YjNwpXN0kPvFgitw&libraries=places

  const dynamicScripts = [
    `https://maps.googleapis.com/maps/api/js?key=${checkForNessus(key)}&libraries=places`,
  ];
  for (let i = 0; i < dynamicScripts.length; i += 1) {
    const node = document.createElement('script');
    node.src = dynamicScripts[i];
    node.type = 'text/javascript';
    node.async = true;
    node.defer = true;
    // node.onload = uponFinishLoadingScript; //probably to initialise your map or something
    document.body.appendChild(node);
  }
}

export function removeAndAddGoogleMapScript(
  key: string,
  methodOrigin: string,
  addIntoBody = false
) {
  // console.log(`*** ${methodOrigin} - Into removeAndAddGoogleMapScript function`);

  if (!addIntoBody) {
    if (removeGoogleMapScriptFromHeadByKeywords(methodOrigin)) {
      addGoogleMapScriptIntoHead(key);
    }
  } else if (removeGoogleMapScriptFromBodyByKeywords(methodOrigin)) {
    addGoogleMapScriptIntoBody(key);
  }
}

export function compareValues(key: string, order = 'asc') {
  return function innerSort(a: any, b: any) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
}

export function formatPhoneNumber(number: string | undefined = '') {
  let phone = number;

  if (number.length === 10) {
    phone = `${number.substr(0, 3)}-${number.substr(3, 3)}-${number.substr(6, 4)}`;
  }

  return phone;
}

export function getOperatingSystem() {
  let operatingSystem = 'Not known';
  if (window.navigator.appVersion.indexOf('Win') !== -1) {
    operatingSystem = OPERATIVE_SYSTEM.WINDOWS_OS;
  }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) {
    operatingSystem = OPERATIVE_SYSTEM.MAC_OS;
  }
  if (window.navigator.appVersion.indexOf('X11') !== -1) {
    operatingSystem = OPERATIVE_SYSTEM.UNIX_OS;
  }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) {
    operatingSystem = OPERATIVE_SYSTEM.LINUX_OS;
  }

  return operatingSystem;
}

export function getBrowser() {
  let currentBrowser = 'Not known';
  if (window.navigator.userAgent.indexOf('Chrome') !== -1) {
    currentBrowser = 'Google Chrome';
  } else if (window.navigator.userAgent.indexOf('Firefox') !== -1) {
    currentBrowser = 'Mozilla Firefox';
  } else if (window.navigator.userAgent.indexOf('MSIE') !== -1) {
    currentBrowser = 'Internet Exployer';
  } else if (window.navigator.userAgent.indexOf('Edge') !== -1) {
    currentBrowser = 'Edge';
  } else if (window.navigator.userAgent.indexOf('Safari') !== -1) {
    currentBrowser = 'Safari';
  } else if (window.navigator.userAgent.indexOf('Opera') !== -1) {
    currentBrowser = 'Opera';
  } else {
    currentBrowser = 'Not known';
  }

  return currentBrowser;
}

export function isRunningOnProd(envIsProd = false) {
  let isProd = envIsProd;
  if (isProd) {
    return isProd;
  }

  const enabledDomains = process.env.REACT_APP_PROD_DOMAINS;
  if (enabledDomains) {
    const domains = enabledDomains.split(',');
    for (let i = 0; i < domains.length; i += 1) {
      if (window.location.origin.includes(domains[i])) {
        isProd = true;
        break;
      }
    }
  }
  return isProd;
}

export function isFrontendRunningLocally() {
  return window.location.origin === ENVIRONMENT.LOCAL;
}

export function isBackendRunningLocally() {
  return process.env.REACT_APP_CMS_BASE_URL === 'http://localhost:8080/site';
}

export function getCurrentEnvironment() {
  return window.location.origin;
}

export function getCurrentBloomreachChannelName() {
  let channel = document.getElementsByTagName('html')[0].className || '';

  // the className might not have been initialized
  if (!channel) {
    const sites = process.env.REACT_APP_SITE_NAMES || '';
    const locationHref = window.location.href;
    if (sites) {
      let site = '';
      if (locationHref.includes('www.cavco.com')) {
        site = 'cavcodotcom';
      } else {
        sites.split(',').forEach((d) => {
          if (locationHref.includes(d)) {
            site = d;
          }
        });
      }
      if (site) {
        channel = site;
      } else if (
        process.env.REACT_APP_BLOOMREACH_SITE &&
        locationHref.includes(process.env.REACT_APP_BLOOMREACH_SITE)
      ) {
        channel = 'fairmont';
      }
    } else {
      let sitePath = isFrontendRunningLocally() ? process.env.REACT_APP_CMS_BASE_URL : locationHref;
      sitePath = sitePath || '';
      if (sitePath.includes(BLOOMREACH_CHANNEL_NAMES.FAIRMONT_HOMES)) {
        channel = BLOOMREACH_CHANNEL_NAMES.FAIRMONT_HOMES;
      } else if (sitePath.includes(BLOOMREACH_CHANNEL_NAMES.FRIENDSHIP_HOMES_MN)) {
        channel = BLOOMREACH_CHANNEL_NAMES.FRIENDSHIP_HOMES_MN;
      } else if (sitePath.includes(BLOOMREACH_CHANNEL_NAMES.FLEETWOOD_HOMES)) {
        channel = BLOOMREACH_CHANNEL_NAMES.FLEETWOOD_HOMES;
      } else if (sitePath.includes(BLOOMREACH_CHANNEL_NAMES.CAVCO_DOT_COM)) {
        channel = BLOOMREACH_CHANNEL_NAMES.CAVCO_DOT_COM;
      } else if (sitePath.includes(BLOOMREACH_CHANNEL_NAMES.CAVCO_HOMES)) {
        channel = BLOOMREACH_CHANNEL_NAMES.CAVCO_HOMES;
      } else if (sitePath.includes(BLOOMREACH_CHANNEL_NAMES.PALM_HARBOR)) {
        channel = BLOOMREACH_CHANNEL_NAMES.PALM_HARBOR;
      } else if (sitePath.includes(BLOOMREACH_CHANNEL_NAMES.CHARIOTEAGLE)) {
        channel = BLOOMREACH_CHANNEL_NAMES.CHARIOTEAGLE;
      } else {
        channel = 'fairmont';
      }
    }
  }
  return channel;
}

export function getCurrentSiteName() {
  switch (getCurrentBloomreachChannelName()) {
    case BLOOMREACH_CHANNEL_NAMES.FAIRMONT_HOMES:
      return SITE_NAMES.FAIRMONT_HOMES;
    case BLOOMREACH_CHANNEL_NAMES.FRIENDSHIP_HOMES_MN:
      return SITE_NAMES.FRIENDSHIP_HOMES_MN;
    case BLOOMREACH_CHANNEL_NAMES.FLEETWOOD_HOMES:
      return SITE_NAMES.FLEETWOOD_HOMES;
    case BLOOMREACH_CHANNEL_NAMES.CAVCO_HOMES:
      return SITE_NAMES.CAVCO_HOMES;
    case BLOOMREACH_CHANNEL_NAMES.CAVCO_DOT_COM:
      return SITE_NAMES.CAVCO_DOT_COM;
    case BLOOMREACH_CHANNEL_NAMES.PALM_HARBOR:
      return SITE_NAMES.PALM_HARBOR;
    case BLOOMREACH_CHANNEL_NAMES.CHARIOTEAGLE:
      return SITE_NAMES.CHARIOTEAGLE;
    case BLOOMREACH_CHANNEL_NAMES.SOLITAIRE_HOMES:
      return SITE_NAMES.SOLITAIRE_HOMES;
    case BLOOMREACH_CHANNEL_NAMES.CAVCO_COMMUNITIES:
      return SITE_NAMES.CAVCO_COMMUNITIES;
    default:
      return '';
  }
}

export function getCurrentSiteWithoutHomes() {
  switch (getCurrentBloomreachChannelName()) {
    case BLOOMREACH_CHANNEL_NAMES.FAIRMONT_HOMES:
      return 'Fairmont';
    case BLOOMREACH_CHANNEL_NAMES.FRIENDSHIP_HOMES_MN:
      return 'Friendship';
    case BLOOMREACH_CHANNEL_NAMES.FLEETWOOD_HOMES:
      return 'Fleetwood';
    case BLOOMREACH_CHANNEL_NAMES.CAVCO_HOMES:
      return 'Cavcohomes';
    case BLOOMREACH_CHANNEL_NAMES.CAVCO_DOT_COM:
      return 'Cavcodotcom';
    case BLOOMREACH_CHANNEL_NAMES.PALM_HARBOR:
      return 'Palmharbor';
    case BLOOMREACH_CHANNEL_NAMES.CHARIOTEAGLE:
      return 'Charioteagle';
    default:
      return '';
  }
}

export function getCurrentSiteNameDisplay() {
  const displayName = getCurrentSiteName();
  return displayName.charAt(0).toUpperCase() + displayName.slice(1);
}

export function getBasePath(envIsProd = false) {
  let basePath = '/';
  if (!isFrontendRunningLocally() && !isRunningOnProd(envIsProd)) {
    const siteName =
      getCurrentSiteName() === SITE_NAMES.FAIRMONT_HOMES ? '' : `${getCurrentSiteName()}/`;
    basePath = `/site/${siteName}`;
  }
  return basePath;
}

export function getUrlWithBasePath(partialUrl: string) {
  let basePath = getBasePath();
  if (!partialUrl) {
    return basePath;
  }
  basePath += partialUrl;
  return basePath.replace(/\/\//g, '/');
}

export function getBasePathFromUrl(upToString: string) {
  const customHistory = createBrowserHistory();
  const pathName = customHistory.location.pathname.toLowerCase();
  return pathName.substring(0, pathName.indexOf(upToString.toLowerCase()));
}

export function getBuildingMethodDisplayValue(buildingMethodKey: string) {
  switch (buildingMethodKey) {
    case BUILDING_METHOD_KEY.MANUFACTURED:
      return BUILDING_METHOD_DISPLAY_VALUE.MANUFACTURED;
    case BUILDING_METHOD_KEY.MODULAR:
      return BUILDING_METHOD_DISPLAY_VALUE.MODULAR;
    case BUILDING_METHOD_KEY.PARK_MODEL:
      return BUILDING_METHOD_DISPLAY_VALUE.PARK_MODEL;
    default:
      return '';
  }
}

export function removeBasePath(path: string) {
  const siteName = getCurrentSiteName();
  const index: number = siteName ? path.indexOf(siteName) + siteName.length : -1;
  const url = path.substring(index);
  return url || path;
}

export function currentPathIsRetailerHomePage(page: any) {
  const retailerData = getRetailerData(page);
  const basePath = getBasePath();
  const retailerBasePath = basePath + retailerData?.retailerPath?.substring(1);
  const currentPath = window.location.pathname;
  return retailerBasePath === currentPath || `${retailerBasePath}/` === currentPath;
}

export function isRetailerHomePage(page: any) {
  // to enable BEM-109 body design 2, comment/remove "return false && page;" line below and uncomment "return currentPathIsRetailerHomePage(page);"
  // return false && page;
  return currentPathIsRetailerHomePage(page);
}

export function getDisplayHomeDataModel(page: any): any | null {
  const detailContainer = page && page.getComponent('main', 'detailcontainer');

  const dhDataComponent =
    detailContainer &&
    detailContainer.getChildren().find((comp: any) => comp.getName() === 'dh-data');
  const inventoryDataComponent =
    detailContainer &&
    detailContainer.getChildren().find((comp: any) => comp.getName() === 'inventory-data');
  if (!dhDataComponent && !inventoryDataComponent) {
    return null;
  }
  const dataComponent = dhDataComponent || inventoryDataComponent;

  return dataComponent.getModels();
}

export function getBuildingCenterFromHeader(page: any): any | null {
  const bcContainer = page.getComponent('header-content', 'buildingcenter-data');
  const bcDocuments = bcContainer && bcContainer.getModels() && bcContainer.getModels().documents;
  return bcDocuments && page.getContent(bcDocuments[0])?.getData();
}

export function formatBytes(bytes: any, decimals = 1) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function getCountryByGeocode(geocodeResult: any) {
  const address = geocodeResult?.address_components?.find((a: any) => a.types?.includes('country'));
  return address ? address.short_name?.toLowerCase() : '';
}

export function buildResourceBundleToDropdown(data: ResourceBundleData | undefined): Dropdown[] {
  if (!data?.keys || !data?.messages) {
    return [];
  }
  const { keys, messages } = data;
  return keys.map((key, index) => ({
    displayName: messages[index],
    value: key,
  }));
}

export function buildDocumentToDropdown(data: any) {
  if (!data?.items) {
    return [];
  }
  const { items } = data;
  return items.map((i: any) => ({ displayName: i.label, value: i.key }));
}

export function buildStatesDropdown({
  coordinatesResource,
  namesResource,
}: {
  coordinatesResource: StateConfigData;
  namesResource: StateConfigData;
}): Dropdown[] {
  const coordinatesReference: Record<string, string> = coordinatesResource.keys.reduce(
    (memo, resource, index) => ({
      ...memo,
      [resource.toLocaleLowerCase()]: coordinatesResource.messages[index],
    }),
    {}
  );

  const dropDownEntries = namesResource.keys.reduce((memo, resource, index) => {
    const key: string = resource.toLowerCase();
    if (!coordinatesReference[key]) {
      return memo;
    }

    return {
      ...memo,
      [namesResource.messages[index]]: coordinatesReference[key],
    };
  }, {});

  const keys: string[] = Object.values(dropDownEntries);
  const messages: string[] = Object.keys(dropDownEntries);
  return buildResourceBundleToDropdown({ keys, messages });
}

export function buildResourceBundleToObject(data: ResourceBundleData | undefined): {
  [key: string]: string | number;
} {
  const { keys, messages } = data || { keys: [], messages: [] };
  return keys?.reduce((current, value, index) => {
    return { ...current, [value]: messages[index] };
  }, {} as { [key: string]: string | number });
}

export function buildValueListToObject(items: ValueListItems[] | undefined): {
  [key: string]: string | number;
} {
  return (
    items?.reduce((current, value) => {
      return { ...current, [value.key]: value.label };
    }, {} as { [key: string]: string | number }) || {}
  );
}

export function uniqueKey(): string {
  return btoa(Math.random().toString()).substring(0, 12);
}

export function getVariant(variant: string): string {
  if (variant === FOOTER_COMPONENTPARAMETERMAP_VARIANT.PARK_MODEL) {
    return PAGE_VARIANT.PARK_MODEL;
  }
  if (variant === FOOTER_COMPONENTPARAMETERMAP_VARIANT.RETAILER) {
    return PAGE_VARIANT.RETAILER;
  }
  if (variant === FOOTER_COMPONENTPARAMETERMAP_VARIANT.CAVCOHOMES) {
    return PAGE_VARIANT.CAVCOHOMES;
  }

  return PAGE_VARIANT.FAIRMONT;
}

export function getPageContentData<T extends Record<string, any>>(
  document: Reference,
  page: Page
): T | undefined {
  if (!document || !page) {
    return;
  }

  return page.getContent(document)?.getData<T>();
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

export function getDistanceFromLatLonInKm(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

export const oneKmInMiles = 0.621371;

export function getDistanceUnitByCountry(country: string) {
  if (country?.toLowerCase() === 'us') {
    return 'miles';
  }
  if (country?.toLowerCase() === 'ca') {
    return 'km';
  }
  return 'miles';
}

export function getImageUrl(
  mediaAssetItem: MediaAssetCompound,
  imgContent: ReferenceModel | undefined,
  size: keyof ExternalImage
): string | null {
  let imgPath: string | null = '';
  if (imgContent) {
    imgPath =
      imgContent.model[size] && imgContent.model[size]._links.site.href
        ? imgContent.model[size]._links.site.href
        : null;
  } else if (mediaAssetItem.ximage) {
    imgPath =
      mediaAssetItem.ximage[size] && (mediaAssetItem.ximage[size] as ExternalImageItem).url
        ? (mediaAssetItem.ximage[size] as ExternalImageItem).url
        : null;
  }

  return imgPath;
}

export function getFirstImgUrl(page: Page, photos: Reference) {
  const photoAlbum = photos && page.getContent(photos);
  if (!photoAlbum) return null;
  const { mediaAsset } = photoAlbum.getData<AlbumData>();
  const imgContent = mediaAsset[0] && page.getContent<ReferenceModel>(mediaAsset[0].image);
  return getImageUrl(mediaAsset[0], imgContent, 'large');
}

export function validateFileCompounds(fileCompounds: any) {
  for (let i = 0; fileCompounds && i < fileCompounds.length; i += 1) {
    const fileCompound = fileCompounds[i];
    const { fileName, uploadFileName } = fileCompound;
    if (fileName && fileName.length > 0 && uploadFileName && uploadFileName.length > 0) {
      return true;
    }
  }
  return false;
}

export function getMyAccountPath(path: string) {
  if (!isFrontendRunningLocally()) {
    return path;
  }

  const MY_ACCOUNT = 'my-account';
  const CAVCO_USER_ID = 'cavcouserid';
  const userId = getCookie(CAVCO_USER_ID);
  const addCavcoUserId = `${CAVCO_USER_ID}=${userId}`;

  if (path === MY_ACCOUNT) {
    return `${path}?${addCavcoUserId}`;
  }

  return `${path}&${addCavcoUserId}`;
}
interface RetailerHours {
  day: string;
  hours: string;
}

const formatOpenHours = (arr: any[]) => {
  const formattedHours: any[] = [];

  arr.forEach((a) => {
    if (a.length >= 2) {
      const from = a[0].day;
      const till = a[a.length - 1].day;
      const newDates = `${from} - ${till}`;
      const newHours = a[0].hours;
      formattedHours.push({ day: newDates, hours: newHours });
    } else {
      const { day } = a[0];
      const hour = a[0].hours;
      formattedHours.push({ day, hours: hour });
    }
  });
  return formattedHours;
};

export const condenseRetailerHours = (retailerOpenHours: RetailerHours[]) => {
  const falseArray: any[] = [];
  const startingArr = retailerOpenHours;
  let startingIdx = 0;
  retailerOpenHours.forEach((date, index) => {
    const over = !!retailerOpenHours[index + 1];
    if (over) {
      if (date.hours !== retailerOpenHours[index + 1].hours) {
        falseArray.push(startingArr.slice(startingIdx, index + 1));
        startingIdx = index + 1;
      }
    } else {
      falseArray.push(startingArr.slice(startingIdx, index + 1));
    }
  });
  return formatOpenHours(falseArray);
};

export function getFavoriteFromLocalStorage(resourceId: string) {
  if (!resourceId) {
    return false;
  }
  const userId = getCookie('cavcouserid');

  if (!userId) {
    return false;
  }

  const favs = localStorage.getItem(`cavcoUserFavorites-${userId}`);

  if (!favs) {
    return false;
  }
  const favorites = JSON.parse(favs);
  let aFav = null;
  favorites.every((fav: any) => {
    if (fav?.resourceId === resourceId) {
      aFav = fav;
      return false;
    }
    return true;
  });
  return aFav;
}

export function getOriginalSubId(resourceId: string) {
  const aFav: any = getFavoriteFromLocalStorage(resourceId);
  if (!aFav) {
    return null;
  }
  return aFav?.subResourceId;
}

export function isFavoriteForUser(resourceId: string) {
  const aFav: any = getFavoriteFromLocalStorage(resourceId);
  if (!aFav) {
    return false;
  }
  return true;
}

async function initFavoritesForCurrentUser(favoriteUrl: string, storageKey = 'cavcoUserFavorites') {
  if (!favoriteUrl) {
    return null;
  }

  const userId = getCookie('cavcouserid');
  if (!userId) {
    return null;
  }

  fetch(favoriteUrl, { credentials: 'include' })
    .then((response) => {
      const rj = response.json();
      return rj;
    })
    .then((data) => {
      if (data?.status === 400) {
        console.log('400!! responseData:' + data); // eslint-disable-line
      } else {
        localStorage.setItem(`${storageKey}-${userId}`, JSON.stringify(data));
        // setCavcoUserFavorites(data);
      }
    })
    .catch((error) => {
      console.log('error from the server:', error.message); // eslint-disable-line
    });
}

export async function initAllFavoritesForCurrentUser(favoriteUrl: string) {
  initFavoritesForCurrentUser(favoriteUrl, 'cavcoUserFavorites');
  // initFavoritesForCurrentUser(favoriteUrl, 'cavcoMyFavorites')
}

export function initMyFavoritesForCurrentUser(favoriteUrl: string) {
  initFavoritesForCurrentUser(favoriteUrl, 'cavcoMyFavorites');
}

export function getMyFavoritesForCurrentUser() {
  const userId = getCookie('cavcouserid');
  if (!userId) {
    return false;
  }
  const favs = localStorage.getItem(`cavcoMyFavorites-${userId}`);
  if (!favs) {
    return false;
  }
  const favorites = JSON.parse(favs);
  return favorites;
}

export function getCavcoUserInfo() {
  const userInfo = localStorage.getItem('cavcoUserInfo');
  if (!userInfo) {
    return false;
  }
  const cavcoUserInfo = JSON.parse(userInfo);
  return cavcoUserInfo;
}

export const getSeriesDecorImages = (props: BrProps, seriesDoc: any) => {
  const decorAlbumRef = seriesDoc?.getData()?.decorPhotoAlbum;
  const decorAlbum = decorAlbumRef ? props.page.getContent(decorAlbumRef) : undefined;
  let albumImages: any;

  if (decorAlbum) {
    const { mediaAsset } = decorAlbum?.getData<AlbumData>();
    albumImages = mediaAsset;
  }
  return albumImages;
};

export const findRetailerBasepath = (page: Page) => {
  const detailContainer = page && page.getComponent('main', 'detailcontainer');

  const dataComponent =
    detailContainer &&
    detailContainer.getChildren().find((comp: any) => comp.getName() === 'retailer-data');
  const dataRef = dataComponent?.getModels()?.documents[0];
  const retailerData = page?.getContent(dataRef)?.getData();
  return retailerData?._links.site?.href;
};

export const isRetailerDetailPage = (page: Page) => {
  const fpDetail = page && page?.getComponent('main', 'detailcontainer');
  const fpValidator =
    fpDetail && fpDetail.getChildren().find((comp: any) => comp.getName() === 'fp-validator');
  return fpValidator;
};

export const isRetailerPageNotDetail = (isRetailerPage: boolean) => {
  if (!isRetailerPage) return;
  const searchTypes: string[] = ['move-in-ready-homes', 'floorplans', 'display-homes', 'builder'];
  const urlNoParam = window.location.href.includes('?')
    ? window.location.href.split('?')[0].replace(/#.*\/?/, '')
    : window.location.href;
  const lastSection = urlNoParam?.split('/')?.pop();
  return lastSection && searchTypes.includes(lastSection);
};

export const findBuildingCenterFromPage = (page: Page) => {
  const detailContainer: any = page.getComponent('main', 'detailcontainer');
  const seriesComponent = detailContainer?.model?.components?.filter(
    (comp: any) => comp.label === 'Cavco Detail Files' || comp.label === 'Floor Plan Files'
  );
  if (!seriesComponent || seriesComponent.length < 1) return;
  const { models } = seriesComponent && seriesComponent[0];
  if (!models?.series) return;
  const series = page?.getContent(models?.series[0]);
  const buildingCenter = page.getContent(series?.getData().buildingCenter);
  return buildingCenter?.getData();
};

export const isSinglePlantWebsite = () => {
  switch (getCurrentBloomreachChannelName()) {
    case BLOOMREACH_CHANNEL_NAMES.FAIRMONT_HOMES:
    case BLOOMREACH_CHANNEL_NAMES.FRIENDSHIP_HOMES_MN:
    case BLOOMREACH_CHANNEL_NAMES.CHARIOTEAGLE:
    case BLOOMREACH_CHANNEL_NAMES.CAVCO_COMMUNITIES:
      return true;
    default:
      return false;
  }
};

export const getTileGridDocumentsFromComponent = (page: Page) => {
  const detailContainer: any = page.getComponent('main', 'detailcontainer');
  const tileGridDocumentData = detailContainer?.model?.components?.filter(
    (comp: any) => comp.label === 'Tile Grid Document Data'
  );
  if (!tileGridDocumentData || tileGridDocumentData.length < 1) return;
  const { models } = tileGridDocumentData && tileGridDocumentData[0];
  return models;
};
