import React from 'react';
import { Image } from 'react-bootstrap';
import { BrProps } from '@bloomreach/react-sdk';
import './CavcoInteractiveBuilderPromo.scss';

export function CavcoInteractiveBuilderPromo(props: BrProps) {
  const { document: documentRef } = props.component.getModels();
  const document: any = documentRef && props.page.getContent(documentRef);
  const pageComponents = props.page.getComponent('main', 'detailcontainer');
  const builderCheck = pageComponents?.getComponent('builder-check')?.getModels();
  const showBuilder = builderCheck?.showInteractiveBuilder;

  if (!document) return <></>;
  if (!showBuilder) return <></>;

  const { title, link, content1, image } = document?.model;
  const bannerImage = image && props.page.getContent(image.imagelink);
  const { displayText, destinationUrl } = link;
  const builderUrl = `${window.location.pathname}${destinationUrl}`;

  return (
    <div className="cvc-interactive-builder-promo promo-container">
      {content1?.value && (
        <div className="promo-container__left">
          <div className="image-block">
            {bannerImage && <Image src={bannerImage?.getUrl()} alt={image?.alt} />}
            {content1 && <div dangerouslySetInnerHTML={{ __html: content1.value }} />}
          </div>
        </div>
      )}
      <div className="promo-container__right">
        <h3>{title}</h3>
        <a className="btn btn-cta banner-link" href={builderUrl}>
          <span>{displayText}</span>
        </a>
      </div>
    </div>
  );
}
