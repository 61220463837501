import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import './CavcoDetailBanner.scss';
import { Link } from 'react-scroll';
import { initAllFavoritesForCurrentUser, isFavoriteForUser } from 'components/utils';
import {
  InventoryCavcoDetailImage,
  InventoryCavcoDetailImageGrid,
} from './InventoryCavcoDetailImage';
import { CavcoDetailImage, CavcoDetailImageGrid } from './CavcoDetailImage';
import { findBuildingCenterFromPage, getRetailerData, currencyFormatter } from '../utils';
import { inventoryToFloorplanMapper } from './inventoryToFloorplan.mapper';
import { ReactComponent as ExportIcon } from '../../assets/images/cavcohomes/export-black.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/cavcohomes/location-icon.svg';
import { ReactComponent as PrintIcon } from '../../assets/images/print-icon.svg';
import { SharePage } from '../SharePage/SharePage';
import { getCookie } from '../hooks/cookie';
import { FavoriteIcon } from '../shared/Icons/FavoriteIcon';

const useStickBanner = (retailerBanner: boolean) => {
  const [stick, setStick] = useState(false);
  const offset = retailerBanner ? 240 : 72;
  const handleScroll = () => {
    setStick(window.scrollY > offset);
  };
  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  return stick;
};

export function CavcoDetailBanner(props: BrProps) {
  const {
    documents,
    retailerFloorPlanBannerConfig,
    retailerDisplayHomeBannerConfig,
    detailPageBannerLabels,
    inStock,
    myAccountConfig,
    plantLocationUid,
  } = props.component.getModels();
  const [isFavorite, setIsFavorite] = useState(false);
  const [fpAssetId, setFpAssetId] = useState<any>(null);
  const config = retailerFloorPlanBannerConfig ?? retailerDisplayHomeBannerConfig;
  const inventoryDocument = inventoryToFloorplanMapper(props);

  const availabilityStatus =
    inventoryDocument && inventoryDocument.availableStatusValue.toUpperCase();
  const document = documents && documents[0] ? props.page.getContent(documents[0]) : undefined;

  const userId = getCookie('cavcouserid');
  const isRetailerPage = !!props.page.getComponent('retailer-header-content');
  const pageComponents = props.page.getComponent('main', 'detailcontainer');
  const isDisplayHomePage = !!pageComponents?.getComponent('display-home-banner');
  const isMIR = !!pageComponents?.getComponent('mir-banner');
  const sticky = useStickBanner(isRetailerPage);
  const headerClass = `${sticky ? 'sticky' : ''}`;

  const isInventory = isMIR || isDisplayHomePage;

  const cavcoImageGrid = isInventory
    ? InventoryCavcoDetailImageGrid(props)
    : CavcoDetailImageGrid(props);
  const cavcoImage = isInventory ? InventoryCavcoDetailImage(props) : CavcoDetailImage(props);

  const {
    modelName,
    modelNumber,
    numBathrooms,
    numBedrooms,
    squareFootage,
    startingPrice,
    price,
    wasPrice,
    nowPrice,
    invCLNowPrice,
    invCLWasPrice,
    inventoryClearance,
    cityWhereLocated,
    state,
    zip,
    squareFeet,
    bedRoomsValue,
    bathRoomsValue,
    _links,
    lotSize,
    brandUid,
  } = document ? document.getData<BannerData>() : inventoryDocument || {};
  const useNowPrice = nowPrice ?? invCLNowPrice;
  const useWasPrice = wasPrice ?? invCLWasPrice;
  const isClearance = inventoryClearance === true;
  const bedrooms = numBedrooms || bedRoomsValue;
  const bathrooms = numBathrooms || bathRoomsValue;
  const sqFeet = squareFootage || squareFeet;
  const asterisk = '**';
  const displayStartingPrice = `${detailPageBannerLabels?.startingPrice?.replace(
    '{price}',
    currencyFormatter.format(startingPrice)
  )}${asterisk}`;

  const findBuilder = () => {
    const buildingCenterData = findBuildingCenterFromPage(props?.page);
    const mapString = 'administration/value-list/builders';
    const inventoryData = pageComponents?.getComponent('inventory-data');
    if (!inventoryData) return buildingCenterData?.dba;
    const builderMapping = inventoryData?.getModels()?.[mapString];
    return builderMapping[brandUid];
  };

  const detailType =
    (isMIR && 'MOVE-IN READY') || (isDisplayHomePage && 'DISPLAY HOME') || 'FLOOR PLAN';
  let locationID: any = null;
  let subIdType = '';
  let fpUrl = '';
  let assetPath = 'our_homes';

  if (isMIR || isDisplayHomePage) {
    assetPath = isDisplayHomePage ? 'display_homes' : 'move_in_ready_homes';
  }

  if (_links) {
    fpUrl = _links.site.href;
  }

  const retailerData = getRetailerData(props.page);

  if (isRetailerPage) {
    if (retailerData && retailerData.locationID) {
      locationID = retailerData?.locationID;
      subIdType = 'subIdTypeRetailer';
    }
    fpUrl = window.location.pathname;
  } else if (plantLocationUid) {
    locationID = plantLocationUid;
    subIdType = 'subIdTypeBuildingCenter';
  }

  let pdfLink: any = null;

  useEffect(() => {
    if (document || inventoryDocument) {
      const fpData: FloorPlanData = document ? document.getData<BannerData>() : inventoryDocument;
      setFpAssetId(fpData.assetId);
      setIsFavorite(isFavoriteForUser(fpData.assetId));
    }
  }, [document, inventoryDocument]);

  if (fpAssetId) {
    pdfLink = `${config.pdfLinkUrl + fpAssetId}/`;
    if (locationID && (!inventoryDocument || inventoryDocument?.homeTypeValue !== 'Stock')) {
      pdfLink = `${pdfLink + locationID}/`;
    }
    const currentLocation = window.location.href.split('#')[0].split('?')[0];
    pdfLink += `?${config.query}=${currentLocation}`;
  }

  const favoriteUrl = useMemo(
    () =>
      `${
        myAccountConfig.favoriteBaseUrl
      }/${assetPath}/${fpAssetId}/${!isFavorite}?&subIdType=${subIdType}${
        locationID ? `&subId=${locationID}` : ''
      }&websiteUrl=${fpUrl}`,
    [
      assetPath,
      fpAssetId,
      fpUrl,
      isFavorite,
      locationID,
      myAccountConfig.favoriteBaseUrl,
      subIdType,
    ]
  );

  const downloadPDF = () => {
    if (pdfLink) {
      window.location.href = pdfLink;
    }
  };

  const titlePluralizer = (val: string | number, desc: string) =>
    val === '1' || val === 1 ? desc : `${desc}s`;

  const detail = (type: string, value: string, metaDesc: string) => {
    return (
      <p className={`floorplan-info${value}`} data-elastic-name={metaDesc}>
        {value === 'sqft' || value === ''
          ? `${type} ${value}`
          : `${type} ${titlePluralizer(type, value)}`}
      </p>
    );
  };

  const address = (cityValue: string, stateValue: string, zipValue: string, mobile: boolean) => (
    <p className={`banner-location ${mobile ? 'mobile' : ''}`}>
      <LocationIcon fill="white" />{' '}
      <span>
        {cityValue} {stateValue}, {zipValue}
      </span>
    </p>
  );

  const loadFavoritesList = useCallback(async (loadFavoriteUrl: string) => {
    await initAllFavoritesForCurrentUser(loadFavoriteUrl);
  }, []);

  useEffect(() => {
    if (myAccountConfig?.cavcoFavoriteUrl && userId)
      loadFavoritesList(`${myAccountConfig.cavcoFavoriteUrl}`);
  }, [loadFavoritesList, myAccountConfig, userId]);

  const buildingMethodValue =
    document && document.getData() && document.getData().buildingMethodValue
      ? document.getData().buildingMethodValue
      : inventoryDocument?.buildingMethodValue;

  const parkFind: boolean = buildingMethodValue
    ? buildingMethodValue.toString().toLowerCase().includes('park')
    : false;
  const buildingMethod = parkFind ? buildingMethodValue : `${buildingMethodValue} Home`;

  let mirClassName = {};
  if (isMIR) {
    mirClassName = 'mir-result';
  } else {
    mirClassName = '';
  }

  let featureModel1Price;
  let featureModel2Price;
  let featureModel1;
  let featureModel2;
  let featureModel;
  let showFeatureModelPrice = false;

  if (retailerData) {
    const {
      featureModel1StartingPrice,
      featureModel2StartingPrice,
      featureModel1AssetId,
      featureModel2AssetId,
      showFeatureModels,
    } = retailerData;
    featureModel1Price = currencyFormatter.format(featureModel1StartingPrice);
    featureModel2Price = currencyFormatter.format(featureModel2StartingPrice);
    featureModel1 = featureModel1AssetId === fpAssetId;
    featureModel2 = featureModel2AssetId === fpAssetId;
    featureModel = featureModel1AssetId === fpAssetId || featureModel2AssetId === fpAssetId;
    showFeatureModelPrice = showFeatureModels;
  }

  let floorplanPricePrefix = 'This Floor Plan Starts From';
  if (detailPageBannerLabels) {
    floorplanPricePrefix = detailPageBannerLabels.featureFloorplanPricePrefix;
  }

  // for MIR
  // [Model Name] [Model Number] [Building Method "Manufactured" or "Park Model" or "Modular"] Home for Sale in [City, ST]
  const pageTitle = isMIR
    ? `${modelName} ${modelNumber} ${buildingMethod} for Sale in ${cityWhereLocated}, ${state}`
    : `${modelName}`;
  return (
    <div className={`cavco-panel-container-banner ${mirClassName}`}>
      <div className="cav-floorplanbanner-container">
        <div className={`floorplanbanner-title ${headerClass}`}>
          <div className="title-block">
            <span>{detailType}</span>
            <h1>{pageTitle}</h1>
            <div className={`socialbutton${headerClass}`}>
              <FavoriteIcon
                classes="cavco-favorite-button floorplan-save-favorites-icon"
                type={assetPath}
                subIdType={subIdType}
                isCavcoHomesVariant
                subId={locationID}
                baseUrl={myAccountConfig.favoriteBaseUrl}
                loadFavoriteUrl={myAccountConfig.cavcoFavoriteUrl}
                setFavoriteUrl={favoriteUrl}
                overrideFavorite
                isFavoriteOverride={isFavorite}
                id={fpAssetId}
                myAccountConfig={myAccountConfig}
                callback={(favorite: boolean) => setIsFavorite(favorite)}
              />
              <SharePage
                classes=""
                textClass=""
                // ogImage={cavcoFloorPlanImage}
                UseIcon={ExportIcon}
                isCvh
              />
            </div>
          </div>
          {!isRetailerPage && inStock && (
            <Link to="availability" className="availability cvh-link" smooth offset={-150}>
              {detailPageBannerLabels?.whereToBuy}
            </Link>
          )}
          <Link
            to="contact-info"
            className={`btn btn-cta cvh-floorplan-info ${isRetailerPage && 'retailer'}`}
            smooth
            offset={-150}
          >
            {detailPageBannerLabels?.requestInfo}
          </Link>
        </div>

        {cavcoImage && <div className="floorplanbanner-image-container">{cavcoImage}</div>}

        <div className="floorplanbanner-info">
          <div className="banner-info floorplanbanner-name-container">
            <div className="title-left">
              <h2 className="floorplanbanner-name">
                <span className="floorplanbanner-name" data-elastic-name="model_number">
                  {findBuilder()} {modelNumber}
                </span>
              </h2>
              {inStock && <button type="button">{detailPageBannerLabels?.inStock}</button>}
              {availabilityStatus && <button type="button">{availabilityStatus}</button>}
            </div>

            {!isRetailerPage && (
              <Link to="availability" className="availability cvh-link" offset={-150} smooth>
                {detailPageBannerLabels?.whereToBuy}
              </Link>
            )}
          </div>
          {price && !isClearance && availabilityStatus !== 'SOLD' && (
            <div className="banner-info mir-price">
              <h4 className="price">
                {Number.isNaN(parseInt(price, 10)) ? price : currencyFormatter.format(price)}
                <span className="price-asterisk">**</span>
              </h4>
            </div>
          )}
          {(nowPrice || invCLNowPrice) && isClearance && (
            <div className="cvc-schema-product" itemScope itemType="https://schema.org/Product">
              <meta itemProp="category" content={detailType} />
              <meta itemProp="name" content={modelName} />
              <meta itemProp="brand" content={findBuilder()} />
              <meta itemProp="model" content={modelNumber} />
              <h4 className="banner-info mir-price price__was">
                <span
                  className="price__now"
                  itemProp="offers"
                  itemScope
                  itemType="https://schema.org/Offer"
                >
                  <meta itemProp="priceCurrency" content="USD" />
                  <meta itemProp="price" content={useNowPrice} />
                  Now {currencyFormatter.format(useNowPrice)}
                  <span className="price-asterisk">**</span>
                </span>
                <span className="price__was">
                  Was {currencyFormatter.format(useWasPrice)}
                  <span className="price-asterisk">**</span>
                </span>
              </h4>
            </div>
          )}
          {startingPrice && !isClearance && (
            <div className="cvc-schema-product" itemScope itemType="https://schema.org/Product">
              <meta itemProp="category" content={detailType} />
              <meta itemProp="name" content={modelName} />
              <meta itemProp="brand" content={findBuilder()} />
              <meta itemProp="model" content={modelNumber} />
              <span
                className="banner-info floorplan-price"
                data-elastic-name="starting_price"
                itemProp="offers"
                itemScope
                itemType="https://schema.org/AggregateOffer"
              >
                <meta itemProp="priceCurrency" content="USD" />
                <meta itemProp="lowPrice" content={startingPrice} />
                {displayStartingPrice}
              </span>
            </div>
          )}
          <div className="cvh-floorplan-banner-details banner-info">
            {startingPrice && availabilityStatus !== 'SOLD' && detail(displayStartingPrice, '', '')}
            {bedrooms && detail(bedrooms, 'bed', 'number_of_bedrooms')}
            {bathrooms && detail(bathrooms, 'bath', 'number_of_bathrooms')}
            {sqFeet && detail(sqFeet.toLocaleString('en-US'), 'sqft', 'square_foot')}
            {lotSize && detail(lotSize, 'acre', 'lotSize')}
            {cityWhereLocated && address(cityWhereLocated, state, zip, false)}
          </div>
          {cityWhereLocated && address(cityWhereLocated, state, zip, true)}

          {!isMIR && !isDisplayHomePage && featureModel && showFeatureModelPrice ? (
            <div className="cvc-fp-feature-model">
              <h2 className="cvc-fp-feature-model-title">
                <span className="cvc-fp-feature-model-title-prefix">{floorplanPricePrefix}</span>
                <strong className="cvc-fp-feature-model-title-amount">
                  {/* eslint-disable-next-line */}
                  {featureModel1 ? featureModel1Price : featureModel2 ? featureModel2Price : null}
                  <span className="price-asterisk">**</span>
                </strong>
              </h2>
            </div>
          ) : null}

          <div className="images-grid">{cavcoImageGrid}</div>
          <div className="bottom-buttons-container">
            <button type="button" className="cvh-link" onClick={downloadPDF}>
              <PrintIcon className="cvh-link-icon" />
              {detailPageBannerLabels?.print}
            </button>
            <div className="social-buttons">
              <FavoriteIcon
                classes="cavco-favorite-button floorplan-save-favorites-icon"
                type={assetPath}
                subIdType={subIdType}
                isCavcoHomesVariant
                subId={locationID}
                baseUrl={myAccountConfig.favoriteBaseUrl}
                loadFavoriteUrl={myAccountConfig.cavcoFavoriteUrl}
                setFavoriteUrl={favoriteUrl}
                overrideFavorite
                isFavoriteOverride={isFavorite}
                callback={(favorite: boolean) => setIsFavorite(favorite)}
                id={fpAssetId}
                myAccountConfig={myAccountConfig}
              />
              {detailPageBannerLabels?.like}
              <SharePage
                classes=""
                textClass=""
                // ogImage={cavcoFloorPlanImage}
                UseIcon={ExportIcon}
                isCvh
              />{' '}
              {detailPageBannerLabels?.share}
            </div>
          </div>
        </div>
        <div className="cvh-floorplanbanner-nav">
          <div className="cvh-floorplan-social-buttons">
            <FavoriteIcon
              classes="cavco-favorite-button floorplan-save-favorites-icon"
              type={assetPath}
              subIdType={subIdType}
              subId={locationID}
              isCavcoHomesVariant
              baseUrl={myAccountConfig.favoriteBaseUrl}
              loadFavoriteUrl={myAccountConfig.cavcoFavoriteUrl}
              setFavoriteUrl={favoriteUrl}
              overrideFavorite
              isFavoriteOverride={isFavorite}
              callback={(favorite: boolean) => setIsFavorite(favorite)}
              id={fpAssetId}
              myAccountConfig={myAccountConfig}
            />
            <SharePage
              classes=""
              textClass=""
              // ogImage={cavcoFloorPlanImage}
              UseIcon={ExportIcon}
              isCvh
            />
          </div>
          <Link to="contact-info" className="btn btn-cta cvh-floorplan-info" offset={-150}>
            {detailPageBannerLabels?.requestInfo}
          </Link>
        </div>
      </div>
    </div>
  );
}
