import React, { useState } from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import { Image } from 'react-bootstrap';
import { Tabs, Tab } from '@material-ui/core';

import './CavcoInteractiveBuilder.scss';

/*
const tabMap = {
  'Friendship Homes': 'Friendship',
  'Fairmont Homes': 'Fairmont',
  'Fairmont Park Model': 'Fairmont Park Model',
};
*/

const generateKey = (pre: string) => {
  return `${pre}_${new Date().getTime()}`;
};

export function CavcoInteractiveBuilder(props: BrProps) {
  const { builders, interactiveBuilder } = props?.component.getModels();
  const { description, title } = interactiveBuilder;
  const [activeTab, setActiveTab] = useState(0);

  if (builders === null) return null;
  const builderRefs = Object.entries(builders);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const TabPanel = (rawDoc: any, val: number) => {
    const { content1, content2, link, title: builderTitle, image } = rawDoc.getData();
    const altText = image?.alt.length > 1 ? image?.alt : builderTitle;
    const handleLinkAction = (actionLink: string) => {
      window.open(actionLink, 'popup', 'height=630, width=1000');
      return false;
    };
    return (
      <div key={`${generateKey('builder-tabpanel')}-${val}`}>
        {(activeTab === val && image?.imagelink) ||
          (content1?.value && (
            <div className="body-builder__image">
              <button type="button" onClick={() => handleLinkAction(link.destinationUrl)}>
                {image?.imagelink && <Image src={image?.imagelink?.getUrl()} alt={altText} />}
                {content1 && <div dangerouslySetInnerHTML={{ __html: content1.value }} />}
                {content2 && (
                  <div
                    className="body-builder__desc"
                    dangerouslySetInnerHTML={{ __html: content2.value }}
                  />
                )}
                {title && (
                  <h4 className="body-builder__title">
                    {builderTitle}
                    {link.displayText && <strong>{link.displayText}</strong>}
                  </h4>
                )}
              </button>
            </div>
          ))}
      </div>
    );
  };

  const BodyBuilder = (refs: any, idx: number) => {
    const documents: any = refs[1]?.map((ref: string) => props.page.getContent(ref));
    return (
      <div
        className="body-builder"
        key={`${generateKey('panel-key')}-${idx}`}
        role="tabpanel"
        hidden={activeTab !== idx}
        id={`builder-tabpanel-${idx}`}
        aria-labelledby={`builder-tab-${idx}`}
      >
        {documents?.map(TabPanel, idx)}
      </div>
    );
  };

  const TabBuilder = (refs: any, idx: number) => {
    const tabTitle = refs[0];
    return (
      <Tab
        // label={tabMap[tabTitle as keyof typeof tabMap] || tabTitle}
        label={tabTitle}
        id={`builder-tab-${idx}`}
        aria-controls={`builder-tabpanel-${idx}`}
        onClick={() => setActiveTab(idx)}
        key={`${generateKey('builder-tab')}-${idx}`}
      />
    );
  };

  return (
    <div className="cvc-interactive-builder">
      <header className="cvc-builder-header">
        <h1>{title}</h1>
        <p className="cvc-builder-header__description">{description}</p>
      </header>
      <main className="cvc-builder-content">
        <div className="cvc-builder-content__builders">
          <div className="cvc-builder-content__detail">
            <Tabs
              className="cvc-builder-content__tabs"
              value={activeTab}
              onChange={() => handleChange}
              aria-label="interactive builder tabs"
              centered
            >
              {builderRefs?.map(TabBuilder)}
            </Tabs>
            {builderRefs?.map(BodyBuilder)}
          </div>
        </div>
      </main>
    </div>
  );
}
