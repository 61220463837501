import React from 'react';
import classNames from 'classnames';
import { BrProps } from '@bloomreach/react-sdk';
import { HEADER_COMPONENTPARAMETERMAP_VARIANT, PAGE_VARIANT } from '../enums';
import './MyAccountHeader.scss';
import SiteGlobalSwitches from '../utils';

let HeaderVariant = '';
declare let window: any;

function displayLogo(image: any, imageAltText: string) {
  if (!image) return '';

  return (
    <img
      className="cvc-my-account-header-logo"
      src={image ? image.getUrl() : ''}
      alt={imageAltText}
    />
  );
}

function getVariant(variant: string): string {
  if (variant === HEADER_COMPONENTPARAMETERMAP_VARIANT.CAVCOHOMES) {
    return PAGE_VARIANT.CAVCOHOMES;
  }
  if (variant === HEADER_COMPONENTPARAMETERMAP_VARIANT.CAVCOHOMES_RETAILER) {
    return PAGE_VARIANT.CAVCOHOMES_RETAILER;
  }
  return PAGE_VARIANT.FAIRMONT;
}

export function MyAccountHeader(props: BrProps) {
  const { componentParameterMap } = props.component.getModels();
  const { header: documentRef, siteGlobalSwitches } = props.component.getModels();
  // console.log('----myAccountConfig : ', myAccountConfig);
  // console.log('----props : ', props);
  HeaderVariant = getVariant(componentParameterMap.variant);
  const document = documentRef ? props.page.getContent(documentRef) : undefined;
  if (!document) {
    return null;
  }

  SiteGlobalSwitches.myAccountSwitchOn = !!(
    siteGlobalSwitches && siteGlobalSwitches.myAccountSwitch.toLowerCase() === 'on'
  );

  SiteGlobalSwitches.showFloorPlanPricing = !!(
    siteGlobalSwitches && siteGlobalSwitches.showFloorPlanPricing.toLowerCase() === 'on'
  );

  SiteGlobalSwitches.showFloorPlanCountry = !!(
    siteGlobalSwitches && siteGlobalSwitches.showFloorPlanCountry.toLowerCase() === 'on'
  );

  const { logoImage } = document.getData<HeaderData>();

  let logoImageUrl = logoImage.destinationUrl;
  logoImageUrl = logoImage && window.spaBaseUrl + logoImageUrl;

  const logoImg: any = logoImage && props.page.getContent(logoImage.image);

  return (
    <div className={classNames('cvc-my-account-header-wrapper', `${HeaderVariant}`)}>
      <header className="cvc-my-account-header">
        <a
          className="cvc-my-account-header-logo"
          href={
            logoImage && logoImageUrl && logoImageUrl.length > 0
              ? logoImageUrl
              : window.location.origin
          }
        >
          {displayLogo(logoImg, logoImage.imageAltText)}
        </a>
      </header>
    </div>
  );
}
