import { BrPageContext, BrProps } from '@bloomreach/react-sdk';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Navbar, Modal, NavDropdown, Button } from 'react-bootstrap';
import { RetailerFavoriteIcon } from 'components/shared/Icons';
import { ResolverLink } from 'components/shared';
import './RetailerHeader2.scss';
import { Drawer, List, ListItem } from '@material-ui/core';
import {
  getCurrentSiteNameDisplay,
  getBasePath,
  getCurrentSiteWithoutHomes,
  getMyAccountPath,
} from 'components/utils';
import { deleteAllCookie, getCookie } from 'components/hooks/cookie';
import { SignInModals } from 'components/MyAccount/SignInModals';
import useWindowDimensions from 'components/hooks/useWindowDimensions';
import classNames from 'classnames';
import { CustomBreadcrumbStackWithoutRequestInfoShare } from 'components/Breadcrumb/BreadcrumbStack';
import { DisplayHomeRequestInfo } from 'components/DisplayHomeRequestInfo/DisplayHomeRequestInfo';
import { LoginStatus, loginSubject$ } from 'components/MyAccount/LoginStatus';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/left-arrow-icon.svg';
import { ReactComponent as NavBarTogglerIcon } from '../../assets/images/nav-icon.svg';
import { ReactComponent as UserMyFavoritesIcon } from '../../assets/images/user-icon.svg';
import { HEADER_CONTENT_TYPE, SCREEN_SIZES } from '../enums';
import { FloorPlanRequestInfoWithParams } from '../FloorPlanRequestInfo/FloorPlanRequestInfo';
import { ReactComponent as LocationIcon } from '../../assets/images/location-icon.svg';
import SiteGlobalSwitches, { getRetailerData } from '../utils';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone-icon.svg';
import { ReactComponent as HoursIcon } from '../../assets/images/clock-icon.svg';
import { Menu } from '../Menu/Menu';

declare let window: any;

function getImage(containerName: string, item: any, imgContent: any) {
  let cavcoThumbnailImgPath = '';
  let mediumImgPath = '';
  let largeImgPath = '';
  let extraLargeImgPath = '';

  if (!imgContent && !item) return '';

  if (imgContent) {
    cavcoThumbnailImgPath =
      imgContent.model.cavcoThumbnail && imgContent.model.cavcoThumbnail._links.site.href
        ? imgContent.model.cavcoThumbnail._links.site.href
        : null;
    mediumImgPath =
      imgContent.model.medium && imgContent.model.medium._links.site.href
        ? imgContent.model.medium._links.site.href
        : null;
    largeImgPath =
      imgContent.model.large && imgContent.model.large._links.site.href
        ? imgContent.model.large._links.site.href
        : null;
    extraLargeImgPath =
      imgContent.model.extraLarge && imgContent.model.extraLarge._links.site.href
        ? imgContent.model.extraLarge._links.site.href
        : null;
  } else if (item.hasOwnProperty('ximage')) {
    if (item.ximage) {
      cavcoThumbnailImgPath =
        item.ximage.cavcoThumbnail && item.ximage.cavcoThumbnail.url
          ? item.ximage.cavcoThumbnail.url
          : null;
      mediumImgPath = item.ximage.medium && item.ximage.medium.url ? item.ximage.medium.url : null;
      largeImgPath = item.ximage.large && item.ximage.large.url ? item.ximage.large.url : null;
      extraLargeImgPath =
        item.ximage.extraLarge && item.ximage.extraLarge.url ? item.ximage.extraLarge.url : null;
    }
  }

  return (
    <picture key={`${containerName}-${item.id}`}>
      <source media={`(min-width:${SCREEN_SIZES.DESKTOP_BIG}px)`} srcSet={extraLargeImgPath} />
      <source
        media={`(min-width:${SCREEN_SIZES.DESKTOP}px) and (max-width:${SCREEN_SIZES.DESKTOP_MAX_WIDTH}px)`}
        srcSet={largeImgPath}
      />
      <source
        media={`(min-width:${SCREEN_SIZES.TABLET}px) and (max-width:${SCREEN_SIZES.TABLET_MAX_WIDTH}px)`}
        srcSet={mediumImgPath}
      />
      <img alt={item.alt} src={cavcoThumbnailImgPath} />
    </picture>
  );
}

function displayRetailerLogo(containerName: string, retailerImage: any, retailerLogoImg: any) {
  const path = window.location.pathname.includes('/site') ? '/site' : '';

  return (
    <div className="cvc-header-retailer-toggle-nav-content-logo">
      <ResolverLink href={path}>
        {getImage(containerName, retailerImage, retailerLogoImg)}
      </ResolverLink>
    </div>
  );
}

export function RetailerHeader2(props: BrProps) {
  const [requestInfoModal, setModal] = useState(false);
  const closeModal = () => setModal(false);
  const openModal = () => setModal(true);

  const [loginModal, setLoginModal] = useState(false);
  const closeLoginModal = () => setLoginModal(false);
  const openLoginModal = () => setLoginModal(true);

  const [showDropdown, setShowDropdown] = useState(false);

  const handleSetSuccessfulSignIn = () => {};

  const [showRightSlide, setRightSlide] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);

  const windowDimensions = useWindowDimensions();

  const page = React.useContext(BrPageContext);
  const history = useHistory();

  const dropDownListType: boolean[] = [];
  const [dropDownList, setMyDropDownList] = useState(dropDownListType);

  const menuObject = Menu(HEADER_CONTENT_TYPE.RETAILER, page, windowDimensions, history);
  const menuList = menuObject?.props?.children[0]?.props?.menu?.siteMenuItems;
  const location = useLocation();
  const [userId, setUserId] = useState(getCookie('cavcouserid'));

  // this closes sidebar menu when you click off of it
  useEffect(() => {
    const sidebar = window.document.querySelector('#responsive-navbar-nav');
    if (
      (windowDimensions.isMobile || windowDimensions.isTablet) &&
      sidebar &&
      sidebar.classList.contains('is-open')
    ) {
      window.document.body.addEventListener('click', (e: any) => {
        // Makes it so you can't click on the sidebar to close it.

        if (e.target.closest('#responsive-navbar-nav') === null) {
          setMenuOpen(false);
        }
      });
    }
    return window.document.body.removeEventListener('click', () => setMenuOpen(false));
  });

  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const subscription = loginSubject$.subscribe((loginStatus: any) => {
      if (loginStatus) {
        setUserId(getCookie('cavcouserid'));
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const {
    header: documentRef,
    componentParameterMap,
    myAccountConfig,
    siteGlobalSwitches,
    retailerLocationDetailsLabels,
  } = props.component.getModels();

  const document = documentRef ? props.page.getContent(documentRef) : undefined;
  if (!document) {
    return null;
  }
  const { logoImage, logoImages } = document.getData<HeaderData>();

  SiteGlobalSwitches.myAccountSwitchOn = !!(
    siteGlobalSwitches && siteGlobalSwitches.myAccountSwitch.toLowerCase() === 'on'
  );

  const showFavorites = SiteGlobalSwitches.myAccountSwitchOn;
  const retailerData = getRetailerData(props.page);
  const retailerUrl = retailerData?.retailerPath || '';

  let logoImageUrl = logoImage.destinationUrl.replace('$retailer', retailerUrl);
  logoImageUrl = logoImage && window.spaBaseUrl + logoImageUrl;

  function formatPhoneNumber(number: string | undefined = '') {
    let phone = number;

    if (number.length === 10) {
      phone = `${number.substr(0, 3)}-${number.substr(3, 3)}-${number.substr(6, 4)}`;
    }

    return phone;
  }

  const phone = () => {
    const phoneFormatted = formatPhoneNumber(retailerData?.phone);
    const phoneLink = `tel:${retailerData?.phone}`;

    return (
      <>
        <PhoneIcon className="cvc-header-retailer-info-contact-phone-icon" />
        <a href={phoneLink}>{phoneFormatted}</a>
      </>
    );
  };

  const favorite = () => {
    return (
      <div>
        {showFavorites && (
          <RetailerFavoriteIcon
            key={`retailer-header-favorite${retailerData?.locationID}`}
            id={retailerData?.locationID}
            // isFavorite={retailerData?.isFavorite}
            baseUrl={myAccountConfig.favoriteBaseUrl}
            loadFavoriteUrl={myAccountConfig.cavcoFavoriteUrl}
            myAccountConfig={myAccountConfig}
          />
        )}
      </div>
    );
  };

  const retailerName = () => {
    return <ResolverLink href={logoImageUrl ?? '/'}>{retailerData?.name}</ResolverLink>;
  };

  function displayFullAddress() {
    return (
      <>
        {retailerData?.address1},{retailerData?.address2 ? ` ${retailerData?.address2}, ` : ''}
        {(windowDimensions.isMobile || windowDimensions.isTablet) && <br />} {retailerData?.city},{' '}
        {retailerData?.state} {retailerData?.zip}
      </>
    );
  }

  const address = () => {
    return (
      <>
        <LocationIcon className="cvc-header-retailer-info-contact-address-icon" />
        {displayFullAddress()}
      </>
    );
  };

  const details = props.page.getComponent('main', 'detailcontainer');
  const form =
    details && (details.getComponent('request-info') || details.getComponent('fp-request-form'));

  const displayHomeForm = details && details.getComponent('dh-request-form');

  const contactUs = () => {
    return (
      <div>
        <Button variant="primary" className=" m-0" onClick={openModal}>
          Contact Us
        </Button>
        <Modal
          className="cvc-request-info-modal-retailer"
          show={requestInfoModal}
          onHide={closeModal}
          backdrop={windowDimensions.isMobile || windowDimensions.isTablet ? 'static' : true}
          keyboard={!(windowDimensions.isMobile || windowDimensions.isTablet)}
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body id="id-modal-body" className="px-0">
            {form && (
              <FloorPlanRequestInfoWithParams
                page={props.page}
                component={form}
                displayInsideModal
                formId="retailer-home-header-link-modal-form"
              />
            )}
            {!form && displayHomeForm && (
              <DisplayHomeRequestInfo page={props.page} component={displayHomeForm} />
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  let monKey = 'Mon';
  let tueKey = 'Tue';
  let wedKey = 'Wed';
  let thuKey = 'Thu';
  let friKey = 'Fri';
  let satKey = 'Sat';
  let sunKey = 'Sun';

  if (retailerLocationDetailsLabels) {
    monKey = retailerLocationDetailsLabels.mon;
    tueKey = retailerLocationDetailsLabels.tue;
    wedKey = retailerLocationDetailsLabels.wed;
    thuKey = retailerLocationDetailsLabels.thu;
    friKey = retailerLocationDetailsLabels.fri;
    satKey = retailerLocationDetailsLabels.sat;
    sunKey = retailerLocationDetailsLabels.sun;
  }

  const current = new Date();
  const currentDay = `current${current.getDay()}`;

  const hours = () => {
    return (
      <>
        <dl className={currentDay}>
          <dt className="day1">{monKey}</dt>
          <dd className="day1">{retailerData?.hoursOpMonday}</dd>
          <dt className="day2">{tueKey}</dt>
          <dd className="day2">{retailerData?.hoursOpTuesday}</dd>
          <dt className="day3">{wedKey}</dt>
          <dd className="day3">{retailerData?.hoursOpWednesday}</dd>
          <dt className="day4">{thuKey}</dt>
          <dd className="day4">{retailerData?.hoursOpThursday}</dd>
          <dt className="day5">{friKey}</dt>
          <dd className="day5">{retailerData?.hoursOpFriday}</dd>
          <dt className="day6">{satKey}</dt>
          <dd className="day6">{retailerData?.hoursOpSaturday}</dd>
          <dt className="day7">{sunKey}</dt>
          <dd className="day7">{retailerData?.hoursOpSunday}</dd>
        </dl>
      </>
    );
  };

  function ExpandHours() {
    const [isActive, setActive] = useState(false);
    const toggleClass = () => {
      setActive(!isActive);
    };
    return (
      <>
        <HoursIcon className="cvc-header-retailer-info-contact-hours-icon" />
        <div
          role="presentation"
          className={isActive ? 'full-list' : 'default-list'}
          onClick={toggleClass}
        >
          {hours()}
        </div>
      </>
    );
  }

  function getRetailerLogoImageKeyBy(parkModelRetailer: boolean, community: boolean): string {
    const imagesArray = [
      { key: 'false-false', value: 'std-retailer' },
      { key: 'false-true', value: 'std-community' },
      { key: 'true-false', value: 'park-retailer' },
      { key: 'true-true', value: 'park-community' },
    ];
    const keyCompound = `${parkModelRetailer.toString()}-${community.toString()}`;

    const imageFound = imagesArray.find((item) => item.key === keyCompound);

    if (!imageFound) {
      return 'std-community';
    }

    return imageFound.value;
  }

  const imageKey = getRetailerLogoImageKeyBy(
    !!retailerData?.parkModelRetailer,
    !!retailerData?.community
  );

  let retailerImage: any = null;
  let retailerLogoImg: any = null;

  if (imageKey) {
    retailerImage = logoImages.imageKeyed.find((img) => img.key === imageKey);
    retailerLogoImg = retailerImage && props.page.getContent(retailerImage.imagelink);
  }

  const isProd =
    componentParameterMap &&
    componentParameterMap.environment &&
    componentParameterMap.environment === 'prod';
  const basePath = getBasePath(isProd);
  const retailerBasePath = basePath + retailerData?.retailerPath?.substring(1);

  const logo = () => {
    return (
      <div className="cvc-header-retailer-flag">
        <Link
          key={`${logoImageUrl}`}
          to={`${logoImageUrl}`}
          className="cvc-header-retailer-flag-brand"
        >
          {getImage('retailer-logo', retailerImage, retailerLogoImg)}
        </Link>
      </div>
    );
  };

  const showSignIn = SiteGlobalSwitches.myAccountSwitchOn
    ? componentParameterMap.showSignIn
    : 'off';

  const getLoginModal = () => {
    return (
      <>
        {loginModal && (
          <SignInModals
            isMobile={windowDimensions.isMobile}
            isTablet={windowDimensions.isTablet}
            myAccountConfig={myAccountConfig}
            loginModalClosed={closeLoginModal}
            setSuccessfulSignIn={handleSetSuccessfulSignIn}
          />
        )}
      </>
    );
  };

  const logOut = () => {
    localStorage.clear();
    deleteAllCookie();
    loginSubject$.next(LoginStatus.loggedOut);
  };

  function displaySignIn() {
    return showSignIn === 'on' && !userId ? ( // eslint-disable-line
      <span
        onClick={openLoginModal}
        onKeyDown={openLoginModal}
        style={{ cursor: 'pointer' }}
        role="button"
        tabIndex={0}
      >
        <UserMyFavoritesIcon className="my-account-nav-icon" />
        <span className="my-account-nav-label">Sign In</span>
        {getLoginModal()}
      </span>
    ) : showSignIn === 'on' && !!userId ? (
      <NavDropdown
        title={
          <Link
            key={`${basePath}${getMyAccountPath('my-account')}`}
            to={`${basePath}${getMyAccountPath('my-account')}`}
            className="dropdown-toggle"
          >
            <UserMyFavoritesIcon className="my-account-nav-icon" />
            <span className="my-account-nav-label">My Favorites</span>
          </Link>
        }
        id="MyFavoritesDropDown"
        onMouseEnter={() => setShowDropdown(true)}
        onMouseLeave={() => setShowDropdown(false)}
        show={showDropdown}
      >
        <NavDropdown.Item className="dropdown-toggle">
          <Link
            key={`${basePath}${getMyAccountPath('my-account?settings')}`}
            to={`${basePath}${getMyAccountPath('my-account?settings')}`}
            className="dropdown-toggle"
          >
            My Settings
          </Link>
        </NavDropdown.Item>

        <NavDropdown.Item onClick={logOut} className="dropdown-toggle">
          Log Out
        </NavDropdown.Item>
      </NavDropdown>
    ) : (
      ''
    );
  }

  const fillInDropDownList = (dropDownLists: boolean[]) => {
    const tempList = [...dropDownLists];
    setMyDropDownList(dropDownListType);
    setMyDropDownList([...tempList]);
  };

  const setMyDropDownListOnMouseEnterHandler = (index: number) => {
    dropDownList[index] = true;
    fillInDropDownList(dropDownList);
  };

  const setMyDropDownListOnMouseLeaveHandler = (index: number) => {
    dropDownList[index] = false;
    fillInDropDownList(dropDownList);
  };

  const navBarTabs = () => {
    const navList: any = [];
    if (!dropDownList || dropDownList.length === 0) {
      menuList.forEach(() => {
        dropDownList.push(false);
      });
    }
    return (
      <Navbar className="cvc-header-retailer-nav">
        {menuList.forEach((menu: any, index: number) => {
          navList.push(
            <div className="cvc-header-retailer-nav-item">
              {menu?.childMenuItems && menu?.childMenuItems.length > 0 ? (
                <NavDropdown
                  title={
                    <Link
                      key={`${retailerBasePath}/${menu?._links?.site?.href}`}
                      to={`${retailerBasePath}/${menu?._links?.site?.href}`}
                      className="dropdown-toggle"
                    >
                      {menu?.name}
                    </Link>
                  }
                  className=""
                  id={`${retailerBasePath}/${menu?._links?.site?.href}`}
                  onMouseEnter={() => setMyDropDownListOnMouseEnterHandler(index)}
                  onMouseLeave={() => setMyDropDownListOnMouseLeaveHandler(index)}
                  show={dropDownList[index]}
                >
                  {menu?.childMenuItems.map((childMenu: any) => {
                    return (
                      <NavDropdown.Item className="dropdown-toggle">
                        <Link
                          key={`${retailerBasePath}/${childMenu?._links?.site?.href}`}
                          to={`${retailerBasePath}/${childMenu?._links?.site?.href}`}
                          className="dropdown-toggle"
                        >
                          {childMenu?.name}
                        </Link>
                      </NavDropdown.Item>
                    );
                  })}
                </NavDropdown>
              ) : (
                <Link
                  key={`${retailerBasePath}/${menu?._links?.site?.href}`}
                  to={`${retailerBasePath}/${menu?._links?.site?.href}`}
                  className=""
                >
                  {menu?.name}
                </Link>
              )}
            </div>
          );
        })}
        {navList}
        <div className="cvc-header-retailer-nav-item my-account">{displaySignIn()}</div>
      </Navbar>
    );
  };

  const UserAccountRightSlide = () => {
    return (
      <Drawer anchor="right" open={showRightSlide} onClose={() => setRightSlide(false)}>
        <div className="cvc-header-retailer-toggle-my-account-header">
          <h4 className="cvc-header-retailer-toggle-my-account-header-title">
            My {getCurrentSiteNameDisplay()} Account
          </h4>
          <button
            type="button"
            className="cvc-header-retailer-toggle-my-account-header-closer"
            aria-controls="responsive-navbar-nav"
            onClick={() => setRightSlide(false)}
          >
            <LeftArrowIcon
              style={{ transform: 'scaleX(-1)' }}
              className="cvc-header-retailer-toggle-my-account-header-closer-icon"
            />
          </button>
        </div>

        <div className="cvc-header-retailer-toggle-my-account-content">
          <List>
            <ListItem button>
              <Link
                key={`${basePath}${getMyAccountPath('my-account')}`}
                to={`${basePath}${getMyAccountPath('my-account')}`}
              >
                My Favorites
              </Link>
            </ListItem>
            <List>
              <ListItem button>
                <Link
                  key={`${basePath}${getMyAccountPath('my-account?floor-plans')}`}
                  to={`${basePath}${getMyAccountPath('my-account?floor-plans')}`}
                >
                  My Floor Plans
                </Link>
              </ListItem>
              <ListItem button>
                <Link
                  key={`${basePath}${getMyAccountPath('my-account?locations')}`}
                  to={`${basePath}${getMyAccountPath('my-account?locations')}`}
                >
                  My Locations
                </Link>
              </ListItem>
            </List>
            <ListItem button>
              <Link
                key={`${basePath}${getMyAccountPath('my-account?settings')}`}
                to={`${basePath}${getMyAccountPath('my-account?settings')}`}
              >
                My Settings
              </Link>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                logOut();
                setRightSlide(false);
              }}
            >
              Log Out
            </ListItem>
          </List>
        </div>
      </Drawer>
    );
  };

  const IconDisplayMobileTablet = () => {
    return (
      <div className="cvc-header-retailer-toggle-my-account">
        {!!userId && (
          <div
            className="cvc-header-retailer-toggle-my-account-toggler logged-in"
            onClick={() => setRightSlide(true)}
            onKeyDown={() => setRightSlide(true)}
            role="button"
            tabIndex={0}
          >
            <UserMyFavoritesIcon />
          </div>
        )}
        {!userId && (
          <div
            className="cvc-header-retailer-toggle-my-account-toggler"
            onClick={openLoginModal}
            onKeyDown={openLoginModal}
            role="button"
            tabIndex={0}
          >
            <UserMyFavoritesIcon />
          </div>
        )}
        {getLoginModal()}
      </div>
    );
  };

  const signInMobileTablet = () => {
    return (
      <>
        {showSignIn === 'on' ? (
          <>
            <IconDisplayMobileTablet />
            <UserAccountRightSlide />
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  const navigationClasses = classNames({
    'cvc-header-comp-navbar-navigation': true,
    'is-open': menuOpen,
  });

  const subLeftNavBarList = (menu: any) => {
    return (
      <List>
        {menu?.childMenuItems.map((childMenu: any) => {
          return (
            <ListItem button>
              <Link
                key={`${retailerBasePath}/${childMenu?._links?.site?.href}`}
                to={`${retailerBasePath}/${childMenu?._links?.site?.href}`}
              >
                {childMenu?.name}
              </Link>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const leftNavBarList = () => {
    return (
      <List>
        {menuList.map((menu: any) => {
          return (
            <>
              <ListItem button>
                <Link
                  key={`${retailerBasePath}/${menu?._links?.site?.href}`}
                  to={`${retailerBasePath}/${menu?._links?.site?.href}`}
                >
                  {menu?.name}
                </Link>
              </ListItem>
              {subLeftNavBarList(menu)}
            </>
          );
        })}
      </List>
    );
  };

  function getRetailerStateAndCity() {
    if (!retailerData?.city && !retailerData?.state) return '';

    return `${retailerData?.city}, ${retailerData?.state}`;
  }

  const leftNavBar = () => {
    return (
      <div className="cvc-header-retailer-toggle-nav">
        <div
          className="cvc-header-retailer-toggle-nav-toggler"
          onClick={() => setMenuOpen(true)}
          onKeyDown={() => setMenuOpen(true)}
          role="button"
          tabIndex={0}
        >
          <NavBarTogglerIcon />
        </div>
        <div id="responsive-navbar-nav" className={navigationClasses} style={{ zIndex: 9999 }}>
          <div className="cvc-header-retailer-toggle-nav-header">
            <button
              type="button"
              className="cvc-header-retailer-toggle-nav-header-closer"
              aria-controls="responsive-navbar-nav"
              onClick={() => setMenuOpen(false)}
            >
              <LeftArrowIcon className="cvc-header-retailer-toggle-nav-header-closer-icon" />
            </button>

            <div className="cvc-header-retailer-toggle-nav-header-title">
              <div className="cvc-header-retailer-toggle-nav-header-title-name">
                {retailerData && retailerData.name}
              </div>
              <div className="cvc-header-retailer-toggle-nav-header-title-location">
                {retailerData && getRetailerStateAndCity()}
              </div>
            </div>
          </div>
          <div className="cvc-header-retailer-toggle-nav-content">
            {leftNavBarList()}
            {displayRetailerLogo('retailer-logo', retailerImage, retailerLogoImg)}
          </div>
        </div>
      </div>
    );
  };

  const breadcrumb = details && details.getComponent('breadcrumb-stack');

  const getHomesOfficialRetailer = () => {
    const retailerLabel = retailerData?.community ? 'Community' : 'Retailer';
    return `${getCurrentSiteWithoutHomes()} Homes Official ${retailerLabel}`;
  };

  const fullHeader = () => {
    const parameters = props.component.getParameters();
    parameters.requestInfo = false;
    parameters.share = false;

    return (
      <div className="cvc-header-retailer">
        {logo()}
        {leftNavBar()}
        {signInMobileTablet()}
        <div className="cvc-header-retailer-info">
          <div className="cvc-header-retailer-info-head">
            <div className="cvc-header-retailer-info-head-breadcrumbs">
              {breadcrumb && (
                <CustomBreadcrumbStackWithoutRequestInfoShare
                  props={{ page: props.page, component: breadcrumb }}
                />
              )}
            </div>
            <div className="cvc-header-retailer-info-head-title">
              <h5 className="cvc-header-retailer-info-head-title-location">
                {retailerData?.city}, {retailerData?.state}
              </h5>
              <h1 className="cvc-header-retailer-info-head-title-name">{retailerName()}</h1>
              <h6 className="cvc-header-retailer-info-head-title-type">
                {getHomesOfficialRetailer()}
              </h6>
            </div>
          </div>
          <div className="cvc-header-retailer-info-contact">
            <div className="cvc-header-retailer-info-contact-address-phone">
              <div className="cvc-header-retailer-info-contact-address">{address()}</div>
              <div className="cvc-header-retailer-info-contact-phone">{phone()}</div>
            </div>
            <div className="cvc-header-retailer-info-contact-hours-btns">
              <div className="cvc-header-retailer-info-contact-hours">{ExpandHours()}</div>
              <div className="cvc-header-retailer-info-contact-btns">
                <div className="cvc-header-retailer-info-contact-btns-request">{contactUs()}</div>
                <div className="cvc-header-retailer-info-contact-btns-favorite">{favorite()}</div>
              </div>
            </div>
          </div>
        </div>
        {navBarTabs()}
      </div>
    );
  };

  return <> {fullHeader()} </>;
}
