import React, { useState, FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import 'components/shared/ProductDetails/ProductRequestInfo.scss';
import { Formik, Field, Form } from 'formik';
import * as Scroll from 'react-scroll';
import DatePicker from 'react-datepicker';
import { BrProps } from '@bloomreach/react-sdk';
import { ResolverLink } from 'components/shared';
import { Spinner } from 'components/shared/Spinner';
import { getRetailerData, TagManager } from '../utils';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar-mini-icon-blue.svg';
import { ReactComponent as ClockIcon } from '../../assets/images/clock-mini-icon-blue.svg';
import useLasso from '../../lasso';

const { scroller } = Scroll;
const FORM_TYPE = { SIDEBAR: 31, FULL_SCREEN: 32, INLINE: 33 };
const OWN_LAND = {
  yes: '1',
  no: '0',
};
let isSearching = false;

function getPHText(
  documents: any,
  props: BrProps,
  phText: string,
  isRetailer: boolean,
  phGeneric: string
) {
  const ref = documents && documents[0];
  const document = ref && props.page.getContent(ref);

  if (!document) {
    return phGeneric;
  }

  if (isRetailer) {
    const { name } = document.getData();
    return phText.replace('%', name);
  }

  const { modelName, modelNumber } = document.getData();
  return phText.replace('%', `${modelName} ${modelNumber}`);
}

function isPark(documents: any, props: BrProps, isRetailer: any) {
  let parkFind = false;

  if (isRetailer) {
    parkFind = window.location.href.includes('park');
  } else {
    const ref = documents && documents[0];
    const document = ref && props.page.getContent(ref);

    if (!document) {
      return false;
    }

    const { buildingMethodValue } = document.getData();
    parkFind = buildingMethodValue.toString().toLowerCase().includes('park');
  }

  return parkFind;
}

// function ddStates(url: string, usStates: any, caProvinces: any) {
//   if (url.includes('/us')) {
//     return usStates;
//   }
//   return caProvinces;
// }

function FloorPlanConfirmationModal(props: any) {
  const titleClassNames = props.isError
    ? classNames('modal-error-title', 'mb-3')
    : classNames('modal-title', 'mb-3');
  const textClassNames = props.isError ? classNames('modal-error-text') : classNames('modal-text');

  return (
    <Modal className="cvc-fp-request-info-modal text-center" show={props.show} onHide={props.close}>
      <Modal.Header closeButton />
      <Modal.Body className="mt-4 px-5">
        <h2 className={titleClassNames}>{props.title}</h2>
        <div className={textClassNames}>
          <p>{props.content}</p>
        </div>
        <button
          type="button"
          className="cvc-form-button btn btn-primary px-4"
          onClick={props.close}
        >
          {props.buttonText}
        </button>
      </Modal.Body>
    </Modal>
  );
}

const setIsSearching = (value: any) => {
  isSearching = value;
};

export function FloorPlanRequestInfo(
  props: BrProps,
  displayInsideModal = false,
  formId = 'floor-plan-request-info-form'
) {
  const {
    documents,
    requestInfoFormLabels: lbl,
    formModalLabel: modalLbl,
    requestInfoFormConfig,
    componentParameterMap,
    usStates,
    caProvinces,
    spinnerConfig,
  } = props.component.getModels();
  const pageLocation = useLocation();

  const { template } = componentParameterMap;
  const isRetailer = template === 'retailer';
  const commentPH = isRetailer ? lbl.commentPH_retailer : lbl.commentPH;

  const retailerData = getRetailerData(props.page);

  let locationID = '';
  if (retailerData && retailerData.locationID) {
    locationID = retailerData.locationID;
  }

  let floorplanAssetId = '';
  const ref = documents && documents[0];
  const fpDoc = ref && props.page.getContent(ref);
  if (!isRetailer && fpDoc) {
    const { assetId } = fpDoc.getData();
    floorplanAssetId = assetId || '';
  }

  const { lasso } = useLasso();

  const showManagerHeader = retailerData && retailerData.generalManagerName;
  const [modalContent, setModalContent] = useState({ title: '', text: '', isError: false });
  const [ownLandValue, setOwnLandValue] = useState('');

  const [modal, setModal] = useState(false);
  // should work on this MT
  // const closeModal = () => {
  //   setModal(false);
  //   if (props.onClose) {
  //     props.onClose();
  //   }
  // };
  const closeModal = () => setModal(false);
  const openModal = () => setModal(true);

  // Set value for controlled fields
  const [locationToBuildState, setLocationToBuildState] = useState('');

  const [firstNameValue, setFirstNameValue] = useState('');
  const [lastNameValue, setLastNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [phoneAreaValue, setPhoneAreaValue] = useState('');
  const [phonePrefixValue, setPhonePrefixValue] = useState('');
  const [phoneSufixValue, setPhoneSufixValue] = useState('');
  const [locationToBuildCityValue, setLocationToBuildCityValue] = useState('');
  const [locationToBuildZipValue, setLocationToBuildZipValue] = useState('');

  // Check for invalid fields
  const [firstNameInvalid, setFirstNameInvalid] = useState(false);
  const [lastNameInvalid, setLastNameInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [phoneInvalid, setPhoneInvalid] = useState(false);
  const [locationInvalid, setLocationInvalid] = useState(false);
  const [ownLandInvalid, setOwnLandInvalid] = useState(false);
  const [appointmentDateFromPicker, setAppointmentDate] = useState<Date | null>(null);
  const [appointmentTimeFromPicker, setAppointmentTime] = useState<Date | null>(null);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [timePickerIsOpen, setTimePickerIsOpen] = useState(false);
  const [appointmentDateInvalid, setAppointmentDateInvalid] = useState(false);
  const [appointmentTimeInvalid, setAppointmentTimeInvalid] = useState(false);

  function handleDateChange(date: any, e: any) {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    setAppointmentDate(date);
    setDatePickerIsOpen(false);

    if (
      (date === null && appointmentTimeFromPicker === null) ||
      (appointmentTimeFromPicker === null && appointmentDateFromPicker === null)
    ) {
      setAppointmentTimeInvalid(false);
    }
  }

  function handleTimeChange(time: any) {
    setAppointmentTime(time);
    setTimePickerIsOpen(false);

    if (
      (time === null && appointmentTimeFromPicker === null) ||
      (appointmentTimeFromPicker === null && appointmentDateFromPicker === null)
    ) {
      setAppointmentDateInvalid(false);
    }
  }

  function handleRadioLabelKeyDown(e: any, value: string) {
    if (e.keyCode === 32 || e.keyCode === 13) {
      e.preventDefault();
      setOwnLandValue(value);
    }
  }

  function validateFields(e: any) {
    setIsSearching(true);

    let validForm = e.target.parentElement.checkValidity();

    const ownLandCurrentValue = ownLandValue;
    if (ownLandCurrentValue === '') {
      setOwnLandInvalid(true);
      validForm = false;
    }

    if (appointmentTimeFromPicker !== null && appointmentDateFromPicker === null) {
      const datePickerInput = document.getElementById('appointmentDate');
      if (datePickerInput) {
        (datePickerInput as HTMLInputElement).focus();
      }
      setAppointmentDateInvalid(true);
    }
    if (appointmentDateFromPicker !== null && appointmentTimeFromPicker === null) {
      const timePickerInput = document.getElementById('appointmentTime');
      if (timePickerInput) {
        (timePickerInput as HTMLInputElement).focus();
      }
      setAppointmentTimeInvalid(true);
    }

    if (!validForm || ownLandCurrentValue === '') {
      const scrollOptions: any = {
        smooth: true,
        duration: 1500,
        offset: displayInsideModal === true ? 0 : -100,
      };
      if (displayInsideModal === true) {
        scrollOptions.containerId = 'id-modal-body';
      }

      scroller.scrollTo(
        `fp-request-info${displayInsideModal === true ? '-inside-modal' : ''}`,
        scrollOptions
      );
    }

    setFirstNameInvalid(false);
    setLastNameInvalid(false);
    setEmailInvalid(false);
    setPhoneInvalid(false);
    setLocationInvalid(false);
    if (ownLandCurrentValue !== '') {
      setOwnLandInvalid(false);
    }
    if (
      (appointmentTimeFromPicker !== null && appointmentDateFromPicker !== null) ||
      (appointmentTimeFromPicker === null && appointmentDateFromPicker === null)
    ) {
      setAppointmentDateInvalid(false);
      setAppointmentTimeInvalid(false);
    }

    setIsSearching(false);
  }

  const retailerManagerHeader = () => {
    if (!retailerData) {
      return null;
    }
    const {
      generalManagerName: managerName,
      generalManagerTitle,
      generalManagerPictureURL: imageURL,
    } = retailerData;

    if (!managerName) {
      return null;
    }
    const managerTitle = generalManagerTitle || lbl.gmTitle;
    return (
      <div className="cvc-retailer-manager">
        {imageURL && (
          <img className="cvc-retailer-manager-image" src={imageURL} alt={managerName} />
        )}
        <div className="cvc-retailer-manager-text">
          <p className="cvc-retailer-manager-name">{managerName}</p>
          <p className="cvc-retailer-manager-title">{managerTitle}</p>
        </div>
      </div>
    );
  };

  const { webType } = requestInfoFormConfig;

  // const url = props.component.getUrl();
  // const ddData = url && ddStates(url, usStates, caProvinces);
  const ddData = { ...usStates, ...caProvinces };
  const options = Object.entries(ddData);
  options.sort();

  // for both US and Canada
  const zipPattern = '[0-9]{5}(?:-[0-9]{4})?|[a-zA-Z][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]';

  const spinnerColorInLightBackground =
    spinnerConfig && spinnerConfig.spinner_color_light_background;

  return (
    <div
      id={`fp-request-info${displayInsideModal === true ? '-inside-modal' : ''}`}
      className={`cvc-fp-request-info cvc-product-request-info ${
        !isRetailer && retailerData ? ' retailer-form' : ''
      }`}
    >
      <div className="cvc-request-form-header">
        <h2 className={`cvc-fp-request-form-title ${showManagerHeader ? ' with-manager' : ''}`}>
          {lbl.title}
        </h2>
        {showManagerHeader && documents.length > 0 ? retailerManagerHeader() : <></>}
      </div>

      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          emailAddress: '',
          appointmentDate: '',
          appointmentTime: '',
          ownLand: '',
          phoneNo: '',
          phone1: '',
          phone2: '',
          phone3: '',
          locationToBuildZip: '',
          locationToBuildCity: '',
          locationToBuildState: '',
          version: componentParameterMap.version,
          currentPageURL: '',
          locationUid: '',
          webType: '',
          trafficSource: { lassoGuid: lasso.guid },
          comments: getPHText(documents, props, commentPH, isRetailer, lbl.commentPH_generic),
          currentPageAssetId: '',
        }}
        onSubmit={async (values, { resetForm }) => {
          setIsSearching(true);

          values.firstName = firstNameValue;
          values.lastName = lastNameValue;
          values.emailAddress = emailValue;
          values.phone1 = phoneAreaValue;
          values.phone2 = phonePrefixValue;
          values.phone3 = phoneSufixValue;
          values.locationToBuildCity = locationToBuildCityValue;
          values.locationToBuildZip = locationToBuildZipValue;
          values.phoneNo = values.phone1 + values.phone2 + values.phone3;
          values.locationToBuildState = locationToBuildState;
          values.currentPageURL = window.location.href;
          values.locationUid = locationID;
          values.ownLand = ownLandValue;
          values.webType = webType;
          values.trafficSource.lassoGuid = lasso.guid;
          values.currentPageAssetId = floorplanAssetId;

          const appointmentDateInputPicker = document.getElementById('appointmentDate');
          if (
            appointmentDateInputPicker &&
            (appointmentDateInputPicker as HTMLInputElement).value === '' &&
            appointmentDateFromPicker === null
          ) {
            setAppointmentDate(null);
            values.appointmentDate = '';
          }
          if (appointmentDateFromPicker && appointmentDateFromPicker !== null) {
            values.appointmentDate = appointmentDateFromPicker.toLocaleDateString('en-US');
          }

          const appointmentTimerInputPicker = document.getElementById('appointmentTime');
          if (
            appointmentTimerInputPicker &&
            (appointmentTimerInputPicker as HTMLInputElement).value === '' &&
            appointmentTimeFromPicker === null
          ) {
            setAppointmentTime(null);
            values.appointmentTime = '';
          }

          if (appointmentTimeFromPicker && appointmentTimeFromPicker !== null) {
            values.appointmentTime = appointmentTimeFromPicker.toLocaleTimeString('en-US', {
              hour12: true,
              hour: '2-digit',
              minute: '2-digit',
            });
          }

          // version indicates the form type and is required
          if (!values.version) values.version = FORM_TYPE.SIDEBAR;

          if (values.ownLand === '') {
            setIsSearching(false);
            return;
          }

          if (appointmentTimeFromPicker !== null && appointmentDateFromPicker === null) {
            setIsSearching(false);
            return;
          }
          if (appointmentDateFromPicker !== null && appointmentTimeFromPicker === null) {
            setIsSearching(false);
            return;
          }

          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
          };

          const isParkVal = isPark(documents, props, isRetailer);
          const formUrl =
            requestInfoFormConfig.apiRoot +
            (isParkVal
              ? requestInfoFormConfig.requestInfoParkUrl
              : requestInfoFormConfig.requestInfoUrl);
          //          console.log("formUrl", formUrl);

          fetch(formUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              console.log('responseData:', data); // eslint-disable-line

              // check for error
              if (data.status !== 200) {
                const textLength = modalLbl.modalTextLength
                  ? parseInt(modalLbl.modalTextLength, 10)
                  : 50;
                const payloadErrorString = data.payload;
                const jsonText = payloadErrorString.slice(
                  payloadErrorString.indexOf('{'),
                  payloadErrorString.length
                );
                const parsedJson = JSON.parse(jsonText);
                const errorText = parsedJson.message;
                const errorMessage =
                  errorText.length > textLength
                    ? `${errorText.substr(0, textLength)}...`
                    : errorText;

                setModalContent({
                  title: modalLbl.modalErrorTitle,
                  text:
                    errorMessage ||
                    (payloadErrorString.length > textLength
                      ? `${payloadErrorString.substr(0, textLength)}...`
                      : payloadErrorString),
                  isError: true,
                });
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'formError',
                    errorMessage,
                    formId,
                    pageHref: pageLocation.pathname,
                  },
                });

                setIsSearching(false);
                openModal();
              } else {
                setModalContent({
                  title: modalLbl.modalTitle,
                  text: modalLbl.modalText,
                  isError: false,
                });
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'formSubmission',
                    formId,
                    pageHref: pageLocation.pathname,
                  },
                });

                setFirstNameValue('');
                setLastNameValue('');
                setEmailValue('');
                setPhoneAreaValue('');
                setPhonePrefixValue('');
                setPhoneSufixValue('');
                setLocationToBuildCityValue('');
                setLocationToBuildZipValue('');

                setLocationToBuildState('');
                setOwnLandValue('');

                setAppointmentDate(null);
                setAppointmentTime(null);
                setIsSearching(false);
                openModal();
                resetForm();
              }
            })
            .catch((error) => {
              console.log('error from the server:', error.message); // eslint-disable-line
              const textLength = modalLbl.modalTextLength
                ? parseInt(modalLbl.modalTextLength, 10)
                : 50;
              const errorMessage =
                error.message.length > textLength
                  ? `${error.message.substr(0, textLength)}...`
                  : error.message;
              setModalContent({
                title: modalLbl.modalErrorTitle,
                text: errorMessage,
                isError: true,
              });
              TagManager.dataLayer({
                dataLayer: {
                  event: 'formError',
                  errorMessage,
                  formId,
                  pageHref: pageLocation.pathname,
                },
              });

              setIsSearching(false);
              openModal();
            });

          // console.log('values', values) // eslint-disable-line
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Form
              id={formId}
              className="cvc-form "
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <div className="form-row">
                <Col className="cvc-form-group cvc-form-required" xs={6}>
                  <label htmlFor="firstName" className={firstNameInvalid ? 'cvc-form-error' : ''}>
                    {lbl.firstName}
                  </label>
                  <Field
                    id="firstName"
                    name="firstName"
                    type="text"
                    inputMode="text"
                    autoComplete="first-name"
                    pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
                    required
                    value={firstNameValue}
                    onChange={(e: any) => setFirstNameValue(e.target.value)}
                    onInvalid={() => setFirstNameInvalid(true)}
                  />
                </Col>

                <Col className="cvc-form-group cvc-form-required" xs={6}>
                  <label htmlFor="lastName" className={lastNameInvalid ? 'cvc-form-error' : ''}>
                    {lbl.lastName}
                  </label>
                  <Field
                    id="lastName"
                    name="lastName"
                    type="text"
                    inputMode="text"
                    autoComplete="family-name"
                    pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
                    required
                    value={lastNameValue}
                    onChange={(e: any) => setLastNameValue(e.target.value)}
                    onInvalid={() => setLastNameInvalid(true)}
                  />
                </Col>
              </div>

              <div className="form-row">
                <Col className="cvc-form-group cvc-form-required">
                  <label htmlFor="emailAddress" className={emailInvalid ? 'cvc-form-error' : ''}>
                    {lbl.email}
                  </label>
                  <Field
                    id="emailAddress"
                    name="emailAddress"
                    inputMode="email"
                    autoComplete="email"
                    type="email"
                    required
                    value={emailValue}
                    onChange={(e: any) => setEmailValue(e.target.value)}
                    onInvalid={() => setEmailInvalid(true)}
                  />
                </Col>
              </div>

              <div className="form-row">
                <Col className="cvc-form-group cvc-form-required">
                  <label htmlFor="phone1" className={phoneInvalid ? 'cvc-form-error' : ''}>
                    {lbl.phone}
                  </label>
                  <div className="d-flex align-items-center cvc-form-group--phone">
                    <Field
                      className="col-3"
                      id="phone1"
                      name="phone1"
                      inputMode="tel"
                      autoComplete="tel tel-area-code"
                      type="tel"
                      maxLength="3"
                      pattern="[0-9]{3}"
                      aria-label="Phone area code"
                      required
                      value={phoneAreaValue}
                      onChange={(e: any) => setPhoneAreaValue(e.target.value)}
                      onInvalid={() => setPhoneInvalid(true)}
                    />
                    <div>-</div>
                    <Field
                      className="col-3"
                      name="phone2"
                      type="tel"
                      inputMode="tel"
                      autoComplete="tel-local-prefix"
                      maxLength="3"
                      pattern="[0-9]{3}"
                      aria-label="Phone number"
                      required
                      value={phonePrefixValue}
                      onChange={(e: any) => setPhonePrefixValue(e.target.value)}
                      onInvalid={() => setPhoneInvalid(true)}
                    />
                    <div>-</div>
                    <Field
                      className="col-5"
                      name="phone3"
                      type="tel"
                      inputMode="tel"
                      autoComplete="tel-local-suffix"
                      maxLength="4"
                      pattern="[0-9]{4}"
                      aria-label="Phone number"
                      required
                      value={phoneSufixValue}
                      onChange={(e: any) => setPhoneSufixValue(e.target.value)}
                      onInvalid={() => setPhoneInvalid(true)}
                    />
                  </div>
                </Col>
              </div>

              <div className="form-row">
                <Col className="cvc-form-group cvc-form-required">
                  <label
                    id="locationToBuild"
                    htmlFor="locationToBuildCity"
                    className={locationInvalid ? 'cvc-form-error' : ''}
                  >
                    {lbl.location}
                  </label>
                  <div className="form-row">
                    <Col>
                      <Field
                        id="locationToBuildCity"
                        name="locationToBuildCity"
                        inputMode="text"
                        autoComplete="address-level2"
                        type="text"
                        placeholder="City"
                        pattern="[A-Za-z\-\s\.]+"
                        aria-label="Location city"
                        required
                        value={locationToBuildCityValue}
                        onChange={(e: any) => setLocationToBuildCityValue(e.target.value)}
                        onInvalid={() => setLocationInvalid(true)}
                      />
                    </Col>
                    {options && (
                      <Col>
                        <Field
                          id="locationToBuildState"
                          as="select"
                          name="locationToBuildState"
                          aria-label="Location state"
                          required
                          autoComplete="address-level1"
                          value={locationToBuildState}
                          onChange={(e: any) => setLocationToBuildState(e.target.value)}
                          onInvalid={() => setLocationInvalid(true)}
                        >
                          <option value="" disabled>
                            State
                          </option>
                          {options.map((option: any) => {
                            return (
                              <option key={option[1]} value={option[1]}>
                                {option[0]}
                              </option>
                            );
                          })}
                        </Field>
                      </Col>
                    )}
                    <Col>
                      <Field
                        name="locationToBuildZip"
                        inputMode="decimal"
                        autoComplete="postal-code"
                        type="text"
                        placeholder="Zip"
                        aria-label="Location zip code"
                        pattern={zipPattern}
                        required
                        value={locationToBuildZipValue}
                        onChange={(e: any) => setLocationToBuildZipValue(e.target.value)}
                        onInvalid={() => setLocationInvalid(true)}
                      />
                    </Col>
                  </div>
                </Col>
              </div>

              <div className="form-row">
                <Col className="cvc-form-group cvc-form-required">
                  <label
                    id="ownLand"
                    htmlFor="ownLandRadioGroup"
                    className={ownLandInvalid ? 'cvc-form-error' : 'cvc-form-label'}
                  >
                    {lbl.ownLand}
                  </label>
                  <div id="ownLandRadioGroup" role="radiogroup" aria-labelledby="ownLand">
                    <label // eslint-disable-line
                      className={`lblRadio${ownLandValue === OWN_LAND.yes ? ' checked' : ''}`}
                      htmlFor="yes"
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                      tabIndex={0}
                      onKeyDown={(e: any) => handleRadioLabelKeyDown(e, OWN_LAND.yes)}
                      onClick={() => setOwnLandValue(OWN_LAND.yes)}
                    >
                      <Field
                        id="yes"
                        name="ownLand"
                        type="radio"
                        value={OWN_LAND.yes}
                        checked={ownLandValue === OWN_LAND.yes}
                        onChange={(event: any) => setOwnLandValue(event.target.value)}
                        onInvalid={() => setOwnLandInvalid(true)}
                        tabIndex="0"
                      />
                      &nbsp;{lbl.ownYes}
                    </label>
                    <label // eslint-disable-line
                      className={`lblRadio${ownLandValue === OWN_LAND.no ? ' checked' : ''}`}
                      htmlFor="no"
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                      tabIndex={0}
                      onKeyDown={(e: any) => handleRadioLabelKeyDown(e, OWN_LAND.no)}
                      onClick={() => setOwnLandValue(OWN_LAND.no)}
                    >
                      <Field
                        id="no"
                        name="ownLand"
                        type="radio"
                        value={OWN_LAND.no}
                        checked={ownLandValue === OWN_LAND.no}
                        onChange={(event: any) => setOwnLandValue(event.target.value)}
                        onInvalid={() => setOwnLandInvalid(true)}
                        tabIndex="0"
                      />
                      &nbsp;{lbl.ownNo}
                    </label>
                  </div>
                </Col>
              </div>

              <div className="form-row flex-column">
                <Col className="cvc-form-group">
                  <label
                    htmlFor="appointmentDate"
                    className={
                      appointmentDateInvalid || appointmentTimeInvalid
                        ? 'cvc-form-error'
                        : 'cvc-form-label'
                    }
                  >
                    {lbl.aptDate}
                  </label>
                  <div className="form-row">
                    <Col xs={7} className="position-relative">
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label id="appointmentDateLabel" style={{ display: 'none' }}>
                        Pick Date
                      </label>
                      <DatePicker
                        ariaLabelledBy="appointmentDateLabel"
                        className="cvc-form-field-date"
                        id="appointmentDate"
                        name="appointmentDate"
                        selected={appointmentDateFromPicker}
                        placeholderText="Date"
                        aria-label="Appointment date"
                        onChange={handleDateChange}
                        shouldCloseOnSelect
                        open={datePickerIsOpen}
                        onFocus={() => setDatePickerIsOpen(true)}
                        onBlur={() => setDatePickerIsOpen(false)}
                        onClickOutside={() => setDatePickerIsOpen(false)}
                      />
                      <CalendarIcon className="icon" onClick={() => setDatePickerIsOpen(true)} />
                    </Col>

                    <Col xs={5} className="position-relative">
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label id="appointmentTimeLabel" style={{ display: 'none' }}>
                        {/* eslint-disable-line jsx-a11y/label-has-associated-control */}
                        Pick time
                      </label>
                      <DatePicker
                        ariaLabelledBy="appointmentTimeLabel"
                        className="cvc-form-field-time ml-auto"
                        id="appointmentTime"
                        name="appointmentTime"
                        selected={appointmentTimeFromPicker}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        placeholderText="Time"
                        onChange={handleTimeChange}
                        dateFormat="h:mm aa"
                        shouldCloseOnSelect
                        open={timePickerIsOpen}
                        onFocus={() => setTimePickerIsOpen(true)}
                        onBlur={() => setTimePickerIsOpen(false)}
                        onClickOutside={() => setTimePickerIsOpen(false)}
                      />
                      <ClockIcon className="icon" onClick={() => setTimePickerIsOpen(true)} />
                    </Col>
                  </div>
                </Col>
              </div>

              <div className="form-row mb-2">
                <Col>
                  <label htmlFor="comments">{lbl.comments}</label>
                  <Field
                    id="comments"
                    name="comments"
                    component="textarea"
                    aria-label="Comments"
                    rows={6}
                  />
                </Col>
              </div>

              <p className="cvc-form-text disclaimer">{lbl.required}</p>
              <p className="cvc-form-text disclaimer">
                {lbl.privacy} <ResolverLink href={lbl.privacyUrl}>Privacy Policy</ResolverLink>
              </p>

              <button
                className="btn btn-primary mt-1"
                type="submit"
                onClick={validateFields}
                disabled={isSearching === true}
                tabIndex={isSearching ? -1 : 0}
              >
                {lbl.submit}
              </button>
              {isSearching ? (
                <Spinner
                  color={spinnerColorInLightBackground ? `${spinnerColorInLightBackground}` : ''}
                />
              ) : (
                ''
              )}
            </Form>
            <FloorPlanConfirmationModal
              title={modalContent.title}
              content={modalContent.text}
              buttonText={modalLbl.modalButtonText}
              show={modal}
              close={closeModal}
              isError={modalContent.isError}
            />
          </>
        )}
      </Formik>
    </div>
  );
}

export const FloorPlanRequestInfoWithParams: FunctionComponent<any> = (fpRequestInfoProps) => {
  const { displayInsideModal, formId, ...props } = fpRequestInfoProps;
  return FloorPlanRequestInfo(props, displayInsideModal, formId);
};
