import { Container } from 'components/Container/Container';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Form, Button, Spinner, Col, Row, Nav, Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import './FavoriteComponent.scss';
import './CHMyAccount.scss';
import {
  getCurrentSiteNameDisplay,
  getMyAccountPath,
  getBasePathFromUrl,
  getCavcoUserInfo,
  initAllFavoritesForCurrentUser,
} from 'components/utils';
import { CavcoSnackbar } from 'components/shared';
import { getCookie, deleteAllCookie } from 'components/hooks/cookie';
import { BrProps } from '@bloomreach/react-sdk';
import { FloorPlanCard } from 'components/FloorPlanRecentlyViewed/FloorPlanCard';
import { List, ListItem } from '@material-ui/core';
import { DisplayHomeCard } from 'components/RecentlyViewedHomes/ViewedHomeCards';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as EyeIcon } from '../../assets/images/eye.svg';
import { ReactComponent as NoEyeIcon } from '../../assets/images/no-eye.svg';
import { ReactComponent as RemoveIcon } from '../../assets/images/remove.svg';
import exclamationPointRed from '../../assets/images/exclamation-point-red.svg';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { CavcoUserForgotPassword } from './CavcoUserForgotPassword';
import {
  CHAccountNav,
  CHAccountLinkName,
  CHAccountInfoForm,
  CHPasswordResetForm,
  CHAccountBanner,
  CHMyFavorites,
} from './components';
import { LoginStatus, loginSubject$ } from './LoginStatus';

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

// Footer ----------------------------------------------------------------------

// function displayTermsAndConditions(props: BrProps, termsAndConditions: any) {
//  if (!termsAndConditions) return '';
//
//  return (
//    <div
//      className="cvc-footer-terms-and-conditions-link"
//      dangerouslySetInnerHTML={{ __html: props.page.rewriteLinks(termsAndConditions.value) }}
//    />
//  );
// }

export function FavoriteComponent(props: BrProps) {
  // console.log('FavoriteComponent(props : ', props);

  const {
    // myFavoriteFloorPlans,
    // myFavoriteRetailers,
    // myFavoriteDisplayHomes,
    myAccountConfig,
    usStates,
    caProvinces,
    // termsAndConditions1,
    // termsAndConditions2,
    // termsAndConditions3,
  } = props.component.getModels();

  const { name: pageVariant } = props.page.getChannelParameters();
  const isCavcoHomesVariant = useMemo(() => {
    return pageVariant === 'cavcohomes';
  }, [pageVariant]);

  const basePath = getBasePathFromUrl('my-account');

  const numberOfFloorPlans = 6;
  const maxNumFloorPlans = 100;
  const numberOfLocations = 6;
  const maxNumLocations = 100;
  const numberOfDisplayHomes = 6;
  const maxNumDisplayHomes = 100;

  const myFavoritesClicked = {
    isMyFavorites: true,
    isMyDisplayHomes: false,
    isMyFloorPlans: false,
    isMyLocations: false,
    isMySettings: false,
  };

  const myDisplayHomesClicked = {
    isMyFavorites: false,
    isMyDisplayHomes: true,
    isMyFloorPlans: false,
    isMyLocations: false,
    isMySettings: false,
  };

  const myFloorPlansClicked = {
    isMyFavorites: false,
    isMyDisplayHomes: false,
    isMyFloorPlans: true,
    isMyLocations: false,
    isMySettings: false,
  };

  const myLocationsClicked = {
    isMyFavorites: false,
    isMyDisplayHomes: false,
    isMyFloorPlans: false,
    isMyLocations: true,
    isMySettings: false,
  };

  const mySettingsClicked = {
    isMyFavorites: false,
    isMyDisplayHomes: false,
    isMyFloorPlans: false,
    isMyLocations: false,
    isMySettings: true,
  };

  const history = useHistory();

  const logOut = useCallback(() => {
    localStorage.clear();
    deleteAllCookie();
    history.push(basePath);
  }, [basePath, history]);

  const classes = useStyles();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const initSnackBarMessage = {
    message: '',
    severity: 'success' as Color,
  };

  const MESSAGE_SEVERITY = {
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    SUCCESS: 'success',
  };
  const [snackBarMessage, setSnackBarMessage] = useState(initSnackBarMessage);

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  function SnackBarComponent() {
    return !isCavcoHomesVariant ? (
      <div className={classes.root}>
        <Snackbar open={openSnackBar} autoHideDuration={3000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity={snackBarMessage.severity}>
            {snackBarMessage.message}
          </Alert>
        </Snackbar>
      </div>
    ) : (
      <CavcoSnackbar
        snackbarProps={{
          open: openSnackBar,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          autoHideDuration: 3000,
          onClose: handleCloseSnackBar,
        }}
        alertProps={{
          className: 'info-form-snackbar',
          severity: snackBarMessage.severity,
        }}
      >
        <span className="info-form-snackbar__text">{snackBarMessage.message}</span>
      </CavcoSnackbar>
    );
  }

  const [myFloorPlansUpToIndex, setMyFloorPlansUpToIndex] = useState(numberOfFloorPlans);
  const [myLocationsUpToIndex, setMyLocationsUpToIndex] = useState(numberOfLocations);
  const [myDisplayHomessUpToIndex, setMyDisplayHomesUpToIndex] = useState(numberOfDisplayHomes);
  const [currentLinkState, setCurrentLinkState] = useState(myFavoritesClicked);

  const currentLinkName = useCallback((): CHAccountLinkName => {
    return currentLinkState.isMySettings ? 'settings' : 'favorites';
  }, [currentLinkState.isMySettings]);

  const [confirmRemoveItemModal, setConfirmRemoveItemModal] = useState(false);
  const closeConfirmRemoveItemModal = () => {
    setConfirmRemoveItemModal(false);
  };
  const [floorPlanLocationToBeDeleted, setFloorPlanLocationToBeDeleted] = useState(null);
  const [favoriteTypeSelected, setFavoriteTypeSelected] = useState('');

  const handleMyFavoritesClicked = useCallback(() => {
    setCurrentLinkState(myFavoritesClicked);
    setMyFloorPlansUpToIndex(numberOfFloorPlans);
    setMyLocationsUpToIndex(numberOfLocations);
    setMyDisplayHomesUpToIndex(numberOfDisplayHomes);
    window.history.replaceState(
      null,
      'My Favorites',
      `${basePath}${getMyAccountPath('my-account')}`
    );
  }, [
    basePath,
    myFavoritesClicked,
    numberOfFloorPlans,
    numberOfLocations,
    numberOfDisplayHomes,
    setCurrentLinkState,
    setMyFloorPlansUpToIndex,
    setMyLocationsUpToIndex,
    setMyDisplayHomesUpToIndex,
  ]);

  const handleMyDisplayHomesClicked = useCallback(() => {
    setCurrentLinkState(myDisplayHomesClicked);
    setMyDisplayHomesUpToIndex(maxNumDisplayHomes);
    window.history.replaceState(
      null,
      'Settings',
      `${basePath}${getMyAccountPath('my-account?display-homes')}`
    );
  }, [
    myDisplayHomesClicked,
    maxNumDisplayHomes,
    setCurrentLinkState,
    setMyDisplayHomesUpToIndex,
    basePath,
  ]);

  const handleMyFloorPlansClicked = useCallback(() => {
    setCurrentLinkState(myFloorPlansClicked);
    setMyFloorPlansUpToIndex(maxNumFloorPlans);
    window.history.replaceState(
      null,
      'My Floor Plans',
      `${basePath}${getMyAccountPath('my-account?floor-plans')}`
    );
  }, [
    myFloorPlansClicked,
    maxNumFloorPlans,
    setCurrentLinkState,
    setMyFloorPlansUpToIndex,
    basePath,
  ]);

  const handleMyLocationsClicked = useCallback(() => {
    setCurrentLinkState(myLocationsClicked);
    setMyLocationsUpToIndex(maxNumLocations);
    window.history.replaceState(
      null,
      'Settings',
      `${basePath}${getMyAccountPath('my-account?locations')}`
    );
  }, [myLocationsClicked, maxNumLocations, setCurrentLinkState, setMyLocationsUpToIndex, basePath]);

  const handleMySettingsClicked = useCallback(() => {
    setCurrentLinkState(mySettingsClicked);
    window.history.replaceState(
      null,
      'Settings',
      `${basePath}${getMyAccountPath('my-account?settings')}`
    );
  }, [mySettingsClicked, setCurrentLinkState, basePath]);

  const handleNavLinkClick = (linkName: CHAccountLinkName) => {
    switch (linkName) {
      case 'favorites':
        handleMyFavoritesClicked();
        break;
      case 'settings':
        handleMySettingsClicked();
        break;
      default:
        break;
    }
  };

  const { showDisplayHomes } = myAccountConfig || false;

  const userId = getCookie('cavcouserid');
  const cavcoUserInfo = getCavcoUserInfo();
  const [myFavoriteFloorPlansState, setMyFavoriteFloorPlansState] = useState([]);
  const [myFavoriteRetailersState, setMyFavoriteRetailersState] = useState([]);
  const [myFavoriteDisplayHomesState, setMyFavoriteDisplayHomesState] = useState([]);
  const [myFavoriteMirState, setMyFavoriteMirState] = useState([]);

  const fetchData = useCallback(async () => {
    const loadMyFavoriteCardsUrl = myAccountConfig?.loadMyFavoriteCardsUrl;
    setIsFetching(true);
    try {
      const response = await fetch(loadMyFavoriteCardsUrl, { credentials: 'include' });
      const data = await response.json();
      if (data?.status === 400) {
        console.log('400!! responseData:' + data); // eslint-disable-line
      } else {
        localStorage.setItem(`cavcoMyFavorites-${userId}`, JSON.stringify(data));
        // setCavcoUserFavorites(data);
        const { myFavoriteFloorPlans, myFavoriteRetailers, myFavoriteDisplayHomes } = data;
        setMyFavoriteFloorPlansState(myFavoriteFloorPlans);
        setMyFavoriteRetailersState(myFavoriteRetailers);
        setMyFavoriteDisplayHomesState(myFavoriteDisplayHomes);
        setMyFavoriteMirState(data?.myFavoriteMoveInReadyHomes || []);
      }
      setIsFetching(false);
    } catch (error: any) {
      console.log('error from the server:', error.message); // eslint-disable-line
      setIsFetching(false);
    }
  }, [myAccountConfig, userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const checkParam = useCallback(() => {
    const param = window.location.search;
    if (param) {
      if (param.includes('?floor-plans')) {
        handleMyFloorPlansClicked();
      } else if (param.includes('?locations')) {
        handleMyLocationsClicked();
      } else if (param.includes('?settings')) {
        handleMySettingsClicked();
      } else if (param.includes('?display-homes')) {
        handleMyDisplayHomesClicked();
      } else {
        handleMyFavoritesClicked();
      }
    }
  }, [
    handleMyDisplayHomesClicked,
    handleMyFloorPlansClicked,
    handleMyLocationsClicked,
    handleMySettingsClicked,
    handleMyFavoritesClicked,
  ]);

  useEffect(() => {
    if (!userId) {
      logOut();
      loginSubject$.next(LoginStatus.loggedOut);
    } else {
      checkParam();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   setMyFavoriteFloorPlansState(myFavoriteFloorPlans)
  //   setMyFavoriteRetailersState(myFavoriteRetailers)
  //   setMyFavoriteDisplayHomesState(myFavoriteDisplayHomes)
  // }, [myFavoriteFloorPlans, myFavoriteRetailers, myFavoriteDisplayHomes]);

  const windowDimensions = useWindowDimensions();

  let stateOptions = { ...usStates, ...caProvinces };
  stateOptions = Object.entries(stateOptions);
  stateOptions.sort();

  //   console.log('stateOptions : ', stateOptions)

  //   My Locations -----------------------------------------------------------------------------------------------

  const LocationLabel = (favoriteRetailer: any) => {
    return (
      <span>
        {favoriteRetailer?.parkModelRetailer && (
          <span className="location-label location-label-park">Park Model Retailer</span>
        )}
        {favoriteRetailer?.community && (
          <span className="location-label location-label-community">Community</span>
        )}

        {favoriteRetailer?.retailer && (
          <span className="location-label location-label-retailer">Retailer</span>
        )}
        {favoriteRetailer?.parkModelCommunity && (
          <span className="location-labellocation-label-park-community">Park Model Community</span>
        )}
      </span>
    );
  };

  const removeFloorPlanLocationFromArray = (floorplanLocation: any) => {
    if (favoriteTypeSelected === 'our_homes') {
      const indx = myFavoriteFloorPlansState.findIndex(
        (floorplan: any) => floorplan.assetID === floorplanLocation?.assetID
      );
      const newFavoriteFloorPlansState = [...myFavoriteFloorPlansState];

      newFavoriteFloorPlansState.splice(indx, indx >= 0 ? 1 : 0);
      setMyFavoriteFloorPlansState(newFavoriteFloorPlansState);
    } else if (favoriteTypeSelected === 'display_homes') {
      const indx = myFavoriteDisplayHomesState.findIndex(
        (displayHome: any) => displayHome.assetID === floorplanLocation?.assetID
      );
      const newFavoriteDisplayHomeState = [...myFavoriteDisplayHomesState];
      newFavoriteDisplayHomeState.splice(indx, indx >= 0 ? 1 : 0);
      setMyFavoriteDisplayHomesState(newFavoriteDisplayHomeState);
    } else if (favoriteTypeSelected === 'move_in_ready_homes') {
      const indx = myFavoriteMirState.findIndex(
        (mir: any) => mir.assetID === floorplanLocation?.assetID
      );
      const newFavoriteMirState = [...myFavoriteMirState];
      newFavoriteMirState.splice(indx, indx >= 0 ? 1 : 0);
      setMyFavoriteMirState(newFavoriteMirState);
    } else {
      const indx = myFavoriteRetailersState.findIndex(
        (location: any) => location.locationID === floorplanLocation?.locationID
      );
      const newFavoriteRetailerState = [...myFavoriteRetailersState];
      newFavoriteRetailerState.splice(indx, indx >= 0 ? 1 : 0);
      setMyFavoriteRetailersState(newFavoriteRetailerState);
    }
  };

  const postRequest = async (floorplanLocation: any) => {
    const baseUrl = myAccountConfig?.favoriteBaseUrl;
    const type = favoriteTypeSelected;
    const isRetailerSelected = favoriteTypeSelected === 'our_retailers';

    const id = isRetailerSelected ? floorplanLocation?.locationID : floorplanLocation?.assetID;
    const favorite = floorplanLocation?.isFavorite;

    const websiteUrlParam =
      isCavcoHomesVariant && !isRetailerSelected ? `&websiteUrl=${floorplanLocation?.url}` : '';

    const favoriteUrl = `${baseUrl}/${type}/${id}/${!favorite}?${websiteUrlParam}`;
    if (!isCavcoHomesVariant) {
      removeFloorPlanLocationFromArray(floorplanLocation);
    }
    const message = `floorplan ${floorplanLocation?.modelName} ${floorplanLocation?.modelNumber}`;
    setIsFetching(true);
    fetch(favoriteUrl, { credentials: 'include' })
      .then((response) => response.json())
      .then((success) => {
        if (!success) {
          const severity = !isCavcoHomesVariant
            ? (MESSAGE_SEVERITY.WARNING as Color)
            : (MESSAGE_SEVERITY.ERROR as Color);
          const snackBarMessageTemp = {
            message: `Failed to remove ${message}`,
            severity,
          };
          setSnackBarMessage(snackBarMessageTemp);
          handleOpenSnackBar();
          setIsFetching(false);
        } else {
          if (isCavcoHomesVariant) {
            removeFloorPlanLocationFromArray(floorplanLocation);
          }
          initAllFavoritesForCurrentUser(myAccountConfig?.cavcoFavoriteUrl);
          setIsFetching(false);
        }
      })
      .catch((error) => {
        console.log('error from the server:', error.message); // eslint-disable-line
        setIsFetching(false);
      });
  };

  const removeLocationDetail = (location: any) => {
    setFavoriteTypeSelected('our_retailers');
    setFloorPlanLocationToBeDeleted(location);
    setConfirmRemoveItemModal(true);
  };

  const approvedLocationRemoval = () => {
    setConfirmRemoveItemModal(false);
    postRequest(floorPlanLocationToBeDeleted);
  };

  const MapFavoriteRetailerRowContent = (favoriteRetailer: any, index: number) => {
    return (
      <div className="cvc-my-account-fav-row" key={`retailer-row-${index.toString()}`}>
        <div className="cvc-my-account-fav-row-star">&#9733;</div>
        <div className="cvc-my-account-fav-row-text">
          {favoriteRetailer?.linkedToCavcohomes ? (
            <Link
              key={favoriteRetailer?.cavcohomesRetailerLink}
              to={{ pathname: favoriteRetailer?.cavcohomesRetailerLink }}
              target="_blank"
            >
              {favoriteRetailer?.locationName}
            </Link>
          ) : (
            <Link
              key={favoriteRetailer?.url ? favoriteRetailer?.url : '/'}
              to={favoriteRetailer?.url ? favoriteRetailer?.url : '/'}
            >
              {favoriteRetailer?.locationName}
            </Link>
          )}
          {'  '}
          in {favoriteRetailer?.city}, {favoriteRetailer?.state}
          {'  '}
          {LocationLabel(favoriteRetailer)}
        </div>
        <div className="cvc-my-account-fav-row-util">
          <RemoveIcon
            onClick={() => removeLocationDetail(favoriteRetailer)}
            className="cvc-my-account-fav-row-remove"
          />
        </div>
      </div>
    );
  };

  const MapFavoriteRetailerRow = (favoriteRetailer: any, index: number) => (
    <>{MapFavoriteRetailerRowContent(favoriteRetailer, index)}</>
  );

  const MapFavoriteRetailerRows = () => {
    return (
      myFavoriteRetailersState &&
      myFavoriteRetailersState
        .slice(0, myLocationsUpToIndex)
        .map((favoriteRetailer: any, index: number) => {
          return MapFavoriteRetailerRow(favoriteRetailer, index);
        })
    );
  };

  const MyLocations = () => {
    return (
      <>
        {!currentLinkState.isMyFavorites && !windowDimensions.isMobile && (
          <h2 className="cvc-my-account-fav-title">My Locations</h2>
        )}
        <h3 className="cvc-my-account-fav-section-title">
          {myFavoriteRetailersState ? myFavoriteRetailersState.length : '0'} Saved Locations
        </h3>
        <div className="cvc-my-account-fav-rows">{MapFavoriteRetailerRows()}</div>
        {myLocationsUpToIndex === numberOfLocations && (
          <Button variant="bar" onClick={handleMyLocationsClicked}>
            View All &gt;{' '}
          </Button>
        )}
      </>
    );
  };

  // Move-in-ready Homes
  const removeMirDetail = (mir: any) => {
    setFavoriteTypeSelected('move_in_ready_homes');
    setFloorPlanLocationToBeDeleted(mir);
    setConfirmRemoveItemModal(true);
  };

  // My floor Plans -----------------------------------------------------------------------------

  const approvedFloorPlanRemoval = () => {
    setConfirmRemoveItemModal(false);
    postRequest(floorPlanLocationToBeDeleted);
  };

  const removeFloorPlanDetail = (floorplan: any) => {
    setFavoriteTypeSelected('our_homes');
    setFloorPlanLocationToBeDeleted(floorplan);
    setConfirmRemoveItemModal(true);
  };

  const MapFloorPlanToCard = (floorplan: any, index: number) => (
    <div className="cvc-my-account-fav-card" key={`MapFloorPlanToCard-${index}`}>
      <FloorPlanCard
        key={`floorplan-card-${index.toString()}`}
        floorPlanDocumentData={floorplan}
        windowDimensions={windowDimensions}
        props={props}
        hideHeart
      />
      <div className="cvc-my-account-fav-card-where">
        <div className="cvc-my-account-fav-card-where-text">
          {floorplan.savedAtLocationName &&
          floorplan.savedAtLocationCity &&
          floorplan.savedAtLocationState ? (
            <span>
              Saved at{' '}
              <a
                href={floorplan.url
                  .split('/')
                  .splice(0, floorplan.url.split('/').length - 2)
                  .join('/')}
              >
                {floorplan.savedAtLocationName}
              </a>{' '}
              in {floorplan.savedAtLocationCity}, {floorplan.savedAtLocationState}
            </span>
          ) : (
            <a href={floorplan.url.split('/').join('/')}>Where to Buy?</a>
          )}
        </div>
        <div className="cvc-my-account-fav-card-where-util">
          <RemoveIcon
            onClick={() => removeFloorPlanDetail(floorplan)}
            className="cvc-my-account-fav-card-remove"
          />
        </div>
      </div>
    </div>
  );

  const MapFloorPlanToCards = () => {
    return (
      myFavoriteFloorPlansState &&
      myFavoriteFloorPlansState
        .slice(0, myFloorPlansUpToIndex)
        .map((floorplan: any, index: number) => {
          return MapFloorPlanToCard(floorplan, index);
        })
    );
  };

  const MyFloorPlansTitle = () => {
    if (currentLinkState.isMyFavorites) {
      return (
        <>
          {showDisplayHomes !== 'true' && (
            <h2 className="cvc-my-account-fav-title">My Favorites</h2>
          )}
        </>
      );
    }
    return <h2 className="cvc-my-account-fav-title">My Floor Plans</h2>;
  };

  const MyFloorPlans = () => {
    return (
      <>
        {!windowDimensions.isMobile && MyFloorPlansTitle()}
        <h3 className="cvc-my-account-fav-section-title">
          {myFavoriteFloorPlansState ? myFavoriteFloorPlansState.length : '0'} Saved Floor Plans
        </h3>
        <div className="cvc-my-account-fav-cards">{MapFloorPlanToCards()}</div>
        {myFloorPlansUpToIndex === numberOfFloorPlans && (
          <Button variant="bar" onClick={handleMyFloorPlansClicked}>
            View All &gt;{' '}
          </Button>
        )}
      </>
    );
  };

  const getBannerGreetingText = (firstName = '') => {
    const welcomeText = 'Welcome back';
    return firstName ? `${welcomeText}, ${firstName}.` : welcomeText;
  };
  // My Display Homes --------------------------------------------------------------------------

  const approvedDisplayHomeRemoval = () => {
    setConfirmRemoveItemModal(false);
    postRequest(floorPlanLocationToBeDeleted);
  };

  const removeFavoriteItem = () => {
    if (favoriteTypeSelected === 'our_homes') {
      approvedFloorPlanRemoval();
    } else if (favoriteTypeSelected === 'display_homes') {
      approvedDisplayHomeRemoval();
    } else if (favoriteTypeSelected === 'move_in_ready_homes') {
      approvedFloorPlanRemoval();
    } else {
      approvedLocationRemoval();
    }
  };

  const removeDisplayHomeDetail = (displayHome: any) => {
    setFavoriteTypeSelected('display_homes');
    setFloorPlanLocationToBeDeleted(displayHome);
    setConfirmRemoveItemModal(true);
  };

  const MapDisplayHomeToCard = (displayHome: any, index: number) => (
    <div className="cvc-my-account-fav-card">
      <DisplayHomeCard
        key={`display-home-card-${index.toString()}`}
        displayHomeDocumentData={displayHome}
        windowDimensions={windowDimensions}
        props={props}
        hideHeart
      />
      <div className="cvc-my-account-fav-card-where">
        <div className="cvc-my-account-fav-card-where-text">
          <span>
            Saved at <a href={`.${displayHome.url}`}>{displayHome.savedAtLocationName}</a> in{' '}
            {displayHome.savedAtLocationCity}, {displayHome.savedAtLocationState}
          </span>
        </div>
        <div className="cvc-my-account-fav-card-where-util">
          <RemoveIcon
            onClick={() => removeDisplayHomeDetail(displayHome)}
            className="cvc-my-account-fav-card-remove"
          />
        </div>
      </div>
    </div>
  );

  const MapDisplayHomeToCards = () => {
    if (myFavoriteDisplayHomesState) {
      return myFavoriteDisplayHomesState
        .slice(0, myDisplayHomessUpToIndex)
        .map((displayHome: any, index: number) => {
          return MapDisplayHomeToCard(displayHome, index);
        });
    }
    return null;
  };

  const MyDisplayHomes = () => {
    return (
      <>
        {!windowDimensions.isMobile && (
          <h2 className="cvc-my-account-fav-title">
            {currentLinkState.isMyFavorites ? 'My Favorites' : 'My Display Models'}
          </h2>
        )}
        <h3 className="cvc-my-account-fav-section-title">
          {myFavoriteDisplayHomesState?.length} Saved Display Models
        </h3>
        <div className="cvc-my-account-fav-cards">{MapDisplayHomeToCards()}</div>
        {myDisplayHomessUpToIndex === numberOfDisplayHomes && (
          <Button variant="bar" onClick={handleMyDisplayHomesClicked}>
            View All &gt;{' '}
          </Button>
        )}
      </>
    );
  };

  // Main returned body -----------------------------------------------------------

  return (
    <div className="cvc-my-account">
      <div className="cvc-my-account-top">
        <h1 className="cvc-my-account-top-title">
          <span className="cvc-my-account-top-title-site">
            My {getCurrentSiteNameDisplay()} Account{' '}
          </span>
          <span className="cvc-my-account-top-title-section">
            {currentLinkState.isMyFavorites && 'My Favorites'}
            {currentLinkState.isMyDisplayHomes && 'My Display Models'}
            {currentLinkState.isMyFloorPlans && 'My Floor Plans'}
            {currentLinkState.isMyLocations && 'My Locations'}
            {currentLinkState.isMySettings && 'My Settings'}
          </span>
        </h1>
        <div className="cvc-my-account-top-util">
          <Nav.Link className="cvc-my-account-top-util-x" as={Link} key={basePath} to={basePath}>
            &#xd7;
          </Nav.Link>
        </div>
      </div>
      <div className="cvc-my-account-main">
        {isCavcoHomesVariant && (
          <CHAccountBanner greetingText={getBannerGreetingText(cavcoUserInfo?.firstName)} />
        )}
        {isCavcoHomesVariant ? (
          <CHAccountNav handleNavItemClick={handleNavLinkClick} activeLink={currentLinkName()} />
        ) : (
          <div className="cvc-my-account-nav">
            <List>
              <ListItem
                button
                className={!currentLinkState.isMyFavorites ? '' : 'current'}
                onClick={handleMyFavoritesClicked}
              >
                My Favorites
              </ListItem>
              <List>
                {showDisplayHomes === 'true' && (
                  <ListItem
                    button
                    className={!currentLinkState.isMyDisplayHomes ? '' : 'current'}
                    onClick={handleMyDisplayHomesClicked}
                  >
                    My Display Models
                  </ListItem>
                )}
                <ListItem
                  button
                  className={!currentLinkState.isMyFloorPlans ? '' : 'current'}
                  onClick={handleMyFloorPlansClicked}
                >
                  My Floor Plans
                </ListItem>
                <ListItem
                  button
                  className={!currentLinkState.isMyLocations ? '' : 'current'}
                  onClick={handleMyLocationsClicked}
                >
                  My Locations
                </ListItem>
              </List>
              <ListItem
                button
                className={!currentLinkState.isMySettings ? '' : 'current'}
                onClick={handleMySettingsClicked}
              >
                My Settings
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  logOut();
                }}
              >
                <Link key={basePath} to={basePath}>
                  Log Out
                </Link>
              </ListItem>
            </List>
          </div>
        )}
        <div className="cvc-my-account-content">
          <SnackBarComponent />
          {isCavcoHomesVariant ? (
            <>
              {currentLinkState.isMyFavorites && (
                <CHMyFavorites
                  floorplans={myFavoriteFloorPlansState}
                  displayHomes={myFavoriteDisplayHomesState}
                  retailers={myFavoriteRetailersState}
                  mir={myFavoriteMirState}
                  isLoading={isFetching}
                  handleRemoveFloorplanClick={removeFloorPlanDetail}
                  handleRemoveRetailerClick={removeLocationDetail}
                  handleRemoveDisplayHomeClick={removeDisplayHomeDetail}
                  handleRemoveMirClick={removeMirDetail}
                  handleRemoveItem={removeFavoriteItem}
                  handleHideRemoveItemModal={closeConfirmRemoveItemModal}
                  showRemoveItemModal={confirmRemoveItemModal}
                />
              )}
              {currentLinkState.isMySettings && <MySetting {...props} />}
            </>
          ) : (
            <>
              {showDisplayHomes === 'true' &&
                (currentLinkState.isMyDisplayHomes || currentLinkState.isMyFavorites) && (
                  <MyDisplayHomes />
                )}
              {(currentLinkState.isMyFloorPlans || currentLinkState.isMyFavorites) && (
                <MyFloorPlans />
              )}
              {(currentLinkState.isMyLocations || currentLinkState.isMyFavorites) && (
                <MyLocations />
              )}
              {currentLinkState.isMySettings && <MySetting {...props} />}
            </>
          )}
        </div>
      </div>
      {!isCavcoHomesVariant && (
        <div className="cvc-my-account-footer">
          {/* termsAndConditions1 && displayTermsAndConditions(props, termsAndConditions1) */}
          {/* termsAndConditions2 && displayTermsAndConditions(props, termsAndConditions2) */}
          {/* termsAndConditions3 && displayTermsAndConditions(props, termsAndConditions3) */}
          <span>Copyright &copy; </span>
          <a rel="noopener noreferrer" href="https://www.cavco.com/" target="_blank">
            Cavco Industries, Inc.
          </a>
          <span> All rights reserved. | </span>
          <a rel="noopener noreferrer" href="https://www.cavco.com/privacy/" target="_blank">
            Important Legal Notices
          </a>
          <span> | </span>
          <a
            rel="noopener noreferrer"
            href="https://www.cavco.com/do-not-sell-my-personal-information/"
            target="_blank"
          >
            Privacy Policy
          </a>
        </div>
      )}
      {!isCavcoHomesVariant && (
        <Modal
          // className="cvc-request-info-modal-retailer"
          show={confirmRemoveItemModal}
          onHide={closeConfirmRemoveItemModal}
          backdrop={windowDimensions.isMobile || windowDimensions.isTablet ? 'static' : true}
          keyboard={!(windowDimensions.isMobile || windowDimensions.isTablet)}
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <Container>
              <Row className="h5 mb-3 mb-md-5 d-flex justify-content-center">
                <strong>Are you sure you want to remove this item?</strong>
              </Row>
              <Row className="d-flex justify-content-center mb-4 mx-3">
                <Button
                  type="button"
                  className="px-5 py-2 m-2 login-button"
                  variant="secondary"
                  onClick={() => closeConfirmRemoveItemModal()}
                >
                  Cancel
                </Button>

                <Button
                  type="button"
                  className="px-5 py-2 m-2 login-button"
                  onClick={() => {
                    if (favoriteTypeSelected === 'our_homes') {
                      approvedFloorPlanRemoval();
                    } else if (favoriteTypeSelected === 'display_homes') {
                      approvedDisplayHomeRemoval();
                    } else {
                      approvedLocationRemoval();
                    }
                  }}
                >
                  Remove
                </Button>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

const MySetting = (props: any) => {
  const cavcoUserInfoFromLocalStorage = getCavcoUserInfo();
  const { name: pageVariant } = props.page.getChannelParameters();
  const isCavcoHomesVariant = pageVariant === 'cavcohomes';

  // const [cavcoUserInfoFromLocalStorage] = useStateWithLocalStorage('cavcoUserInfo');
  const is3rdParty = !!(
    cavcoUserInfoFromLocalStorage &&
    (cavcoUserInfoFromLocalStorage.googleId || cavcoUserInfoFromLocalStorage.facebookId)
  );
  const windowDimensions = useWindowDimensions();

  return (
    <span className="mb-5 pb-5">
      {(!windowDimensions.isMobile || isCavcoHomesVariant) && (
        <h2 className="cvc-my-account-fav-title">My Settings</h2>
      )}
      <AccountInformation {...props} />
      <br />
      <br />
      {is3rdParty ? '' : <ChangePassword {...props} />}
    </span>
  );
};
export interface AccountInfoPostValues {
  firstName: string;
  lastName: string;
  address1: string;
  city: string;
  state: string;
  zip: string;
  cellPhone: string;
  emailAddress: string;
}
interface AccountInfoValues extends AccountInfoPostValues {
  phone1: string;
  phone2: string;
  phone3: string;
}
const AccountInformation = (props: any) => {
  const [submittingAccountInfo, setSubmittingAccountInfo] = useState(false);
  const { myAccountConfig, usStates, caProvinces } = props.component.getModels();
  const [showModal, setShowModal] = useState(false);
  const [errorSavingAccountInfo, setErrorSavingAccountInfo] = useState(false);
  const [requiredFieldsEmpty, setRequiredFieldsEmpty] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState<any>(false);
  const [snackbarContent, setSnackbarContent] = useState<any | null>(undefined);
  const cavcoUserInfo = getCavcoUserInfo();
  const { name: pageVariant } = props.page.getChannelParameters();
  const isCavcoHomesVariant = pageVariant === 'cavcohomes';

  const handleOpenSnackbar = ({
    isError = false,
    message = '',
  }: {
    isError: boolean;
    message?: string;
  }) => {
    const data = isError
      ? {
          title: 'Error',
          text: message,
          isError,
        }
      : { title: 'Success', text: 'Your account has been successfully updated', isError };

    setSnackbarContent(data);
  };

  const handleCloseSnackbar = () => {
    setSnackbarContent(null);
  };

  const setCavcoUserInfo = (values: AccountInfoValues) => {
    cavcoUserInfo.firstName = values?.firstName;
    cavcoUserInfo.lastName = values?.lastName;
    cavcoUserInfo.address1 = values?.address1;
    cavcoUserInfo.city = values?.city;
    cavcoUserInfo.state = values?.state;
    cavcoUserInfo.zip = values?.zip;
    cavcoUserInfo.cellPhone = values?.cellPhone;
    cavcoUserInfo.emailAddress = values?.emailAddress;

    localStorage.setItem('cavcoUserInfo', JSON.stringify(cavcoUserInfo));

    return cavcoUserInfo;
  };
  const postAccountInfo = (values: AccountInfoValues) => {
    if (!cavcoUserInfo.uid) return;

    if (!values.firstName || !values.lastName || !values.emailAddress) {
      setRequiredFieldsEmpty(true);
      return;
    }
    setSubmittingAccountInfo(true);

    const userIdLoc: string = cavcoUserInfo.userId || cavcoUserInfo.uid.toString();
    const postValues = {
      uid: cavcoUserInfo.uid,
      userId: userIdLoc,
      emailaddress: values?.emailAddress,
      firstname: values?.firstName,
      lastname: values?.lastName,
      address1: values?.address1,
      phone: values?.cellPhone,
      city: values?.city,
      state: values?.state,
      zip: values?.zip,
      googleId: cavcoUserInfo.googleId,
      facebookId: cavcoUserInfo.facebookId,
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postValues),
    };
    fetch(myAccountConfig.cavcoUserUpdateUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setSubmittingAccountInfo(false);
        setErrorSavingAccountInfo(false);
        setServerErrorMessage('');
        if (data.status === '400') {
          setErrorSavingAccountInfo(true);
          setServerErrorMessage(`Validation error occurred, please try again. ${data.message}`);
          if (data.message.startsWith('firstName must not exceed')) {
            setServerErrorMessage('First Name must not exceed 50 characters');
          }
          if (data.message.startsWith('lastName must not exceed')) {
            setServerErrorMessage('Last Name must not exceed 50 characters');
          }
          console.log('400!! responseData:' + data); // eslint-disable-line
          handleOpenSnackbar({ isError: true, message: data.message });
        } else {
          setShowModal(true);
          handleOpenSnackbar({ isError: false });
          setCavcoUserInfo(data);
        }
      })
      .catch((error) => {
        console.log('error from the server:', error.message); // eslint-disable-line
        setSubmittingAccountInfo(false);
        handleOpenSnackbar({ isError: true, message: error.message });
        setErrorSavingAccountInfo(true);
      });
  };

  let stateOptions = { ...usStates, ...caProvinces };
  stateOptions = Object.entries(stateOptions);
  stateOptions.sort();
  return !isCavcoHomesVariant ? (
    <div className="py-4">
      <p className="h4 fw-bold pb-3">
        <strong>Account Information</strong>
      </p>
      <Formik
        initialValues={{
          firstName: cavcoUserInfo?.firstName,
          lastName: cavcoUserInfo?.lastName,
          address1: cavcoUserInfo?.address1,
          city: cavcoUserInfo?.city,
          state: cavcoUserInfo?.state,
          zip: cavcoUserInfo?.zip,
          phone: cavcoUserInfo?.cellPhone,
          phone1: cavcoUserInfo?.cellPhone?.substring(0, 3),
          phone2: cavcoUserInfo?.cellPhone?.substring(3, 6),
          phone3: cavcoUserInfo?.cellPhone?.substring(6),
          emailAddress: cavcoUserInfo?.emailAddress,
        }}
        onSubmit={(values, actions) => {
          // values.phone = values.phone1 + values.phone2 + values.phone3;
          postAccountInfo({ ...values, cellPhone: values.phone1 + values.phone2 + values.phone3 });
          actions.setSubmitting(false);
          actions.resetForm({ values: { ...values } });
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <Form className="px-3" onSubmit={handleSubmit}>
            {(requiredFieldsEmpty || errorSavingAccountInfo) && (
              <Row className="pb-5">
                <Col xs={2}>
                  <img
                    src={exclamationPointRed}
                    title="exclamation-point-red"
                    alt="exclamation-point-red"
                  />
                </Col>
                <Col className="mt-1">
                  {serverErrorMessage && <p>{serverErrorMessage}</p>}
                  {errorSavingAccountInfo && !serverErrorMessage && (
                    <p>Error occurred, Please try again.</p>
                  )}
                  {!values.firstName && <p>First Name is required</p>}
                  {!values.lastName && <p>Last Name is required</p>}
                  {!values.emailAddress && <p>Email Address is required</p>}
                </Col>
              </Row>
            )}

            <Row>
              <Form.Group as={Col} md="6" className="mb-3 " controlId="firstName">
                <Form.Label>First Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>
              {requiredFieldsEmpty && <div />}

              <Form.Group as={Col} md="6" className="mb-3 " controlId="lastName">
                <Form.Label>Last Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="12" className="mb-3 " controlId="address1">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address1"
                  value={values.address1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} md="6" className="mb-3 " controlId="city">
                {/* <Form.Label>City</Form.Label> */}
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="City"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" className="mb-3 " controlId="state">
                <Form.Control as="select" name="state" value={values.state} onChange={handleChange}>
                  <option value="">State/Province </option>
                  {stateOptions.map((option: any) => {
                    return (
                      <option key={option[0]} value={option[0]}>
                        {option[0]}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="3" className="mb-3 " controlId="zip">
                {/* <Form.Label>Last Name</Form.Label> */}
                <Form.Control
                  type="text"
                  name="zip"
                  placeholder="Zip/Postal"
                  value={values.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>
            </Row>
            <Form.Label>Phone</Form.Label>
            <Row>
              <Col xs={2} style={{ minWidth: '100px' }}>
                <Form.Group className="mb-3 " controlId="phone1">
                  <Form.Control
                    type="text"
                    name="phone1"
                    value={values.phone1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={3}
                    pattern="[0-9]{3}"
                    aria-label="Phone area code"
                  />
                </Form.Group>
              </Col>
              -
              <Col xs={2} style={{ minWidth: '100px' }}>
                <Form.Group className="mb-3 " controlId="phone2">
                  <Form.Control
                    type="text"
                    name="phone2"
                    value={values.phone2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={3}
                    pattern="[0-9]{3}"
                    aria-label="Phone area code"
                  />
                </Form.Group>
              </Col>
              -
              <Col xs={3} style={{ minWidth: '100px' }}>
                <Form.Group className="mb-3 " controlId="phone3">
                  <Form.Control
                    type="text"
                    name="phone3"
                    value={values.phone3}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={4}
                    pattern="[0-9]{4}"
                    aria-label="Phone number"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group as={Col} md="6" className="mb-3 " controlId="emailAddress">
                <Form.Label>Email Address*</Form.Label>
                <Form.Control
                  type="email"
                  name="emailAddress"
                  value={values.emailAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>
            </Row>

            <Button
              disabled={submittingAccountInfo}
              type="submit"
              className="px-5 py-2 text-center"
            >
              {submittingAccountInfo && (
                <>
                  <Spinner animation="border" variant="light" className="mr-4" />
                </>
              )}
              {submittingAccountInfo ? '' : 'Save'}
            </Button>

            {/* <pre>{JSON.stringify(values,null,2)}</pre> */}
          </Form>
        )}
      </Formik>
      <Modal
        className="cvc-fp-request-info-modal text-center"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton />
        <Modal.Body className="mt-4 px-5">
          <h2>Thank you</h2>
          <p>Your account has been successfully updated</p>
          <button
            type="button"
            className="cvc-form-button btn btn-primary px-4"
            onClick={() => setShowModal(false)}
          >
            Ok
          </button>
        </Modal.Body>
      </Modal>
    </div>
  ) : (
    <>
      <CHAccountInfoForm
        onSubmit={postAccountInfo}
        accountInfo={{
          firstName: cavcoUserInfo?.firstName,
          lastName: cavcoUserInfo?.lastName,
          address1: cavcoUserInfo?.address1,
          city: cavcoUserInfo?.city,
          state: cavcoUserInfo?.state,
          zip: cavcoUserInfo?.zip,
          cellPhone: cavcoUserInfo?.cellPhone,
          emailAddress: cavcoUserInfo?.emailAddress,
        }}
        stateOptions={stateOptions}
      />
      <CavcoSnackbar
        snackbarProps={{
          open: !!snackbarContent,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          autoHideDuration: snackbarContent?.isError ? 6000 : 3000,
          onClose: handleCloseSnackbar,
        }}
        alertProps={{
          className: 'info-form-snackbar',
          severity: snackbarContent?.isError ? 'error' : 'success',
        }}
      >
        <span className="info-form-snackbar__title">{snackbarContent?.title}</span>
        <span className="info-form-snackbar__text">{snackbarContent?.text}</span>
      </CavcoSnackbar>
    </>
  );
};
export interface ChangePasswordValues {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}
const ChangePassword = (props: any) => {
  const [isShowPwd, setIsShowPwd] = useState(false);
  const [emailOrPasswordEmpty, setEmailOrPasswordEmpty] = useState(false);
  const [passwordAndConfirmPasswordMatch, setPasswordAndConfirmPasswordMatch] = useState(true);
  const [passwordRequirementMatch, setPasswordRequirementMatch] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorChangingPassword, setErrorChangingPassword] = useState(false);
  const [requiredPasswordsEmpty, setRequiredPasswordsEmpty] = useState(false);
  const [newPasswordsNotMatch, setNewPasswordsNotMatch] = useState(false);
  const [submittingChangePassword, setSubmittingChangePassword] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const { myAccountConfig } = props.component.getModels();
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const windowDimensions = useWindowDimensions();
  const [showModal, setShowModal] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState<any>(false);
  const [snackbarContent, setSnackbarContent] = useState<any | null>(undefined);
  const cavcoUserInfo = getCavcoUserInfo();
  const { name: pageVariant } = props.page.getChannelParameters();
  const isCavcoHomesVariant = pageVariant === 'cavcohomes';
  // const[c,setc] = useState(false);

  // const { showDisplayHomes } = myAccountConfig;
  const closeForgotPasswordModal = () => {
    setForgotPasswordModal(false);
  };
  const openForgotPasswordModal = () => setForgotPasswordModal(true);

  const handleOpenSnackbar = ({
    isError = false,
    message = '',
  }: {
    isError: boolean;
    message?: string;
  }) => {
    const data = isError
      ? {
          title: 'Error',
          text: message,
          isError,
        }
      : { title: 'Success', text: 'Your password has been successfully updated', isError };

    setSnackbarContent(data);
  };

  const handleCloseSnackbar = () => {
    setSnackbarContent(null);
    setServerErrorMessage('');
  };

  const postPasswordChange = (values: ChangePasswordValues) => {
    if (!values.currentPassword || !values.newPassword || !values.confirmNewPassword) {
      setRequiredPasswordsEmpty(true);
      return;
    }

    if (values.newPassword !== values.confirmNewPassword) {
      setNewPasswordsNotMatch(true);
      return;
    }
    // if(!values.newPassword.match(/(^.{8,25}$)/){
    // setClentMethod("Password length 8-25")
    // } else{
    //   setClientMethos(flase)
    // }
    setSubmittingChangePassword(true);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    };
    setServerErrorMessage(false);

    fetch(myAccountConfig.cavcoChangePasswordUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setSubmittingChangePassword(false);
        setErrorChangingPassword(false);
        if (data.status === 400) {
          if (data.message.startsWith('The Current Password is invalid')) {
            setServerErrorMessage('The Current Password is invalid');
          } else {
            setServerErrorMessage('The Current Password is invalid');
          }
          handleOpenSnackbar({ isError: true, message: 'The Current Password is invalid' });
          // setErrorChangingPassword(true);
        } else {
          setShowModal(true);
          setErrorMessage('');
          handleOpenSnackbar({ isError: false });
        }
        if (data.status !== 200 && data.status !== 400) {
          setServerErrorMessage(data.message);
        }
      })
      .catch((error) => {
        console.log('error from the server:', error.message); // eslint-disable-line
        setErrorOccurred(true);
        setSubmittingChangePassword(false);
        setErrorChangingPassword(true);
        handleOpenSnackbar({ isError: true, message: error.message });
      });
  };
  return (
    <div className="mb-5 pb-5">
      {!isCavcoHomesVariant ? (
        <>
          <p className="h4 fw-bold pb-3">
            <strong>Change Password</strong>
          </p>
          <Formik
            initialValues={{
              uid: cavcoUserInfo.uid,
              currentPassword: '',
              newPassword: '',
              confirmNewPassword: '',
            }}
            onSubmit={(values) => {
              if (!values.newPassword || !values.confirmNewPassword) {
                setEmailOrPasswordEmpty(true);
                setPasswordRequirementMatch(false);
                return;
              }
              setEmailOrPasswordEmpty(false);
              if (!values.newPassword.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).{8,25}$/)) {
                return;
              }
              setPasswordRequirementMatch(true);

              if (values.newPassword !== values.confirmNewPassword) {
                setPasswordAndConfirmPasswordMatch(false);
                return;
              }
              setPasswordAndConfirmPasswordMatch(true);
              postPasswordChange(values);
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form className="px-3" onSubmit={handleSubmit}>
                {(requiredPasswordsEmpty ||
                  errorChangingPassword ||
                  newPasswordsNotMatch ||
                  emailOrPasswordEmpty ||
                  serverErrorMessage) && (
                  <Row className="pb-5">
                    <Col xs={2}>
                      <img
                        src={exclamationPointRed}
                        title="exclamation-point-red"
                        alt="exclamation-point-red"
                      />
                    </Col>
                    <Col className="mt-1">
                      {errorChangingPassword && <p>Error occurred, Please try again.</p>}
                      {emailOrPasswordEmpty && <span>Password is required.</span>}
                      {requiredPasswordsEmpty && <p>All password inputs are required</p>}
                      {newPasswordsNotMatch && <p>New Password does not match.</p>}
                      {serverErrorMessage && <p>{serverErrorMessage}</p>}
                      {/* {} */}
                    </Col>
                  </Row>
                )}

                <Form.Group as={Col} md="6" className="mb-3" controlId="currentPassword">
                  <Form.Label>Current Password*</Form.Label>
                  <div className="right-inner-addon">
                    <Form.Control
                      type="password"
                      name="currentPassword"
                      value={values.currentPassword}
                      onChange={handleChange}
                    />
                  </div>
                </Form.Group>

                <Form.Group as={Col} md="6" className="mb-3" controlId="newPassword">
                  <Form.Label>New Password*</Form.Label>
                  <div className="right-inner-addon">
                    <Form.Control
                      type={isShowPwd ? 'text' : 'password'}
                      name="newPassword"
                      value={values.newPassword}
                      onChange={handleChange}
                      className={
                        // !passwordRequirementMatch ||
                        !passwordAndConfirmPasswordMatch ||
                        emailOrPasswordEmpty ||
                        !!errorMessage ||
                        errorOccurred
                          ? 'cvc-form-error'
                          : ''
                      }
                    />

                    <div // eslint-disable-line
                      className="right-inner-addon-btn"
                      onClick={() => setIsShowPwd((prevState) => !prevState)}
                    >
                      {isShowPwd ? <NoEyeIcon /> : <EyeIcon />}
                    </div>
                  </div>
                </Form.Group>
                <div>
                  <strong
                    className={
                      !passwordRequirementMatch &&
                      !values.newPassword.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).{8,25}$/)
                        ? 'cvc-form-error'
                        : ''
                    }
                  >
                    Your password must include:
                  </strong>
                  <div>
                    <ul>
                      <li
                        className={
                          !passwordRequirementMatch && !values.newPassword.match(/(^.{8,25}$)/)
                            ? 'cvc-form-error'
                            : ''
                        }
                      >
                        8-25 characters
                      </li>
                      <li
                        className={
                          !passwordRequirementMatch &&
                          !values.newPassword.match(/(?=.*[A-Z])(?=.*[a-z])/)
                            ? 'cvc-form-error'
                            : ''
                        }
                      >
                        Upper and lowercase letters
                      </li>
                      <li
                        className={
                          !passwordRequirementMatch && !values.newPassword.match(/\d/)
                            ? 'cvc-form-error'
                            : ''
                        }
                      >
                        At least a number
                      </li>
                      <li
                        className={
                          !passwordRequirementMatch && !values.newPassword.match(/\W/)
                            ? 'cvc-form-error'
                            : ''
                        }
                      >
                        At least a special character
                      </li>
                    </ul>
                  </div>
                </div>
                <Form.Group as={Col} md="6" className="mb-3" controlId="confirmNewPassword">
                  <Form.Label>Confirm New Password*</Form.Label>
                  <div className="right-inner-addon">
                    <Form.Control
                      type={isShowPwd ? 'text' : 'password'}
                      name="confirmNewPassword"
                      value={values.confirmNewPassword}
                      onChange={handleChange}
                    />
                    <div // eslint-disable-line
                      className="right-inner-addon-btn"
                      onClick={() => setIsShowPwd((prevState) => !prevState)}
                    >
                      {isShowPwd ? <NoEyeIcon /> : <EyeIcon />}
                    </div>
                  </div>
                </Form.Group>

                <Col>
                  <Row className="pl-3">
                    <small // eslint-disable-line
                      className="cvc-text-link-alt"
                      onClick={openForgotPasswordModal}
                    >
                      Forgot Your Password?
                    </small>
                  </Row>

                  <Button
                    // disabled={submittingUserLogin}
                    type="submit"
                    className="px-5 py-2 text-center"
                  >
                    {submittingChangePassword && (
                      <>
                        <Spinner animation="border" variant="light" className="mr-4" />
                      </>
                    )}
                    {submittingChangePassword ? '' : 'Change'}
                  </Button>
                </Col>

                {/* <pre>{JSON.stringify(values,null,2)}</pre> */}
              </Form>
            )}
          </Formik>
          <Modal
            className="cvc-fp-request-info-modal text-center"
            show={showModal}
            onHide={() => setShowModal(false)}
          >
            <Modal.Header closeButton />
            <Modal.Body className="mt-4 px-5">
              <h2>Thank you</h2>
              <p>Your password has been successfully updated</p>
              <button
                type="button"
                className="cvc-form-button btn btn-primary px-4"
                onClick={() => setShowModal(false)}
              >
                Ok
              </button>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          <CHPasswordResetForm
            handleForgotPassword={openForgotPasswordModal}
            onSubmit={postPasswordChange}
            uid={cavcoUserInfo.uid}
          />
          <CavcoSnackbar
            snackbarProps={{
              open: !!snackbarContent,
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
              autoHideDuration: snackbarContent?.isError ? 6000 : 3000,
              onClose: handleCloseSnackbar,
            }}
            alertProps={{
              className: 'info-form-snackbar',
              severity: snackbarContent?.isError ? 'error' : 'success',
            }}
          >
            <span className="info-form-snackbar__title">{snackbarContent?.title}</span>
            <span className="info-form-snackbar__text">{snackbarContent?.text}</span>
          </CavcoSnackbar>
        </>
      )}
      <Modal
        className="cvc-request-info-modal-retailer"
        show={forgotPasswordModal}
        onHide={closeForgotPasswordModal}
        backdrop={windowDimensions.isMobile || windowDimensions.isTablet ? 'static' : true}
        keyboard={!(windowDimensions.isMobile || windowDimensions.isTablet)}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Row>
            <Col>
              <CavcoUserForgotPassword
                cavcoUserLoginUrl={myAccountConfig?.cavcoForgetPassword}
                postLoginUrl={myAccountConfig?.postLoginUrl}
                closeMethod={closeForgotPasswordModal}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};
