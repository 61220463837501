/*
 *
 * This component creates the metadata for the Retailer Home page
 * This is a Document-backed page.
 * ex url: https://qa.cavco.bloomreach.cloud/site/our-retailers/us/wi/fond-du-lac/flood-homes
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrProps } from '@bloomreach/react-sdk';
import { GetFirstImgUrl } from '../FloorPlanBanner/FloorPlanBanner';
import { BuildCannonicalURL } from './CannonicalURL';

function buildMetaDescription(data: RetailerData) {
  const metaDescription = `${data.dba} is a mobile home dealer located in ${data.city}, ${data.state}. We are confident that we can provide the right ${data.city} manufactured or modular home to fit your family's wants and needs. View floor plans at cavcohomes.com.`;

  //  let metaDescription = `${data.dba} of ${data.city}, ${data.state} is proud to represent ${company}. Call today to find out how you can become another satisfied ${companyShort}`;
  //  metaDescription += data.parkModelRetailer ? 'park model owner.' : ' homeowner.';

  return metaDescription;
}
// [displayName], [company], [community | retailer], [city], [state]
function buildMetaKeywords(data: RetailerData, company: any) {
  let metaKeywords = `${data.dba}, ${company}${data.community ? ' community' : ' retailer'}`;
  metaKeywords = `${metaKeywords},${data.city},${data.state}`;
  return metaKeywords;
}

function buildMetaImage(props: BrProps, photos: any) {
  let ogImage = '';
  const photosDocument = photos && photos[0] && props.page.getContent(photos[0]);
  if (photosDocument) {
    const imgUrl = GetFirstImgUrl(props, photos[0]);
    if (imgUrl) ogImage = imgUrl;
  }
  return ogImage;
}

function buildCmsOgImage(props: BrProps, imageRef: any) {
  const image = imageRef && props.page.getContent(imageRef.imagelink);
  if (!image) return undefined;
  return image.getUrl();
}

function pagePropertiesForRtlData(props: BrProps, data: RetailerData, links: any) {
  const { metadata: cmsMetadata } = data;

  let pageTitle = `${data.dba}, ${data.city}, ${data.state} | Manufactured, Modular and Mobile Homes`;
  pageTitle = `${cmsMetadata?.pageTitle || pageTitle}`;
  const { componentParameterMap } = props.component.getModels();

  const canonicalUrl = cmsMetadata?.canonicalUrl || BuildCannonicalURL(links, false);
  const metaDescription = cmsMetadata?.metaDescription || buildMetaDescription(data);
  const metaKeywords =
    cmsMetadata?.metaKeywords || buildMetaKeywords(data, componentParameterMap.companyFull);
  const ogTitle = cmsMetadata?.ogTitle || pageTitle;
  const ogImage =
    buildCmsOgImage(props, cmsMetadata?.socialImage) || buildMetaImage(props, data.photos);
  const imageAlt = cmsMetadata?.socialImage?.alt || '';
  const ogDescription = cmsMetadata?.ogDescription || metaDescription;
  const indexRobot = cmsMetadata && !cmsMetadata.index ? 'noindex' : 'index';
  const followRobot = cmsMetadata && !cmsMetadata.follow ? 'nofollow' : 'follow';
  const robots = [indexRobot, followRobot];
  const robotsTag = robots && <meta name="robots" content={robots.join(',')} />;
  return (
    <div>
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogTitle && <meta name="twitter:title" content={ogTitle} />}
        {ogImage && <meta property="og:image" content={`${ogImage}`} />}
        {ogImage && <meta className="elastic" name="search_image" content={`${ogImage}`} />}
        {data.address1 && <meta className="elastic" name="address" content={`${data.address1}`} />}
        {data.city && <meta className="elastic" name="city" content={`${data.city}`} />}
        {data.state && <meta className="elastic" name="state" content={`${data.state}`} />}
        {data.zip && <meta className="elastic" name="zip" content={`${data.zip}`} />}
        {data.phone && <meta className="elastic" name="phone" content={`${data.phone}`} />}
        {ogImage && <meta name="twitter:image" content={`${ogImage}`} />}
        {ogImage && <meta name="twitter:image" content={`${ogImage}`} />}
        <meta className="elastic" name="type" content={data.community ? 'community' : 'location'} />
        <meta className="elastic" name="sort_group" content={data.community ? '3' : '2'} />
        {imageAlt && <meta name="twitter:image:alt" content={imageAlt} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogDescription && <meta name="twitter:description" content={ogDescription} />}
        {robotsTag}
      </Helmet>
    </div>
  );
}

export function getCavcoRetailerData(props: BrProps) {
  const details = props.page.getComponent('main', 'detailcontainer');
  const formR = details && details.getComponent('retailer-data');
  const doc = formR && formR.getModels().documents[0];
  const document = props.page.getContent(doc);
  const retailer = document?.getData<RetailerData>();
  return retailer;
}

export function PagePropertiesRtl(props: BrProps) {
  const details = props.page.getComponent('main', 'detailcontainer');
  const form = details && details.getComponent('details');
  const doc = form && form.getModels().documents[0];
  const document = props.page.getContent(doc);
  const dm = (document && document.getData<RetailerData>()) || getCavcoRetailerData(props);
  if (!dm) {
    return null;
  }
  return pagePropertiesForRtlData(props, dm, dm._links);
}
