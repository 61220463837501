import React, { useContext } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { Col, Container, Carousel, Row } from 'react-bootstrap';
import { Tabs, Tab, IconButton } from '@material-ui/core';
import { BrProps } from '@bloomreach/react-sdk';
import { Reference, Page } from '@bloomreach/spa-sdk';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { ResolverLink } from 'components/shared';
import { LocalStorageContext } from 'components/Header/LocalStorageContext';
import { HEADER_LINKS } from 'components/Header/constants';
import { SEARCH_TYPES } from 'components/enums';
import AutocompleteComp from '../Autocomplete/Autocomplete';
import { ReactComponent as SearchIcon } from '../../assets/images/search-icon-30.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/images/chevron-right-icon.svg';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/chevron-left-icon.svg';
import {
  buildResourceBundleToObject,
  getCountryByGeocode,
  getPageContentData,
  getFirstImgUrl,
  getImageUrl,
  checkForNessus,
} from '../utils';

import './SearchTabHero.scss';

declare let window: any;

interface TabHeroReference {
  brandName: string;
  id: string;
  url: string;
}

function a11yProps(index: number) {
  return {
    id: `tab-hero-${index}`,
    'aria-controls': `tabhero-${index}`,
  };
}

const parseTabSearchType = (value: string) => value?.trim();

const mapBrands = (
  references: TabHeroReference[],
  documentsRef: { [key: string]: Reference },
  page: Page
) => {
  const brands: { [key: string]: TabHeroReference } = {};
  if (!references?.length) {
    return brands;
  }
  references.forEach((ref) => {
    const floorplanId = documentsRef[ref.id]
      ? page.getContent(documentsRef[ref.id])?.getData()?.id
      : null;
    if (floorplanId) {
      brands[floorplanId] = { ...ref };
    }
  });
  return brands;
};

const getContentReference = (
  ref: Reference | string,
  page: Page,
  tabSearchType: TabSearchType,
  brands: { [key: string]: TabHeroReference }
): HeroCarouselSlide | null => {
  const refDoc = page.getContent(ref)?.getData<any>();

  if (!ref || !refDoc) {
    return null;
  }
  let title = '';
  let carouselImage = '';
  let superheader = '';
  const slideLink = refDoc._links?.site?.href || '';

  if (refDoc.contentType?.toLowerCase() === 'cavco:album') {
    const mediaAsset = (refDoc as AlbumData).mediaAsset || [];
    if (mediaAsset[0]) {
      const imageContent = page.getContent<ReferenceModel>(mediaAsset[0].image);
      carouselImage = getImageUrl(mediaAsset[0], imageContent, 'large') || '';
    }
  }

  switch (tabSearchType) {
    case HEADER_LINKS[SEARCH_TYPES.FLOORPLAN].KEY:
      superheader = brands[refDoc.id]?.brandName || '';
      title = refDoc.displayName;
      if (!carouselImage && refDoc.photos) {
        carouselImage = getFirstImgUrl(page, refDoc.photos) || '';
      }
      break;
    case HEADER_LINKS[SEARCH_TYPES.MIR].KEY:
      superheader = refDoc.brandValue;
      title = refDoc.name;
      if (!carouselImage && refDoc.photoAlbum) {
        carouselImage = getFirstImgUrl(page, refDoc.photoAlbum) || '';
      }
      break;
    case HEADER_LINKS[SEARCH_TYPES.RETAILER].KEY:
      title = refDoc.dba;
      if (!carouselImage && refDoc.photos && refDoc.photos.length) {
        carouselImage = getFirstImgUrl(page, refDoc.photos[0]) || '';
      }
      break;
    default:
      title = '';
      superheader = '';
      break;
  }
  return {
    title,
    carouselImage,
    superheader,
    slideLink,
  };
};

const parseTabHeroList = (
  list: TabHeroTabCompound[],
  page: Page,
  brands: { [key: string]: TabHeroReference }
): TabHeroTabCompound[] => {
  return list.map((v) => ({
    ...v,
    heroCarouselSlide: v.heroCarouselSlide.map((x) => ({
      ...x,
      contentReference: getContentReference(
        x.contentReferenceDoc,
        page,
        parseTabSearchType(v.tabSearchType) as TabSearchType,
        brands
      ),
    })),
  }));
};

export const SearchTabHero: React.FC<BrProps> = ({ component, page }: BrProps) => {
  const history = useHistory();
  const [selectedTab, setTab] = React.useState<TabHeroTabCompound>();
  const [value, setValue] = React.useState(0);
  const [search, setSearch] = React.useState<SearchLatLong>({
    lat: null,
    lng: null,
    country: '',
    postalCode: '',
    searchTerm: '',
  });
  const [searchError, setSearchError] = React.useState<string>('');
  const [slideIndex, setSlideIndex] = React.useState<number>(0);
  const [floorPlanSearch, setFloorPlanSearch] = useContext(LocalStorageContext);

  // local storage hook

  const {
    document: documentRef,
    configuration,
    formConfig,
    references = [],
    ...rest
  } = component.getModels();
  const brands = mapBrands(references, rest, page);
  const valuelist = page?.getContent(configuration)?.getData();
  const formConfigLabels = buildResourceBundleToObject(
    getPageContentData<ResourceBundleData>(formConfig, page)
  );

  React.useEffect(() => {
    const searchInput: any = document.querySelector('.cvc-search-tab-hero-input')!;
    if (searchInput) {
      const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement> & React.ChangeEvent<HTMLInputElement>
      ) => {
        if (e.key === 'Enter') {
          if (!e?.target?.value) {
            setSearch({
              lat: null,
              lng: null,
              country: '',
              postalCode: '',
            });
            setSearchError(
              formConfigLabels.zipcodeError?.toString() || 'Please enter a zipcode, city, or state'
            );
            return;
          }
          /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
          handleSubmit();
        }
      };

      searchInput.addEventListener('keydown', handleKeyDown);
      return () => searchInput.removeEventListener('keydown', handleKeyDown);
    }
  });

  React.useEffect(() => {
    if (documentRef && page && !selectedTab) {
      const data = page.getContent(documentRef)?.getData<SearchTabHeroData>();
      const firstTab = data?.tabHeroTabCompound[0];
      if (firstTab) {
        setTab({
          ...firstTab,
          heroCarouselSlide:
            firstTab.heroCarouselSlide?.map((o) => ({
              ...o,
              contentReference: getContentReference(
                o.contentReferenceDoc,
                page,
                parseTabSearchType(firstTab.tabSearchType) as TabSearchType,
                brands
              ),
            })) || [],
        });
      }
    }
  }, [documentRef, page, brands, selectedTab]);

  if (!documentRef) {
    return null;
  }

  const searchDocument = page.getContent(documentRef);
  const searchDocumentData = searchDocument?.getData<SearchTabHeroData>();
  const tabHeroList = parseTabHeroList(searchDocumentData?.tabHeroTabCompound || [], page, brands);
  const apiKey = valuelist?.items?.find((i: any) => i.key === 'apiKey')?.label || '';
  const internalLoc = valuelist?.items?.find((i: any) => i.key === 'mapEndpoint')?.label || '';

  const handleChange = (index: number) => {
    setValue(index);
    setTab(tabHeroList[index]);
    setSlideIndex(0);
  };

  const getImageSrc = (carouselRef: any): string => {
    return page.getContent(carouselRef)?.getUrl() || '';
  };

  const getUrlBySelectedTab = () => {
    const tabSearchType = parseTabSearchType(tabHeroList[value]?.tabSearchType);
    switch (tabSearchType) {
      case HEADER_LINKS[SEARCH_TYPES.RETAILER].KEY:
        return `${window.spaBaseUrl}/our-retailers`;
      case HEADER_LINKS[SEARCH_TYPES.COMMUNITY].KEY:
        return `${window.spaBaseUrl}/our-communities`;
      case HEADER_LINKS[SEARCH_TYPES.MIR].KEY:
        return `${window.spaBaseUrl}/move-in-ready-homes`;
      default:
        return `${window.spaBaseUrl}/our-homes`;
    }
  };

  const searchInputFocus = () => {
    const searchInput = document.querySelector('.cvc-search-tab-hero-input');
    if (searchInput) {
      (searchInput as HTMLInputElement).focus();
    }
  };

  const suggestionSelected = (data: any) => {
    geocodeByAddress(data.description)
      .then(async (results) => {
        const result = results[0];
        const postalCode: any = result.address_components.find(
          (el: any) => el.types[0] === 'postal_code'
        );
        const latLong = await getLatLng(result);
        setSearchError('');
        return {
          ...latLong,
          country: getCountryByGeocode(results[0]),
          postalCode: postalCode?.short_name,
          searchTerm: result?.formatted_address,
        };
      })
      .then((latLong: SearchLatLong) => setSearch(latLong));
    searchInputFocus();
  };

  function handleSubmit() {
    const nextPage = getUrlBySelectedTab();
    const { lat, lng, country, postalCode, searchTerm } = search;

    if (lat && lng) {
      let nameString = '';
      if (postalCode) {
        sessionStorage.setItem(
          'searchData',
          `{ "postalCode":"${postalCode}", "country":"${country}", "lat":"${lat}", "lng":"${lng}" }`
        );
      }

      if (searchTerm) {
        nameString = `&name=${searchTerm}`;
      }

      const path = `${nextPage}/${country}?latitude=${lat}&longitude=${lng}${nameString}`;
      const tabSearchType = parseTabSearchType(tabHeroList[value]?.tabSearchType);
      // set the search item in localstorage
      setFloorPlanSearch({ ...floorPlanSearch, [tabSearchType]: path });

      history.push(path);
    } else {
      setSearchError(
        formConfigLabels.zipcodeError?.toString() || 'Please enter a zipcode, city, or state'
      );
    }
  }

  return (
    <div className="cvc-search-tab-hero">
      <Container fluid>
        <Row>
          <Col md={6} xs={12} className="cvc-search-tab-hero-tab-section">
            <div className="cvc-search-tab-hero-tabs-section-wrapper">
              <div className="cvc-search-tab-hero-header">
                <h1 className="cvc-search-tab-hero-headline">{selectedTab?.headline}</h1>
                <p className="cvc-search-tab-hero-subheadline">{selectedTab?.subheadline}</p>
              </div>
              <div className="cvc-search-tab-hero-tabs-wrapper">
                <Tabs
                  className={cn({
                    'cvc-search-tab-hero-tabs': true,
                    'cvc-search-tab-hero-tabs__shrink': tabHeroList.length < 4,
                  })}
                  value={value}
                  onChange={(ev, val) => handleChange(val)}
                  aria-label="search tab hero dropdown"
                >
                  {tabHeroList?.map((tabHero: TabHeroTabCompound, index) => (
                    <Tab
                      key={`tab-${tabHero.tabSearchType}`}
                      label={tabHero.tabLabel}
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
                <div className="cvc-search-tab-hero-input-wrapper">
                  <IconButton
                    className="cvc-search-tab-hero-input-icon-button"
                    onClick={handleSubmit}
                  >
                    <SearchIcon className="cvc-search-tab-hero-input-icon" />
                  </IconButton>
                  <AutocompleteComp
                    apiKey={checkForNessus(apiKey)}
                    placeholder={
                      formConfigLabels.searchPlaceHolder || 'Enter City, State or Zip Code'
                    }
                    geocodeEndpoint={internalLoc}
                    onClick={suggestionSelected}
                    inputClassName={cn({
                      'cvc-search-tab-hero-input': true,
                      'has-error': !!searchError,
                    })}
                  />
                  {searchError && <p className="zipcode-error">{searchError}</p>}
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} xs={12} className="cvc-search-tab-hero-carousel-section">
            <Carousel
              interval={null}
              wrap={false}
              slide={false}
              activeIndex={slideIndex}
              onSelect={(index) => setSlideIndex(index)}
              nextIcon={<RightArrowIcon />}
              prevIcon={<LeftArrowIcon />}
            >
              {selectedTab?.heroCarouselSlide?.map(
                ({ slideLink, superheader, title, contentReference, carouselImage }, index) => (
                  <Carousel.Item key={`tab-hero-carousel-${index}`}>
                    {slideLink !== '' && (
                      <ResolverLink
                        className="cvc-search-tab-hero-carousel-image-wrapper"
                        id={`tab-hero-${index}`}
                        href={contentReference?.slideLink || slideLink}
                      >
                        <img
                          className="cvc-search-tab-hero-carousel-image"
                          src={contentReference?.carouselImage || getImageSrc(carouselImage)}
                          alt={`${contentReference?.title ?? title}`}
                        />
                      </ResolverLink>
                    )}
                    {slideLink === '' && (
                      <div className="cvc-search-tab-hero-carousel-image-wrapper">
                        <img
                          className="cvc-search-tab-hero-carousel-image"
                          src={contentReference?.carouselImage || getImageSrc(carouselImage)}
                          alt={`${contentReference?.title ?? title}`}
                        />
                      </div>
                    )}
                    <Carousel.Caption>
                      <p className="cvc-search-tab-hero-carousel-img-superheader">
                        {contentReference?.superheader || superheader}
                      </p>
                      <h4 className="cvc-search-tab-hero-carousel-img-title">
                        {contentReference?.title || title}
                      </h4>
                    </Carousel.Caption>
                  </Carousel.Item>
                )
              )}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
